import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const CustomizePackageAccordionTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "1.1",
  letterSpacing: "0.18px",
  color: BLACK[87],
});
