import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { QuestionProps, YesNoStep } from "../Step";

export default function ExecutiveOrHighlyVisibleStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  return (
    <YesNoStep<GuidedPackageResponses>
      stepperProps={stepperProps}
      name="isExecutiveOrHighlyVisible"
      title="Is the role you’re hiring for an executive, position of trust, or highly visible to the public?"
      subtitle="This helps us know if you need to include Global Watch, Social Media Search, and Federal Criminal in your package."
    />
  );
}
