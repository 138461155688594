import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const CustomizePackageAccordionDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "140%",
  letterSpacing: "0.5px",

  color: BLACK[60],
});
