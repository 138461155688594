import { Box, Button, Divider, styled } from "@mui/material";
import { customizePopularPackagePath } from "App";
import ComparePackageCardList from "components/list/ComparePackageCardList";
import { PackageCardListItemProps } from "components/list/PackageCardListItem";
import { Package } from "models/packages/Package";
import { PackageDepthOfCoverage } from "models/packages/PackageDepthOfCoverage";
import { PackageService } from "models/packages/PackageService";
import { CompareRef } from "pages/SelectPackage/SelectPackage";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetDepthsQuery,
  useGetServicesQuery,
} from "store/slices/marketplaceApi";
import CardDetails from "../CardDetails";
import CardHeader from "../CardHeader";

export interface ComparePackageCardContentsProps {
  inputPackage: Package;
  infoBoxHeight: number;
  combinedPackage: Package;
  compareRefs: CompareRef[];
}

const ComparePackageCardContents = ({
  inputPackage,
  infoBoxHeight,
  combinedPackage,
  compareRefs,
}: ComparePackageCardContentsProps): JSX.Element => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const serviceContainerRef = useRef<HTMLDivElement>(null);

  const { data: depths = [] } = useGetDepthsQuery();
  const { data: services = [] } = useGetServicesQuery();

  const depthCategories = [...new Set(depths.map((depth) => depth.categoryId))];

  const serviceIds = services.map((service) => service.id);
  const depthOfCoverageBoxHeight = 160;
  const sharePackageBoxHeight = 28;
  const maxLabelChars = 20;

  const mapDepths = (currentPackage: Package): PackageCardListItemProps[] => {
    return Object.values(currentPackage.depths)
      .filter((depth: PackageDepthOfCoverage) => !!depth)
      .sort((a, b) => {
        return (
          depthCategories.indexOf(a.categoryId) -
          depthCategories.indexOf(b.categoryId)
        );
      })
      .map((depth: PackageDepthOfCoverage) => {
        return {
          name: depth.name,
          description: depth.description,
        } as PackageCardListItemProps;
      });
  };

  const mapServices = (currentPackage: Package): PackageCardListItemProps[] => {
    return Object.values(currentPackage.services)
      .filter((service: PackageService) => !!service)
      .sort((a, b) => {
        return serviceIds.indexOf(a.id) - serviceIds.indexOf(b.id);
      })
      .map((service: PackageService) => {
        return {
          name: service.name,
          description: service.description,
          disclaimer: service.disclaimer,
        } as PackageCardListItemProps;
      });
  };

  const navigate = useNavigate();

  const selectAndCustomizeButton = () => {
    return (
      <Button
        onClick={() => {
          navigate(customizePopularPackagePath, {
            state: {
              selectedPackage: inputPackage,
            },
          });
        }}
        variant={"contained"}
        color={"secondary"}
        fullWidth
        sx={{
          height: "40px",
          width: "258px",
          padding: "0px 28px",
        }}>
        select and customize
      </Button>
    );
  };

  return (
    <Box>
      <CardHeader
        name={
          inputPackage.name.length > maxLabelChars
            ? inputPackage.name.substring(0, maxLabelChars) + `...`
            : inputPackage.name
        }
        description={inputPackage.description}
      />
      <Divider sx={{ width: "265px" }} />
      <CardDetails inputPackage={inputPackage} />
      <Box sx={{ padding: "8px 8px 28px" }}>{selectAndCustomizeButton()}</Box>
      <DepthAndServiceBox
        ref={listContainerRef}
        sx={{
          height: infoBoxHeight - sharePackageBoxHeight,
        }}>
        <Box ref={serviceContainerRef}>
          <ComparePackageCardList
            title={"Services"}
            items={mapServices(inputPackage)}
            combinedItems={mapServices(combinedPackage)}
            width={"255px"}
            compareRefs={compareRefs}
          />
        </Box>
        <Box
          sx={{
            ...(mapDepths(inputPackage).length && {
              height: depthOfCoverageBoxHeight,
            }),
          }}>
          <ComparePackageCardList
            title={"Depth of Coverage"}
            items={mapDepths(inputPackage)}
            combinedItems={mapDepths(combinedPackage)}
            width={"255px"}
            compareRefs={compareRefs}
          />
        </Box>
      </DepthAndServiceBox>
    </Box>
  );
};

const DepthAndServiceBox = styled(Box)`
  overflow: hidden;
`;

export default ComparePackageCardContents;
