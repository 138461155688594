import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { QuestionProps, YesNoStep } from "../Step";

export default function FederalContractStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  return (
    <YesNoStep<GuidedPackageResponses>
      stepperProps={stepperProps}
      name="isFederalContract"
      title="Is this role associated with a federal contact? (FAR clause)"
      subtitle="This helps us know if you need to include Global Watch in your package."
    />
  );
}
