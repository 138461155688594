import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { TableCell } from "components/Table/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import palette from "styles/palette";
import { EstimatedPrice } from "./Typography/EstimatedPrice";
import { PriceAndTimeDisclaimer } from "./Typography/PriceAndTimeDisclaimer";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  BLACK,
  CANCEL_HOVER_BACKGROUND,
  CHECK,
  EDIT_HOVER_BACKGROUND,
  EXPAND_COVERAGE,
  SAVE,
  SAVE_HOVER_BACKGROUND,
} from "styles/colors";
import { TurnaroundTime } from "components/common/Typography/TurnaroundTime";
import { ReviewPackagesRowWidgetProps } from "./ReviewPackagesRowWidget.d";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  savedPackageAdded,
  savedPackageChanged,
  savedPackageRemoved,
} from "store/actions/save-package-actions";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { View } from "./Typography/View";
import { Delete } from "./Dialog/Typography/Delete";
import StyledTextFieldWithLabel from "components/Onboarding/StyledTextFieldWithLabel";
import { Edit } from "components/card/Typography/Edit";
import SharePackageDialog from "components/SharePackageDialog";
import { v4 as uuidV4 } from "uuid";
import { customizePopularPackagePath } from "App";

interface ExpandableListProps<T> {
  items: T[];
  renderItem: (item: T) => string;
  collapseAfter?: number;
}

function ExpandableList<T>({
  items,
  renderItem,
  collapseAfter = 4,
}: ExpandableListProps<T>) {
  const showExpandCollapse = items.length > collapseAfter;
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleItems = isExpanded ? items : items.slice(0, collapseAfter);

  return (
    <Box
      style={{
        margin: "0px 0px 0px 74px",
      }}>
      {visibleItems.map((r) => {
        const value = renderItem(r);

        return (
          <Box key={value} sx={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faCheck}
              color={CHECK}
              style={{ marginRight: "10px" }}
            />
            <Typography
              sx={{
                display: "inline-block",
                fontSize: "14px",
              }}>
              {value}
            </Typography>
          </Box>
        );
      })}

      {showExpandCollapse ? (
        <View onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "View Less" : "View All"}
          <FontAwesomeIcon
            title="Expand coverage"
            transform={isExpanded ? { rotate: 180 } : { rotate: 0 }}
            style={{ verticalAlign: "middle", marginLeft: "12px" }}
            icon={faChevronDown}
            size="sm"
            color={EXPAND_COVERAGE}
          />
        </View>
      ) : null}
    </Box>
  );
}

const ReviewPackagesRowWidget = ({
  row: reviewPackage,
  index,
}: ReviewPackagesRowWidgetProps): JSX.Element => {
  const [editPackageName, setEditPackageName] = useState<boolean>(false);
  const [packageName, setPackageName] = useState<string>(reviewPackage.name);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const savedPackages = useAppSelector(
    (state) => state.savedPackages.savedPackages,
  );

  const navigate = useNavigate();

  useEffect(() => {
    setPackageName(reviewPackage.name);
  }, [reviewPackage]);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handlePackageNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPackageName(event?.target?.value);
  };

  const dispatch = useAppDispatch();

  const { turnaround, depths, services, price } = reviewPackage;

  const minLength = 1;
  const maxLength = 256;

  const helperText =
    (packageName?.length < minLength && `1-256 characters required`) ||
    (packageName?.length > maxLength && `1-256 characters required`);

  const error =
    packageName?.length < minLength || packageName?.length > maxLength;

  const savePackageName = () => {
    if (!reviewPackage.packageId) {
      return;
    }

    dispatch(
      savedPackageChanged({
        id: reviewPackage.packageId,
        changes: {
          name: packageName,
        },
      }),
    );
  };

  const [openSharePackageDialog, setOpenSharePackageDialog] = useState(false);

  const handleOpenSharePackageDialog = () => setOpenSharePackageDialog(true);
  const handleCloseSharePackageDialog = () => setOpenSharePackageDialog(false);

  const handleDuplicatePackage = () => {
    dispatch(
      savedPackageAdded({
        ...reviewPackage,
        packageId: uuidV4(),
        name: packageName ? `${packageName}-Copy` : "My Package",
      }),
    );
  };

  const handleDeletePackage = () => {
    if (!reviewPackage.packageId) {
      return;
    }

    dispatch(savedPackageRemoved(reviewPackage.packageId));
  };

  return (
    <>
      <SharePackageDialog
        open={openSharePackageDialog}
        packages={[reviewPackage]}
        handleCloseSharePackageDialog={handleCloseSharePackageDialog}
      />
      <Dialog
        maxWidth={"md"}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-package-dialog"
        open={openDeleteDialog}>
        <DialogTitle sx={{ marginBottom: "0px", padding: 2 }}>
          <Delete>Delete Package</Delete>
          <IconButton
            aria-label={"Close"}
            onClick={handleCloseDeleteDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: BLACK[54],
            }}>
            <CloseIcon sx={{ padding: "6px 2px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "384px",
            height: "28px",
            padding: "0px 24px",
            overflow: "hidden",
          }}>
          <Typography fontSize={"14px"} textAlign={"center"}>
            {"You will not be able to recover this package once deleted."}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "8px 0px",
            margin: "16px 0px 24px 0px",
            justifyContent: "flex-end",
          }}>
          <Button
            aria-label="Cancel"
            sx={{
              width: "140px",
              fontSize: "16px",
              fontWeight: "bold",
              marginRight: "8px",
            }}
            variant={"outlined"}
            onClick={handleCloseDeleteDialog}>
            {"Cancel"}
          </Button>
          <Button
            aria-label="Confirm"
            sx={{
              width: "140px",
              height: "40px",
              fontSize: "16px",
              fontWeight: "bold",
              marginRight: "24px",
            }}
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              handleDeletePackage();
              handleCloseDeleteDialog();
            }}>
            {"Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <Box style={{ margin: "0px 0px 0px 74px", whiteSpace: "nowrap" }}>
            {editPackageName ? (
              <>
                <StyledTextFieldWithLabel
                  sx={{
                    maxWidth: "220px",
                    margin: "0px 8px 0px 0px",
                  }}
                  id="edit-package-name"
                  error={error}
                  helperText={helperText}
                  autoFocus
                  value={packageName}
                  onChange={handlePackageNameChange}
                />
                <>
                  {editPackageName && (
                    <>
                      <Tooltip
                        title={"Cancel"}
                        arrow={true}
                        componentsProps={{
                          arrow: {
                            sx: {
                              color: "tertiary_grey.dark",
                            },
                          },
                          tooltip: {
                            sx: {
                              backgroundColor: "tertiary_grey.dark",
                              width: "auto",
                            },
                          },
                        }}>
                        <IconButton
                          sx={{
                            "&:hover": {
                              backgroundColor: CANCEL_HOVER_BACKGROUND,
                            },
                          }}
                          aria-label="Cancel"
                          onClick={() => {
                            setEditPackageName(false);
                            const newSavedPackages = [...savedPackages];
                            setPackageName(newSavedPackages[index]?.name);
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={"Save"}
                        arrow={true}
                        componentsProps={{
                          arrow: {
                            sx: {
                              color: "tertiary_grey.dark",
                            },
                          },
                          tooltip: {
                            sx: {
                              backgroundColor: "tertiary_grey.dark",
                              width: "auto",
                            },
                          },
                        }}>
                        <Box component={"span"}>
                          <IconButton
                            sx={{
                              "&:hover": {
                                backgroundColor: SAVE_HOVER_BACKGROUND,
                              },
                            }}
                            aria-label="Save"
                            disabled={error}
                            onClick={() => {
                              savePackageName();
                              setEditPackageName(false);
                            }}>
                            <DoneIcon sx={{ color: SAVE }} />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </>
                  )}
                </>
              </>
            ) : (
              <Box sx={{ whiteSpace: "nowrap" }}>
                <Typography sx={{ display: "inline-block" }}>
                  {packageName}
                </Typography>
                <Tooltip
                  title={"Edit package name"}
                  arrow={true}
                  componentsProps={{
                    arrow: {
                      sx: {
                        color: "tertiary_grey.dark",
                      },
                    },
                    tooltip: {
                      sx: {
                        backgroundColor: "tertiary_grey.dark",
                        width: "auto",
                      },
                    },
                  }}>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "80px",
                      height: "22px",
                      marginLeft: "8px",
                      "&:hover": {
                        background: EDIT_HOVER_BACKGROUND,
                      },
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditPackageName(true)}>
                    <ModeEditIcon
                      sx={{
                        margin: "0px 10px 0px 14px",
                        fontSize: "12px",
                        color: "secondary.dark",
                        alignSelf: "flex-end",
                        verticalAlign: "middle",
                      }}
                    />
                    <Edit onClick={() => setEditPackageName(true)}>Edit</Edit>
                  </Box>
                </Tooltip>
              </Box>
            )}
            <Box>
              <FontAwesomeIcon
                style={{ margin: "16px 8px 0px 0px" }}
                icon={faClock}
                color={palette?.neutral_dark?.light}
              />
              <TurnaroundTime>
                {turnaround || "Based on services selected"}
              </TurnaroundTime>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          {services ? (
            <ExpandableList
              items={services.filter((service) => service.name !== "None")}
              renderItem={({ name }) => name}
            />
          ) : null}
        </TableCell>
        <TableCell>
          {depths ? (
            <ExpandableList items={depths} renderItem={({ name }) => name} />
          ) : null}
        </TableCell>
        <TableCell align={"right"}>
          <Box style={{ margin: "0px 0px 0px 74px" }}>
            <EstimatedPrice>{`$ ${price?.toFixed(2)}`}</EstimatedPrice>
            <PriceAndTimeDisclaimer>
              *Price and turnaround times may vary
            </PriceAndTimeDisclaimer>
          </Box>
        </TableCell>
        <TableCell align={"center"}>
          <PopupState variant="popover" popupId="more-actions-menu">
            {(popupState) => (
              <>
                <Tooltip title={"Actions"} arrow={true}>
                  <IconButton
                    sx={{ marginLeft: "30px" }}
                    data-testid="package-actions"
                    aria-label="View more actions"
                    {...bindTrigger(popupState)}>
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  MenuListProps={{ sx: { width: "125px" } }}
                  {...bindMenu(popupState)}>
                  <MenuItem
                    data-testid="package-actions-edit"
                    onClick={() => {
                      navigate(customizePopularPackagePath, {
                        state: {
                          selectedPackage: reviewPackage,
                          oldSelectedPackage: reviewPackage,
                        },
                      });
                    }}>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "30px",
                        },
                      }}>
                      <EditRoundedIcon sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleOpenSharePackageDialog}>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "30px",
                        },
                      }}>
                      <MailOutlineRoundedIcon sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Share</ListItemText>
                  </MenuItem>
                  <MenuItem
                    data-testid="package-actions-duplicate"
                    onClick={() => {
                      handleDuplicatePackage();
                      popupState.close();
                    }}>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "30px",
                        },
                      }}>
                      <ContentCopyRoundedIcon sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                  </MenuItem>
                  <MenuItem
                    data-testid="package-actions-delete"
                    onClick={() => {
                      handleClickOpenDeleteDialog();
                      popupState.close();
                    }}>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "30px",
                        },
                      }}>
                      <DeleteOutlineRoundedIcon sx={{ fontSize: "18px" }} />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReviewPackagesRowWidget;
