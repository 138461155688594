import { Link } from "@mui/material";
import { FooterItem } from "./Footer";

export const footerList: FooterItem[] = [
  {
    label: "Clients",
    content: (
      <>
        <Link href={"mailto:clientsuccess@otes.com"} underline={"none"}>
          Email
        </Link>
        <span style={{ color: "white" }}>{" or Call "}</span>
        <Link href={"tel: 800.886.4777"} underline={"none"}>
          800.886.4777
        </Link>
      </>
    ),
  },
  {
    label: "Candidates",
    content: (
      <>
        <Link href={"mailto:candidateconnect@otes.com"} underline={"none"}>
          Email
        </Link>
        <span style={{ color: "white" }}>{" or Call "}</span>
        <Link href={"tel: 888.222.0667"} underline={"none"}>
          888.222.0667
        </Link>
      </>
    ),
  },
  {
    label: "Help",
    content: (
      <Link
        href="https://www.orangetreescreening.com/knowledge"
        underline="none"
        target={"_blank"}
        rel={"noopener"}>
        Knowledge center
      </Link>
    ),
  },
];
