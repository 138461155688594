import {
  styled,
  TableCell as MuiTableCell,
  tableCellClasses,
} from "@mui/material";
import { LIGHT_GREY } from "styles/colors";

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: "25px",
    backgroundColor: LIGHT_GREY[90],
    color: theme?.palette?.primary?.dark[900],
    padding: "8px 8px",
    borderBottom: "none",
    paddingLeft: "85px",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "136px",
    fontSize: "16px",
    paddingTop: "31px",
    verticalAlign: "top",
  },
}));
