import { Package } from "models/packages/Package";
import { Box, styled, Typography } from "@mui/material";
import SummaryCardContents from "./SummaryCardContents";
import Card from "../Card";
import { Dispatch, SetStateAction, useState } from "react";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SharePackageDialog from "components/SharePackageDialog";
import { SharePackageButton } from "../package/Button/SharePackageButton";

export interface SummaryCardProps {
  customizedPackage: Package;
  alertMessages: string[];
  setAlertMessages: Dispatch<SetStateAction<string[]>>;
}

const SummaryCard = ({
  customizedPackage,
  alertMessages,
  setAlertMessages,
}: SummaryCardProps): JSX.Element => {
  const [openSharePackageDialog, setOpenSharePackageDialog] =
    useState<boolean>(false);

  const handleOpenSharePackageDialog = () => setOpenSharePackageDialog(true);
  const handleCloseSharePackageDialog = () => setOpenSharePackageDialog(false);

  return (
    <Box sx={{ position: "sticky", top: 0, height: "fit-content" }}>
      <SharePackageDialog
        open={openSharePackageDialog}
        packages={[customizedPackage]}
        handleCloseSharePackageDialog={handleCloseSharePackageDialog}
      />
      <SummaryHeader sx={{ backgroundColor: "secondary.dark" }}>
        <SummaryHeaderText>SUMMARY</SummaryHeaderText>
      </SummaryHeader>
      <Card
        sx={{
          boxSizing: "border-box",
          width: "441px",
          border: "1px solid",
          borderRadius: "0px 0px 4px 4px",
          borderColor: "secondary.dark",
        }}
        actionSx={{
          padding: "0px",
          margin: "0px",
          width: "inherit",
          height: "auto",
        }}
        actions={
          <SharePackageButton
            aria-label={"share-package-button"}
            disabled={
              customizedPackage
                ? !customizedPackage?.services?.some(({ id }) => id > 0)
                : false
            }
            onClick={handleOpenSharePackageDialog}
            startIcon={
              <EmailRoundedIcon
                fontSize={"small"}
                sx={{ marginRight: "6px", cursor: "pointer" }}
              />
            }>
            Share package
          </SharePackageButton>
        }>
        <SummaryCardContents
          customizedPackage={customizedPackage}
          alertMessages={alertMessages}
          setAlertMessages={setAlertMessages}
        />
      </Card>
    </Box>
  );
};

const SummaryHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "12px",
  width: "441px",
  height: "50px",
});

const SummaryHeaderText = styled(Typography)({
  width: "185px",
  height: "30px",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "30px",
  letterSpacing: "0.1px",
  color: "white",
  padding: "10px 15px 10px",
});

export default SummaryCard;
