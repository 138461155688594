import { styled, Typography, TypographyTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const StyledTitle = styled(Typography)`
  height: 32px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  flex: none;
  order: 0;
  flex-grow: 0;
` as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;
