import { styled, Typography } from "@mui/material";
import { FAQ_ANSWER } from "styles/colors";

export const FAQAnswer = styled(Typography)(({ theme }) => ({
  height: "60px",
  fontWeight: 400,
  fontsize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: FAQ_ANSWER,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));
