import { styled, Typography } from "@mui/material";

export const SharePackage = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  flex: "none",
  order: 1,
  flexGrow: 0,
});
