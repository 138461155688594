const VALID_EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const isValidEmail = (value: string): boolean =>
  VALID_EMAIL_REGEX.test(value);

export const emailValidator = (value: string): string | undefined =>
  isValidEmail(value) ? undefined : "Invalid email format.";

export const hubspotEmailValidator = (
  value: string,
  status: string,
  data: string[] | undefined,
): string | undefined =>
  !isFreeDomain(value, status, data)
    ? emailValidator(value)
    : `Please enter your business email address. This form does not accept addresses from ${value.substring(
        value.indexOf("@") + 1,
      )}.`;

const isFreeDomain = (
  value: string,
  status: string,
  data: string[] | undefined,
): boolean => {
  const currDomain = value.substring(value.indexOf("@") + 1);

  if (data && status === "success") {
    return data.flat().includes(currDomain);
  }

  return false;
};
