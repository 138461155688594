import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Link, styled, Tooltip, Typography } from "@mui/material";
import {
  customizeCustomPackagePath,
  landingPath,
  onboardingCustomPath,
  reviewCustomPackagesPath,
  reviewPopularPackagesPath,
} from "App";
import { useAppSelector } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { BLACK, PACKAGE_BADGE_COUNT } from "styles/colors";
import palette from "styles/palette";

const Header = (): JSX.Element => {
  const location = useLocation();
  const isLandingPath = location?.pathname === landingPath;
  const navigate = useNavigate();

  const savedPackages = useAppSelector(
    (state) => state.savedPackages.savedPackages,
  );

  const savedPackagesCount = savedPackages?.length;

  return (
    <Box
      sx={{
        boxShadow: `inset 0px -1px 0px ${BLACK[12]}`,
        width: "100%",
        height: "104px",
        minWidth: "1440px",
      }}
      component={"header"}>
      <HeaderContentBox>
        <Link
          href={"https://www.orangetreescreening.com/"}
          underline={"none"}
          target={"_blank"}
          rel={"noopener"}>
          <Box
            sx={{
              width: "170px",
              height: "32px",
            }}
            component={"img"}
            alt={"Orange Tree logo"}
            src={"/images/OTLogoVector.svg"}
          />
        </Link>
        <Box
          component={"nav"}
          sx={{
            flex: "1",
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Link
            href={"https://www.orangetreescreening.com/"}
            underline={"none"}
            target={"_blank"}
            rel={"noopener"}
            sx={{ marginRight: "44px" }}>
            <NavLabel>Home</NavLabel>
          </Link>
        </Box>
        {!isLandingPath && (
          <Tooltip title="Saved Packages" arrow={true}>
            <SavedPackagesBadge
              style={{ margin: "5px 0px", alignSelf: "center" }}
              badgeContent={savedPackagesCount > 9 ? "9+" : savedPackagesCount}
              onClick={() =>
                navigate(
                  [
                    customizeCustomPackagePath,
                    reviewCustomPackagesPath,
                    onboardingCustomPath,
                  ].includes(location?.pathname)
                    ? reviewCustomPackagesPath
                    : reviewPopularPackagesPath,
                )
              }>
              <FontAwesomeIcon
                icon={faBookmark}
                size={"lg"}
                color={palette?.neutral_dark?.light}
              />
            </SavedPackagesBadge>
          </Tooltip>
        )}
      </HeaderContentBox>
    </Box>
  );
};

const HeaderContentBox = styled(Box)({
  boxSizing: "border-box",
  padding: "34px 64px 0",
  maxWidth: "1440px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  justifyContent: "space-between",
});

const NavLabel = styled(Typography)({
  width: "42px",
  height: "19px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  textTransform: "uppercase",
  color: BLACK[87],
  flex: "none",
  order: 0,
  flexGrow: 0,
});

const SavedPackagesBadge = styled(Badge)({
  alignSelf: "center",
  cursor: "pointer",
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: PACKAGE_BADGE_COUNT,
    width: "17px",
    height: "17px",
  },
});

export default Header;
