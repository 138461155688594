import { Link, styled } from "@mui/material";

export const BuildCustomPackageLink = styled(Link)(({ theme }) => ({
  width: "204px",
  height: "28px",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "28px",
  letterSpacing: "0.25px",
  color: theme?.palette?.secondary.dark,
  flex: "none",
  order: 0,
  flexGrow: 0,
  marginRight: "5px",
}));
