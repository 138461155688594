import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  styled,
} from "@mui/material";
import { useAppSelector } from "hooks";
import { Contact } from "models/onboarding/Contact";
import { PackagePost } from "models/onboarding/PackagePost";
import { Package } from "models/packages/Package";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import {
  useCreateContactMutation,
  useGetReCaptchaSiteKeyQuery,
} from "store/slices/marketplaceApi";
import StyledTextFieldWithLabel from "./StyledTextFieldWithLabel";
import { useQuery } from "react-query/react";
import { fetchBlockedDomainsCsv } from "utils/csv-parser";
import { hubspotEmailValidator } from "utils/email";

interface OnboardingFormProps {
  setOnboardingActiveStepIndex: (index: number) => void;
  setHideBackButton: (hideBackButton: boolean) => void;
}

type OnboardingForm = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyAnnualUsage: string;
  marketingEmailOptIn: boolean;
};

const OnboardingForm = ({
  setHideBackButton,
  setOnboardingActiveStepIndex,
}: OnboardingFormProps): JSX.Element => {
  useEffect(() => {
    setHideBackButton(false);
  }, []);

  const savedPackages = useAppSelector((state) => state.savedPackages);
  const { data: reCaptchaKey } = useGetReCaptchaSiteKeyQuery();
  const [createContact, { isLoading: isCreateContactPending }] =
    useCreateContactMutation();

  const [reCaptchaValue, setReCaptchaValue] = useState("");

  const { data, status } = useQuery("domains", fetchBlockedDomainsCsv);

  const { handleSubmit, control, watch } = useForm<OnboardingForm>({
    reValidateMode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      companyAnnualUsage: "",
      marketingEmailOptIn: true,
    },
  });

  const buildPostObject = (formData: OnboardingForm) => {
    const packages: PackagePost[] = savedPackages.savedPackages?.map(
      (item: Package) => {
        return {
          name: item.name,
          price: item.price,
          turnaround: item.turnaround,
          depthIds: item?.depths?.map((depth) => depth.id),
          serviceIds: item?.services?.map((service) => service.id),
        };
      },
    );

    const postObject: Contact = {
      user: formData,
      packages,
      reCaptchaAnswer: reCaptchaValue,
    };

    return postObject;
  };

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const onSubmit = handleSubmit(async (formData) => {
    reCaptchaRef?.current?.reset();
    const postObject = buildPostObject(formData);

    await createContact(postObject);

    setOnboardingActiveStepIndex(1);

    setHideBackButton(true);
  });

  const emptyRequiredFields = watch(["firstName", "lastName", "email"]).some(
    (requiredField) => !requiredField,
  );

  const reCaptchaOnChange = (token: string | null) => {
    if (token == null) {
      setReCaptchaValue("");
    } else {
      setReCaptchaValue(token);
    }
  };

  const onlyASCIIPrintableCharacters = new RegExp(/^[ 0-9a-zA-ZÀ-ÿ-']*$/);

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <Grid>
          <Controller
            control={control}
            name="firstName"
            rules={{
              required: "First Name is required.",
              validate: (value) =>
                onlyASCIIPrintableCharacters.test(value)
                  ? undefined
                  : "Please use only letters, numbers, and basic symbols.",
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextFieldWithLabel
                sx={{ marginBottom: "30px" }}
                id="first-name-input"
                label="First Name *"
                placeholder="First Name"
                errorMsg={error}
                autoFocus
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{
              required: "Last Name is required.",
              validate: (value) =>
                onlyASCIIPrintableCharacters.test(value)
                  ? undefined
                  : "Please use only letters, numbers, and basic symbols.",
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextFieldWithLabel
                sx={{ marginBottom: "30px" }}
                id="last-name-input"
                label="Last Name *"
                placeholder="Last Name"
                errorMsg={error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email Address is required.",
              validate: (value) => hubspotEmailValidator(value, status, data),
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextFieldWithLabel
                sx={{ marginBottom: "30px" }}
                id="email-address-input"
                label="Email Address *"
                placeholder="Email Address"
                errorMsg={error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="companyName"
            rules={{
              required: "Company Name is required.",
              validate: (value) =>
                onlyASCIIPrintableCharacters.test(value)
                  ? undefined
                  : "Please use only letters, numbers, and basic symbols.",
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextFieldWithLabel
                sx={{ marginBottom: "30px" }}
                id="company-name-input"
                label="Company Name (Legal Entity for Contracting Purposes) *"
                placeholder="Company Name"
                errorMsg={error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="companyAnnualUsage"
            rules={{
              required: "Estimated Number of Screenings is required.",
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextFieldWithLabel
                sx={{ marginBottom: "30px" }}
                type="number"
                id="number-of-screenings-input"
                label="What is your company's estimated number of annual background screenings? *"
                placeholder="Number of Screenings"
                errorMsg={error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="marketingEmailOptIn"
            render={({ field }) => (
              <StyledFormControlLabel
                {...field}
                control={<Checkbox checked={field.value} />}
                label="Stay up to date on industry news and information from Orange Tree."
              />
            )}
          />
          <Grid>
            {reCaptchaKey?.siteKey && (
              <ReCAPTCHA
                sitekey={reCaptchaKey?.siteKey}
                ref={reCaptchaRef}
                onChange={reCaptchaOnChange}
              />
            )}

            <StyledSubmitButton
              disabled={
                emptyRequiredFields ||
                reCaptchaValue === "" ||
                isCreateContactPending
              }
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation>
              Continue
              {isCreateContactPending ? <StyledSpinner size={20} /> : null}
            </StyledSubmitButton>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

const FormContainer = styled(Box)({
  marginTop: "40px",
  width: "100%",
});

const StyledSubmitButton = styled(Button)({
  width: "314px",
  height: "40px",
  borderRadius: "2px",
  marginTop: "25px",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: "6px",
  marginBottom: "30px",
  color: "#444444",
});

const StyledSpinner = styled(CircularProgress)({
  marginLeft: "10px",
  color: "inherit",
});

export default OnboardingForm;
