import Stepper from "./components/stepper/Stepper";
import { Box, Divider, ThemeProvider, Typography } from "@mui/material";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SelectPackage from "./pages/SelectPackage";
import CustomizePackage from "./pages/CustomizePackage";
import { useState } from "react";
import { theme } from "./styles/theme";
import ReviewPackages from "./pages/ReviewPackages";
import Header from "components/Header";
import Footer from "components/Footer";
import Onboarding from "pages/Onboarding";
import Success from "pages/Success";
import RetrievingAgreement from "pages/RetrievingAgreement";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { faqList } from "components/common/FAQ/faqList";
import FAQ from "components/common/FAQ";
import GuidedPackageBuilder from "pages/GuidedPackageBuilder";
import ChooseYourPath, {
  ChooseYourPathBar,
} from "pages/Landing/ChooseYourPath";
import { QueryClientProvider } from "react-query/react";
import { QueryClient } from "react-query/core";
import DifferentExperienceBar from "pages/Landing/DifferentExperienceBar";

export const landingPath = "/welcome";
export const selectPackagePath = "/select-package";
export const customizePopularPackagePath = "/customize-package-popular";
export const customizeCustomPackagePath = "/customize-package-custom";
export const reviewPopularPackagesPath = "/review-packages-popular";
export const reviewCustomPackagesPath = "/review-packages-custom";
export const onboardingPopularPath = "/onboarding-popular";
export const onboardingCustomPath = "/onboarding-custom";
export const successPath = "/success";
export const retrievingAgreementPath = "/retrieving-agreement";
export const guidedPackageBuilderPath = "/guided-package-builder";

function App(): JSX.Element {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [hideBackButton, setHideBackButton] = useState<boolean>(false);

  const popularPackagesSteps = [
    "Select Package",
    "Customize",
    "Review",
    "Onboarding",
  ];

  const customizePackageSteps = ["Build and customize", "Review", "Onboarding"];

  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = new QueryClient();

  const isLandingPath = location?.pathname === landingPath;
  const isSelectPackagePath = location?.pathname === selectPackagePath;
  const isCustomizePopularPackagePath =
    location?.pathname === customizePopularPackagePath;

  const isReviewPopularPackagesPath =
    location?.pathname === reviewPopularPackagesPath;

  const isReviewCustomPackagesPath =
    location?.pathname === reviewCustomPackagesPath;

  const isOnboardingPopularPath = location?.pathname === onboardingPopularPath;
  const isSuccessPath = location?.pathname === successPath;

  const isRetrievingAgreementPath =
    location?.pathname === retrievingAgreementPath;

  const isGuidedPackageBuilderPath = location?.pathname.startsWith(
    guidedPackageBuilderPath,
  );

  const displayFaq =
    (isSelectPackagePath ||
      isReviewPopularPackagesPath ||
      isReviewCustomPackagesPath) &&
    faqList?.length > 0;

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Header />
        <Box width={"100%"} minHeight={"40vw"}>
          <Box
            sx={{
              ...(!isSelectPackagePath && {
                width: "1440px",
                marginLeft: "auto",
                marginRight: "auto",
              }),
            }}>
            {!isSuccessPath &&
              !isRetrievingAgreementPath &&
              activeStepIndex != 0 &&
              !hideBackButton && (
                <Box sx={{ marginLeft: "118px" }}>
                  <KeyboardArrowLeftIcon
                    sx={{
                      marginTop: "20px",
                      color: "secondary.dark",
                    }}
                    onClick={() => navigate(-1)}
                  />
                  <Typography
                    sx={{
                      display: "inline-flex",
                      width: "33px",
                      height: "20px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      letterSpacing: "0.5px",
                      color: "secondary.dark",
                      flex: "none",
                      order: 0,
                      flexGrow: 0,
                      marginTop: "16px",
                      verticalAlign: "super",
                    }}
                    onClick={() => navigate(-1)}>
                    Back
                  </Typography>
                </Box>
              )}
            {!isLandingPath &&
              !isSuccessPath &&
              !isRetrievingAgreementPath &&
              !isGuidedPackageBuilderPath &&
              activeStepIndex < 4 && (
                <>
                  <Stepper
                    activeStepIndex={activeStepIndex}
                    steps={
                      isSelectPackagePath ||
                      isCustomizePopularPackagePath ||
                      isReviewPopularPackagesPath ||
                      isOnboardingPopularPath
                        ? popularPackagesSteps
                        : customizePackageSteps
                    }
                  />
                  <Box sx={{ minWidth: "1440px", width: "100%" }}>
                    <Divider
                      sx={{
                        width: "1282px",
                        margin: "0px auto",
                      }}
                    />
                  </Box>
                </>
              )}
            <Routes>
              <Route path={landingPath} element={<ChooseYourPath />} />
              <Route
                path={selectPackagePath}
                element={
                  <SelectPackage handleStepIndexChange={setActiveStepIndex} />
                }
              />
              <Route
                path={customizePopularPackagePath}
                element={
                  <CustomizePackage
                    handleStepIndexChange={setActiveStepIndex}
                  />
                }
              />
              <Route
                path={customizeCustomPackagePath}
                element={
                  <CustomizePackage
                    handleStepIndexChange={setActiveStepIndex}
                  />
                }
              />
              <Route
                path={reviewPopularPackagesPath}
                element={
                  <ReviewPackages handleStepIndexChange={setActiveStepIndex} />
                }
              />
              <Route
                path={reviewCustomPackagesPath}
                element={
                  <ReviewPackages handleStepIndexChange={setActiveStepIndex} />
                }
              />
              <Route
                path={onboardingPopularPath}
                element={
                  <Onboarding
                    handleStepIndexChange={setActiveStepIndex}
                    setHideBackButton={setHideBackButton}
                  />
                }
              />
              <Route
                path={onboardingCustomPath}
                element={
                  <Onboarding
                    handleStepIndexChange={setActiveStepIndex}
                    setHideBackButton={setHideBackButton}
                  />
                }
              />
              <Route path={successPath} element={<Success />} />
              <Route
                path={retrievingAgreementPath}
                element={<RetrievingAgreement />}
              />
              <Route
                path={guidedPackageBuilderPath}
                element={<GuidedPackageBuilder />}
              />

              <Route path="*" element={<Navigate to={landingPath} replace />} />
            </Routes>
          </Box>
          {isLandingPath && <ChooseYourPathBar />}
          {[
            selectPackagePath,
            customizePopularPackagePath,
            customizeCustomPackagePath,
            reviewPopularPackagesPath,
            reviewCustomPackagesPath,
            onboardingPopularPath,
            onboardingCustomPath,
            guidedPackageBuilderPath,
          ].includes(location?.pathname) && <DifferentExperienceBar />}
          {displayFaq && <FAQ faqList={faqList} />}
        </Box>

        <Footer />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
export default App;
