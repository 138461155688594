import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
} from "@reduxjs/toolkit";
import savePackageSlice from "./slices/save-package-slice";
import onboardingSlice from "./slices/onboarding-slice";
import { loadPersistedState, persistState } from "./localStorage";
import { marketplaceApi } from "./slices/marketplaceApi";

const STORAGE_KEY = "otesMktplaceState";

const reducer = combineReducers({
  [marketplaceApi.reducerPath]: marketplaceApi.reducer,
  savedPackages: savePackageSlice.reducer,
  onboarding: onboardingSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createStore = (
  options?: Partial<
    Omit<ConfigureStoreOptions<RootState>, "middleware" | "reducer">
  >,
) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(marketplaceApi.middleware),
    reducer,
    ...options,
  });

const whitelistPersistedState = ({ savedPackages }: RootState) => ({
  savedPackages,
});

const store = createStore({
  preloadedState: loadPersistedState<RootState>(
    STORAGE_KEY,
    whitelistPersistedState,
  ),
});

persistState<RootState>(store, STORAGE_KEY, whitelistPersistedState);

export default store;
export type AppDispatch = typeof store.dispatch;
