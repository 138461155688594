import {
  Box,
  BoxTypeMap,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import palette from "styles/palette";
import { FOOTER_BACKGROUND } from "styles/colors";
import { footerList } from "./footerList";

export interface FooterItem {
  label: string;
  content: string | JSX.Element;
}

interface FooterListProps {
  list?: Array<FooterItem>;
}

const FooterList = ({ list }: FooterListProps): JSX.Element => (
  <List>
    {list?.map(({ label, content }) => (
      <ListItem key={label} sx={{ padding: "0px" }} disableGutters={true}>
        {label && <ListItemLabel>{label}:</ListItemLabel>}
        <ListItemText
          sx={{ margin: "0px" }}
          disableTypography={true}
          secondary={
            <ListItemContent>{content}</ListItemContent>
          }></ListItemText>
      </ListItem>
    ))}
  </List>
);

const Footer = (): JSX.Element => {
  return (
    <FooterBox component={"footer"}>
      <Box sx={{ width: "1440px", marginLeft: "auto", marginRight: "auto" }}>
        <Box sx={{ margin: "0px 60px" }}>
          <Box>
            <Logo
              sx={{ width: "170px", height: "32px" }}
              component={"img"}
              alt={"Orange Tree logo"}
              src="/images/OTLogoVector.svg"
            />
          </Box>
          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <Support component={"label"}>Support</Support>
            <FooterList list={footerList}></FooterList>
          </Box>
          <FooterDivider />
          <Box sx={{ display: "flex", width: "1320px", marginTop: "20px" }}>
            <LegalText>
              {"© 2022 Orange Tree Employment Screening LLC "}
              <Link
                href={"https://www.orangetreescreening.com/privacy-policy"}
                underline={"none"}
                target={"_blank"}
                rel={"noopener"}
                style={{ color: palette?.primary?.main, marginLeft: "45px" }}>
                {"Privacy Policy"}
              </Link>
            </LegalText>
            <Link
              href={"https://twitter.com/WeAreOTES"}
              underline={"none"}
              target={"_blank"}
              rel={"noopener"}
              style={{ margin: "0px 32px 0px auto" }}>
              <FontAwesomeIcon
                title="Twitter logo"
                icon={faTwitter}
                size={"lg"}
                color={"white"}
              />
            </Link>
            <Link
              href={
                "https://www.linkedin.com/company/orange-tree-employment-screening"
              }
              underline={"none"}
              target={"_blank"}
              rel={"noopener"}>
              <FontAwesomeIcon
                title="LinkedIn logo"
                icon={faLinkedin}
                size={"lg"}
                color={"white"}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </FooterBox>
  );
};

const FooterBox = styled(Box)({
  position: "relative",
  width: "100%",
  minWidth: "1440px",
  height: "300px",
  background: FOOTER_BACKGROUND,
});

const Logo = styled(Box)({
  width: "170px",
  height: "31.64px",
  flex: "none",
  order: 0,
  flexGrow: 0,
  margin: "33px 0px",
}) as OverridableComponent<BoxTypeMap<Record<string, unknown>, "div">>;

const Support = styled(Typography)({
  width: "65px",
  height: "19px",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "19px",
  textTransform: "uppercase",
  color: "white",
  flex: "none",
  order: 0,
  flexGrow: 0,
}) as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;

const ListItemLabel = styled(Typography)({
  color: "white",
  fontWeight: 400,
  fontSize: "14px",
  letterSpacing: "0.5px",
  marginRight: "4px",
});

const ListItemContent = styled(Typography)(({ theme }) => ({
  width: "235px",
  height: "20px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: theme?.palette?.primary?.main,
  flex: "none",
  flexGrow: 0,
}));

const FooterDivider = styled(Divider)({
  width: "1320px",
  border: "1px solid",
  flex: "none",
  order: 0,
  flexGrow: 0,
});

const LegalText = styled(Typography)({
  width: "auto",
  height: "19px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  color: "white",
  flex: "none",
  order: 0,
  flexGrow: 0,
  display: "inline-flex",
});

export default Footer;
