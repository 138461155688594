import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const PriceAndTimeDisclaimer = styled(Typography)({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0.50px",
  color: BLACK[60],
});
