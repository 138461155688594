import { CardHeader, styled } from "@mui/material";

export const CustomizePackageCardHeader = styled(CardHeader)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  width: "864px",
  height: "64px",
  background: "#EAEDEE",
  flex: "none",
  order: 0,
  alignSelf: "stretch",
  flexGrow: 0,
});
