import { styled, Typography } from "@mui/material";

export const Disclaimer = styled(Typography)(({ theme }) => ({
  width: "auto",
  fontWeight: 500,
  fontSize: "12px",
  fontStyle: "italic",
  lineHeight: "18px",
  letterSpacing: "0.5px",
  color: theme?.palette?.primary?.dark,
  flex: "none",
  order: 2,
  flexGrow: 0,
}));
