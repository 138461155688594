import { Box, styled, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Package } from "models/packages/Package";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { BLACK } from "styles/colors";
import { TurnaroundTime } from "components/common/Typography/TurnaroundTime";
import { PriceAndTimeDisclaimer } from "components/common/Typography/PriceAndTimeDisclaimer";
import { formatCurrency } from "utils/format-currency";
import { PackageDisclaimer } from "components/common/Link/PackageDisclaimer";
import WhatDoesThisMeanDialog from "../dialog/WhatDoesThisMeanDialog";
import { useState } from "react";

export interface CardDetailsProps {
  inputPackage: Package;
}

const CardDetails = ({ inputPackage }: CardDetailsProps): JSX.Element => {
  const [openWhatDoesThisMeanDialog, setOpenWhatDoesThisMeanDialog] =
    useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "10px 8px 0px",
        gap: "8px",
      }}>
      <Box>
        <PriceText aria-label={`Package Total Price`}>
          {formatCurrency(inputPackage?.price)}
        </PriceText>
      </Box>
      <Box sx={{ color: "neutral_dark.light" }}>
        <FontAwesomeIcon icon={faClock} />
        <TurnaroundTime>
          {inputPackage?.turnaround || "Based on services selected"}
        </TurnaroundTime>
      </Box>
      <Box>
        <PriceAndTimeDisclaimer>
          *Price and turnaround times may vary
        </PriceAndTimeDisclaimer>
        <PackageDisclaimer
          onClick={() => {
            setOpenWhatDoesThisMeanDialog(true);
          }}
          underline={"hover"}
          target={"_blank"}
          rel={"noopener"}
          aria-label={"package-disclaimer"}>
          <Box sx={visuallyHidden} component={"span"}>
            Opens Pricing Disclaimer (PDF)
          </Box>
          What does this mean?
        </PackageDisclaimer>
        <WhatDoesThisMeanDialog
          open={openWhatDoesThisMeanDialog}
          handleCloseDialog={() => {
            setOpenWhatDoesThisMeanDialog(false);
          }}
        />
      </Box>
    </Box>
  );
};

const PriceText = styled(Typography)({
  verticalAlign: "super",
  fontWeight: "700",
  fontSize: "30px",
  lineHeight: "30px",
  color: BLACK[87],
  "&::after": {
    content: "'*'",
  },
  "&::first-letter": {
    marginRight: "4px",
  },
});

export default CardDetails;
