import { Box, styled } from "@mui/material";

export const PackageHeader = styled(Box)`
  padding: 52px 0px 26px 0px;
  width: 1240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  gap: 7px;
`;
