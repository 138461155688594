import { DepthOfCoverage } from "models/DepthOfCoverage";
import { Package } from "models/packages/Package";
import { ServiceOfCoverage } from "models/ServiceOfCoverage";
import { PackageBuilder } from "./packageBuilder";
import { Category } from "../../models/category/Category";

export interface GuidedPackageResponses {
  // question 1
  isMinimumExperienceRequired: boolean;
  isSpecificDegreeRequired?: boolean;
  // 2
  includesValuableAssetAccess: boolean;
  // 3
  isProfessionalLicenseRequired: boolean;
  // 4
  isHealthcareIndustry: boolean;
  // 5
  isDrivingRequired: boolean;
  isDotRegulated?: boolean;
  isFMCSARegulated?: boolean;
  // 6
  isDrugFreeWorkplace: boolean;
  candidateState?: string;
  // 7
  isFederalContract: boolean;
  // 8
  isExecutiveOrHighlyVisible: boolean;
}

export class GuidedPackageBuilder {
  packageBuilder: PackageBuilder;

  constructor(
    availableServices: ServiceOfCoverage[],
    availableDepths: DepthOfCoverage[],
    availableServiceCategories: Category[],
    availableDepthCategories: Category[],
  ) {
    this.packageBuilder = new PackageBuilder(
      availableServices,
      availableDepths,
      availableServiceCategories,
      availableDepthCategories,
    );

    // add some default packages

    // SSN trace
    this.packageBuilder.addServiceBySymbolicId("SERVICE_LOCATOR_2");
    // national criminal records
    this.packageBuilder.addServiceBySymbolicId("SERVICE_LOCATOR_3");
    // national sex offender
    this.packageBuilder.addServiceBySymbolicId("SERVICE_LOCATOR_4");
    // county/statewide criminal search
    this.packageBuilder.addServiceBySymbolicId("SERVICE_CRIMINAL_1");
    // Current & Last Name AKS used for 7 Years
    this.packageBuilder.addDepthBySymbolicId("DEPTH_NAME_2");
    // Current Address
    this.packageBuilder.addDepthBySymbolicId("DEPTH_ADDRESS_1");
    // 7 Year Search
    this.packageBuilder.addDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_1");
  }

  build(responses: GuidedPackageResponses): Partial<Package> {
    if (responses.isMinimumExperienceRequired) {
      // employment verification
      this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_2");
      // 7-year depth
      this.packageBuilder.addDepthBySymbolicId("DEPTH_EMPLOYMENT_2");

      if (responses.isSpecificDegreeRequired) {
        // education verification
        this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_1");
      }
    }

    if (responses.includesValuableAssetAccess) {
      // employment credit (summarized credit)
      this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_6");
    }

    if (responses.isProfessionalLicenseRequired) {
      // professional license verification
      this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_5");
    }

    if (responses.isHealthcareIndustry) {
      // office of inspector general
      this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_10");
      // GSA??? not sure which ID
      // this.packageBuilder.addServiceBySymbolicId("GSA_ID");
      // SAM
      this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_6");
    }

    if (responses.isDrivingRequired) {
      if (responses.isDotRegulated) {
        if (responses.isFMCSARegulated) {
          // MVR regulated
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_8");
          // PSP report
          this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_4");
          // DOT drug panel
          this.packageBuilder.addServiceBySymbolicId("SERVICE_DRUG_3");
          // FMCSA employment
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_4");
          // FMCSA clearinghouse
          this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_8");
          // CDLIST
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_9");
          // DOT employment
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_3");
          // 7-year depth
          this.packageBuilder.addDepthBySymbolicId("DEPTH_EMPLOYMENT_2");

          // remove standard employment verification
          this.packageBuilder.removeServiceBySymbolicId(
            "SERVICE_VERIFICATION_2",
          );
        } else {
          // MVR regulated
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_8");
          // PSP report
          this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_4");
          // DOT drug panel
          this.packageBuilder.addServiceBySymbolicId("SERVICE_DRUG_3");
          // DOT employment
          this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_3");
          // 7-year depth
          this.packageBuilder.addDepthBySymbolicId("DEPTH_EMPLOYMENT_2");

          // remove standard employment verification
          this.packageBuilder.removeServiceBySymbolicId(
            "SERVICE_VERIFICATION_2",
          );
        }
      } else {
        // MVR standard
        this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_7");
      }
    }

    if (responses.isFederalContract) {
      // Global Watch
      this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_3");
    }

    if (responses.isExecutiveOrHighlyVisible) {
      // Global Watch
      this.packageBuilder.addServiceBySymbolicId("SERVICE_GOVT_3");
      // social media search
      this.packageBuilder.addServiceBySymbolicId("SERVICE_VERIFICATION_12");
      // federal criminal
      this.packageBuilder.addServiceBySymbolicId("SERVICE_CRIMINAL_2");
    }

    this.packageBuilder.applyBusinessRules();

    return this.packageBuilder.build();
  }
}
