import { styled, Box } from "@mui/material";

export const Popular = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "1px 4px",
  width: "53px",
  height: "17px",
  color: "white",
  background: theme?.palette?.secondary?.main,
  borderRadius: "8px",
  flex: "none",
  order: 1,
  flexGrow: 0,
  placeContent: "center",
  marginRight: "11px",
  verticalAlign: "middle",
}));
