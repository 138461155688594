import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { FAQ_BACKGROUND } from "styles/colors";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQTitle } from "./Typography/FAQTitle";
import { FAQItem } from "./FAQ.d";
import { FAQQuestion } from "./Typography/FAQQuestion";
import { FAQAnswer } from "./Typography/FAQAnswer";

interface FAQProps {
  faqList?: Array<FAQItem>;
}

const FAQ = ({ faqList }: FAQProps): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>("");

  const maxListItems = 5;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "1440px",
        margin: "0px auto",
        background: FAQ_BACKGROUND,
      }}>
      <Box
        sx={{
          width: "1440px",
          minHeight: "224px",
          padding: "38px 120px",
          margin: "0px auto",
        }}>
        <FAQTitle>Frequently Asked Questions</FAQTitle>
        {faqList?.slice(0, maxListItems)?.map(({ question, answer }, index) => (
          <Accordion
            key={`faq-accordion-${index}`}
            sx={{
              background: FAQ_BACKGROUND,
              boxShadow: "none",
            }}
            disableGutters={true}
            expanded={expanded === `faq_${index}`}
            onChange={handleChange(`faq_${index}`)}>
            <AccordionSummary
              aria-controls={`faq-panel-content-${index}`}
              id={`faq-panel-header-${index}`}
              expandIcon={<ExpandMoreIcon />}>
              <FAQQuestion>{question}</FAQQuestion>
            </AccordionSummary>
            <AccordionDetails>
              <FAQAnswer>{answer}</FAQAnswer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
