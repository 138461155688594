import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const TurnaroundTime = styled(Typography)({
  display: "inline-flex",
  marginLeft: "8px",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "110%",
  color: BLACK[87],
  "&::after": {
    content: "'*'",
    color: BLACK[38],
  },
});
