import { styled, Typography } from "@mui/material";

export const AlertDescription = styled(Typography)(({ theme }) => ({
  width: "282px",
  height: "auto",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: theme?.palette?.secondary?.dark,
  flex: "none",
  order: 1,
  alignself: "stretch",
  flexGrow: 0,
}));
