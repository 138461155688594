import { Package } from "models/packages/Package";
import { Box } from "@mui/material";
import Card from "../Card";
import ComparePackageInfoCardContents from "./ComparePackageInfoCardContents";
import { CompareRef } from "pages/SelectPackage/SelectPackage";

export interface ComparePackageInfoCardProps {
  combinedPackage: Package;
  infoBoxHeight: number;
  compareRefs: CompareRef[];
  setCompareRefs: (compareRefs: CompareRef[]) => void;
}

const ComparePackageInfoCard = ({
  combinedPackage,
  infoBoxHeight,
  compareRefs,
  setCompareRefs,
}: ComparePackageInfoCardProps): JSX.Element => {
  return (
    <>
      <Box aria-label={"compare-package-info-card"}>
        <Card
          sx={{
            width: "380px",
            background: "#F5F8F9",
          }}>
          <ComparePackageInfoCardContents
            combinedPackage={combinedPackage}
            infoBoxHeight={infoBoxHeight}
            compareRefs={compareRefs}
            setCompareRefs={setCompareRefs}
          />
        </Card>
      </Box>
    </>
  );
};

export default ComparePackageInfoCard;
