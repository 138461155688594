import {
  Box,
  CardHeader as MuiCardHeader,
  IconButton,
  OverridableCardHeader,
  styled,
  Tooltip,
} from "@mui/material";
import { Truncate } from "components/Truncate";
import {
  CANCEL_HOVER_BACKGROUND,
  EDIT_HOVER_BACKGROUND,
  SAVE,
  SAVE_HOVER_BACKGROUND,
  TYPOGRAPHY,
} from "../../styles/colors";
import { PackageCardDisplayMode } from "./package/PackageCard";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import StyledTextFieldWithLabel from "components/Onboarding/StyledTextFieldWithLabel";
import { Edit } from "./Typography/Edit";

export interface CardHeaderProps {
  name: string;
  editName?: boolean;
  setName?: Dispatch<SetStateAction<string>>;
  description: string | null;
  descriptionLineClampEnabled?: boolean;
  displayMode?: PackageCardDisplayMode;
  sx?: Record<string, string>;
  handleOpenPackageDialog?: () => void;
}

const CardHeader = ({
  name,
  editName = false,
  setName,
  description,
  displayMode = PackageCardDisplayMode.COLLAPSED,
  sx = {},
  handleOpenPackageDialog,
}: CardHeaderProps): JSX.Element => {
  const [editHeaderName, setEditHeaderName] = useState<boolean>(false);
  const [headerName, setHeaderName] = useState<string>(name);

  const DESCRIPTION_MAX_LINES =
    displayMode === PackageCardDisplayMode.COLLAPSED ? 3 : Infinity;
  const NAME_MAX_LINES =
    displayMode === PackageCardDisplayMode.COLLAPSED ? 1 : Infinity;
  const OVERFLOWING_TEXT = (
    <Box
      component="span"
      sx={{
        color: TYPOGRAPHY["body"],
        letterSpacing: "0.5px",
        lineHeight: "20px",
        fontSize: "14px",
        fontWeight: 400,
      }}>
      {"..."}
      <Box
        sx={{ color: "secondary.dark" }}
        component={"span"}
        onClick={handleOpenPackageDialog}>
        {"Read more"}
      </Box>
    </Box>
  );

  const handlePackageNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHeaderName(event?.target?.value);
  };

  const minLength = 1;
  const maxLength = 256;

  const helperText =
    (headerName?.length < minLength && `1-256 characters required`) ||
    (headerName?.length > maxLength && `1-256 characters required`);

  const error =
    headerName?.length < minLength || headerName?.length > maxLength;

  return (
    <StyledCardHeader
      title={
        <>
          {editHeaderName ? (
            <>
              <StyledTextFieldWithLabel
                sx={{
                  maxWidth: "220px",
                  margin: "0px 8px 0px 0px",
                }}
                id="edit-package-name"
                error={error}
                helperText={helperText}
                autoFocus
                value={headerName}
                onChange={handlePackageNameChange}
              />
              <>
                {editHeaderName && (
                  <>
                    <Tooltip
                      title={"Cancel"}
                      arrow={true}
                      componentsProps={{
                        arrow: {
                          sx: {
                            color: "tertiary_grey.dark",
                          },
                        },
                        tooltip: {
                          sx: {
                            backgroundColor: "tertiary_grey.dark",
                            width: "auto",
                          },
                        },
                      }}>
                      <IconButton
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: CANCEL_HOVER_BACKGROUND,
                          },
                        }}
                        aria-label="Cancel"
                        onClick={() => {
                          setEditHeaderName(false);
                          setHeaderName(name);
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={"Save"}
                      arrow={true}
                      componentsProps={{
                        arrow: {
                          sx: {
                            color: "tertiary_grey.dark",
                          },
                        },
                        tooltip: {
                          sx: {
                            backgroundColor: "tertiary_grey.dark",
                            width: "auto",
                          },
                        },
                      }}>
                      <Box component={"span"}>
                        <IconButton
                          sx={{
                            "&:hover": {
                              backgroundColor: SAVE_HOVER_BACKGROUND,
                            },
                          }}
                          aria-label="Save"
                          disabled={error}
                          onClick={() => {
                            setName && setName(headerName);
                            setEditHeaderName(false);
                          }}>
                          <DoneIcon sx={{ color: SAVE }} />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </>
                )}
              </>
            </>
          ) : (
            <>
              <Truncate
                lines={NAME_MAX_LINES}
                ellipsis={OVERFLOWING_TEXT}
                breakWord={true}
                sx={{
                  color: TYPOGRAPHY["header"],
                  padding: "1px 0px 1px 0px",
                  letterSpacing: "0.1px",
                  lineHeight: "34px",
                  fontSize: "24px",
                  fontWeight: 500,
                  height: "34px",
                  width: "100%",
                }}>
                {name}
              </Truncate>
              {editName && (
                <Tooltip
                  title={"Edit package name"}
                  arrow={true}
                  componentsProps={{
                    arrow: {
                      sx: {
                        color: "tertiary_grey.dark",
                      },
                    },
                    tooltip: {
                      sx: {
                        backgroundColor: "tertiary_grey.dark",
                        width: "auto",
                      },
                    },
                  }}>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "80px",
                      height: "32px",
                      marginLeft: "8px",
                      "&:hover": {
                        background: EDIT_HOVER_BACKGROUND,
                      },
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditHeaderName(true)}>
                    <ModeEditIcon
                      sx={{
                        margin: "0px 10px 0px 14px",
                        fontSize: "12px",
                        color: "secondary.dark",
                        alignSelf: "flex-end",
                        verticalAlign: "middle",
                      }}
                    />
                    <Edit onClick={() => setEditHeaderName(true)}>Edit</Edit>
                  </Box>
                </Tooltip>
              )}
            </>
          )}
        </>
      }
      subheader={
        <Truncate
          lines={DESCRIPTION_MAX_LINES}
          ellipsis={OVERFLOWING_TEXT}
          sx={{
            color: TYPOGRAPHY["body"],
            letterSpacing: "0.5px",
            lineHeight: "20px",
            fontSize: "14px",
            fontWeight: 400,
            width: "100%",
          }}>
          {description}
        </Truncate>
      }
      sx={sx}
    />
  );
};

const StyledCardHeader = styled(MuiCardHeader)({
  padding: "0px 0px 8px 0px",
  width: "368px",
}) as OverridableCardHeader;

export default CardHeader;
