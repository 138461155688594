import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DepthOfCoverage } from "models/DepthOfCoverage";
import { Contact } from "models/onboarding/Contact";
import { DocusignContact } from "models/onboarding/DocusignContact";
import { Package } from "models/packages/Package";
import { ReCaptcha } from "models/ReCaptcha";
import { ServiceOfCoverage } from "models/ServiceOfCoverage";
import { SharePackage } from "models/share-package/SharePackage";
import { Category } from "../../models/category/Category";

const sortDepthsByOrderNumber = (
  obj1: DepthOfCoverage,
  obj2: DepthOfCoverage,
) => {
  if (obj1.categoryId === obj2.categoryId) {
    if (obj1.order < obj2.order) {
      return -1;
    }
    if (obj1.order > obj2.order) {
      return 1;
    }
    return 0;
  }

  return 0;
};

export const marketplaceApi = createApi({
  reducerPath: "marketplaceApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_USE_LOCAL_API_STUBS === "true"
        ? "/api"
        : // existing env file convention is to include the trailing slash, so we just
          // smush the path on here
          `${process.env.REACT_APP_BACKEND_BASE_URI ?? "/"}api`,
  }),
  endpoints: (builder) => ({
    //
    // DEPTHS
    //
    getDepths: builder.query<DepthOfCoverage[], void>({
      query: () => "depths",
      transformResponse: ({ depths }: { depths: DepthOfCoverage[] }) => {
        return depths.sort(sortDepthsByOrderNumber);
      },
    }),
    //
    // DEPTH CATEGORIES
    //
    getDepthCategories: builder.query<Category[], void>({
      query: () => "categories/depth",
      transformResponse: ({ categories }: { categories: Category[] }) => {
        return categories.filter((category) => category.description !== "");
      },
    }),
    //
    // SERVICES
    //
    getServices: builder.query<ServiceOfCoverage[], void>({
      query: () => "services",
      transformResponse: ({ services }: { services: ServiceOfCoverage[] }) => {
        return services;
      },
    }),
    //
    // SERVICE CATEGORIES
    //
    getServiceCategories: builder.query<Category[], void>({
      query: () => "categories/service",
      transformResponse: ({ categories }: { categories: Category[] }) => {
        return categories;
      },
    }),
    //
    // PACKAGES
    //
    getPackages: builder.query<Package[], void>({
      query: () => "packages",
      transformResponse: ({ packages }: { packages: Package[] }) => {
        return packages;
      },
    }),
    // CAPTCHA
    getReCaptchaSiteKey: builder.query<ReCaptcha, void>({
      query: () => "recaptcha/site-key",
    }),
    //
    // ONBOARDING
    //
    createContact: builder.mutation<void, Contact>({
      query: (contact) => ({
        url: "signup",
        method: "POST",
        body: contact,
      }),
    }),
    createDocusignContact: builder.mutation<
      // TODO extract this to shared type
      { envelopeId: string; envelopeStatus: string },
      Partial<DocusignContact>
    >({
      query: (contact) => ({
        url: "docusign",
        method: "POST",
        body: contact,
      }),
    }),
    viewAgreement: builder.query<string, string>({
      query: (envelopeId) => ({
        url: "docusign/view",
        method: "POST",
        body: { envelopeId },
        responseHandler: async (res) => {
          const buffer = await res.arrayBuffer();

          const pdf = new Blob([buffer], {
            type: "application/pdf",
          });

          const url = URL.createObjectURL(pdf);

          return url;
        },
      }),
    }),
    //
    // SHARE PACKAGE
    //
    sharePackage: builder.mutation<void, SharePackage>({
      query: (sharePackage) => ({
        url: "share-package",
        method: "POST",
        body: sharePackage,
      }),
    }),
  }),
});

export const {
  useGetDepthsQuery,
  useGetDepthCategoriesQuery,
  useGetServicesQuery,
  useGetServiceCategoriesQuery,
  useGetPackagesQuery,
  useGetReCaptchaSiteKeyQuery,
  useCreateContactMutation,
  useCreateDocusignContactMutation,
  useLazyViewAgreementQuery,
  useSharePackageMutation,
} = marketplaceApi;
