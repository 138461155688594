import {
  Card as MuiCard,
  CardActions,
  CardContent,
  styled,
} from "@mui/material";

export type CardProps = {
  children: JSX.Element | JSX.Element[];
  actions?: JSX.Element;
  sx?: Record<string, string>;
  actionSx?: Record<string, string>;
};

const Card = ({
  children,
  actions,
  sx = {},
  actionSx = {},
}: CardProps): JSX.Element => {
  return (
    <StyledCard sx={sx}>
      <CardContent>{children}</CardContent>
      {actions && <CardActions sx={actionSx}>{actions}</CardActions>}
    </StyledCard>
  );
};

const StyledCard = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 400px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 3px 1px rgba(0, 0, 0, 0.04),
    0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export default Card;
