import { styled, Typography } from "@mui/material";

export const SelectedCount = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  textAlign: "right",
  letterSpacing: "0.6px",
  color: theme?.palette?.secondary?.dark,
  marginLeft: "auto",
  marginRight: "1rem",
  alignSelf: "center",
  width: "12rem",
}));