import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const NoPackagesSaved = styled(Typography)({
  color: BLACK[87],
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "28px",
  letterSpacing: "0.25px",
  margin: "24px 0px 8px 0px",
});
