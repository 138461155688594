import { Box, Button, IconButton, styled, Typography } from "@mui/material";
import { BLACK, MEDIUM_BLUE } from "styles/colors";
import CloseIcon from "@mui/icons-material/Close";
import { OpenInNew } from "@mui/icons-material";

interface WhatDoesThisMeanDialogContentProps {
  handleCloseDialog: () => void;
}

const WhatDoesThisMeanDialogContent = ({
  handleCloseDialog,
}: WhatDoesThisMeanDialogContentProps): JSX.Element => {
  return (
    <DialogBox>
      <Box
        sx={{
          margin: "8px auto 0 373px",
        }}>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            color: BLACK[54],
            p: 0,
          }}>
          <CloseIcon sx={{ p: 0 }} />
        </IconButton>
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "22px",
            lineHeight: "32px",
            letterSpacing: "0.1px",
            paddingBottom: "14px",
          }}
          aria-label={"dialog-primary-description"}>
          Prices May Vary
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.25px",
            paddingBottom: "12px",
          }}
          aria-label={"dialog-secondary-description"}>
          Price does not include statutory and passthrough fees.
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          endIcon={<OpenInNew />}
          href={
            window.location.host === "marketplace.otes.com"
              ? "https://2644161.fs1.hubspotusercontent-na1.net/hubfs/2644161/marketplace/exhibit_a.pdf"
              : "https://22257359.fs1.hubspotusercontent-na1.net/hubfs/22257359/marketplace/exhibit_a.pdf"
          }
          target={"_blank"}
          rel={"noopener"}
          sx={{
            color: MEDIUM_BLUE[30],
            fontWeight: "500",
            fontSize: "12px",
            height: "32px",
            lineHeight: "12px",
            letterSpacing: "0.56px",
            padding: "7px 2.25px 7px 14px",
            textTransform: "none",
            width: "229px",
            marginLeft: "176px",
          }}
          aria-label={"dialog-button"}>
          Statutory and Passthrough Fees
        </Button>
      </Box>
    </DialogBox>
  );
};

const DialogBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  background: "white",
});
export default WhatDoesThisMeanDialogContent;
