import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import { TableProps } from "./Table.d";
import { TableCell } from "./TableCell";

// this component is currently used in only one place
// consider implementing rendering with a render callback
// rather than passing a RowWidget prop
function Table<T extends { name: string }>({
  id,
  columns,
  rows,
  RowWidget,
}: TableProps<T>): JSX.Element {
  return (
    <TableContainer sx={{ maxHeight: 900 }}>
      <MuiTable id={id} aria-label={`${id}`} stickyHeader={true}>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => {
              if (typeof column === "string" || column instanceof String) {
                return (
                  <TableCell key={`${column}_${index}`}>{column}</TableCell>
                );
              } else {
                const { name, columnOptions } = column;
                return (
                  <TableCell
                    key={`${name}_${index}`}
                    align={columnOptions?.align}>
                    {name}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) =>
            RowWidget ? (
              <RowWidget key={`row_widget_${index}`} row={row} index={index} />
            ) : (
              <TableRow
                key={`${row.name}_${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {Object.keys(row).map((name, index) => (
                  <TableCell key={`${name}_${index}`}>
                    <Box style={{ margin: "0px 0px 0px 74px" }}>
                      <Typography>
                        {row[name as keyof T] as unknown as string}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ),
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;
