import {
  styled,
  ToggleButton,
  Box,
  FormControl,
  FormLabel as MuiFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import Stepper, { StepperProps } from "./Stepper";
import { ReactNode } from "react";
import { useFormContext, Controller, FieldValues, Path } from "react-hook-form";

export interface QuestionProps {
  stepperProps: Pick<
    StepperProps,
    "currentStep" | "totalSteps" | "onBackClick" | "onForwardClick"
  >;
}

export const QuestionToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: "100%",
  textTransform: "none",
  justifyContent: "start",
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

export const SecondaryQuestion = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(2),
}));

export const FormLabel = styled(MuiFormLabel)(() => ({
  "&.Mui-focused": {
    color: "initial",
  },
}));

export interface YesNoQuestionProps<T extends FieldValues> {
  name: Path<T>;
  secondaryQuestionIfYes?: ReactNode;
  secondaryQuestionIfNo?: ReactNode;
}

export function YesNoQuestion<T extends FieldValues>({
  name,
  secondaryQuestionIfYes,
  secondaryQuestionIfNo,
}: YesNoQuestionProps<T>): JSX.Element {
  const { control, watch } = useFormContext<T>();

  const response = watch(name);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <QuestionToggleButton
            name={name}
            value={true}
            selected={value === true}
            onChange={() => onChange(true)}
            onBlur={onBlur}>
            Yes
          </QuestionToggleButton>
        )}
      />

      {response === true && secondaryQuestionIfYes !== undefined ? (
        <SecondaryQuestion>{secondaryQuestionIfYes}</SecondaryQuestion>
      ) : null}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <QuestionToggleButton
            name={name}
            value={false}
            selected={value === false}
            onChange={() => onChange(false)}
            onBlur={onBlur}>
            No
          </QuestionToggleButton>
        )}
      />

      {response === false && secondaryQuestionIfNo !== undefined ? (
        <SecondaryQuestion>{secondaryQuestionIfNo}</SecondaryQuestion>
      ) : null}
    </>
  );
}

export interface YesNoRadioProps<T extends FieldValues> {
  label: ReactNode;
  name: Path<T>;
  id: string;
}

export function YesNoRadio<T extends FieldValues>({
  label,
  name,
  id,
}: YesNoRadioProps<T>): JSX.Element {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <FormControl fullWidth>
          <FormLabel id={id}>{label}</FormLabel>
          <RadioGroup
            aria-labelledby={id}
            name={name}
            onBlur={onBlur}
            // TODO should we use nulls instead of undefined for blank responses?
            value={value === undefined ? null : value}>
            <FormControlLabel
              sx={{ m: 1 }}
              value={true}
              onChange={() => onChange(true)}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ m: 1 }}
              value={false}
              onChange={() => onChange(false)}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

export interface StepProps {
  title: ReactNode;
  subtitle: ReactNode;
  stepperProps: Omit<StepperProps, "children">;
  children: ReactNode;
}

export function Step({
  title,
  // subtitle,
  stepperProps,
  children,
}: StepProps): JSX.Element {
  return (
    <Stepper {...stepperProps}>
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" marginBottom={2}>
          {title}
        </Typography>

        {/* <Typography marginBottom={2}>{subtitle}</Typography> */}

        {children}
      </Box>
    </Stepper>
  );
}

export interface YesNoStepProps<T extends FieldValues> {
  name: Path<T>;
  title: ReactNode;
  subtitle: ReactNode;
  stepperProps: Pick<
    StepperProps,
    "currentStep" | "totalSteps" | "onBackClick" | "onForwardClick"
  >;
}

export function YesNoStep<T extends FieldValues>({
  name,
  title,
  subtitle,
  stepperProps,
}: YesNoStepProps<T>): JSX.Element {
  const { watch } = useFormContext<T>();

  const response = watch(name);
  const isForwardDisabled = response === undefined;

  return (
    <Step
      title={title}
      subtitle={subtitle}
      stepperProps={{
        ...stepperProps,
        forwardDisabled: isForwardDisabled,
      }}>
      <YesNoQuestion name={name} />
    </Step>
  );
}
