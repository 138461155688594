import { Box, styled } from "@mui/material";

export const PackageFooter = styled(Box)({
  width: "1440px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  margin: "14px auto 0px auto",
  gap: "7px",
});
