import {
  Box,
  Divider,
  Step,
  Stepper as MuiStepper,
  styled,
  Typography,
} from "@mui/material";
import { STEPPER } from "styles/colors";

export type OnboardingStepperProps = {
  activeOnboardingStepIndex: number;
};

const OnboardingStepper = ({
  activeOnboardingStepIndex,
}: OnboardingStepperProps): JSX.Element => {
  const steps = ["1. Contact Information", "2. User Compliance and Agreement"];

  return (
    <StyledBox>
      <MuiStepper activeStep={activeOnboardingStepIndex}>
        {steps?.map((stepLabel, index) => {
          const active = activeOnboardingStepIndex === index;

          return (
            <Step key={index} sx={{ width: "50%", padding: 0 }}>
              <StyledStepBox>
                <StyledStepText active={active.toString()}>
                  {stepLabel}
                </StyledStepText>
              </StyledStepBox>

              <StyledDivider
                active={active.toString()}
                activeonboardingstepindex={activeOnboardingStepIndex}
              />
            </Step>
          );
        })}
      </MuiStepper>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  flex-direction: column;
  justify-content: center;
  width: 1037px;
  align-items: center;
  display: flex;
  height: 28px;
  padding: 16px 24px 1px 0px;
  border-radius: 8px;
`;

const StyledStepText = styled(Typography)`
  height: 28px;
  font-size: 14px;
  line-heights: 20px;
  letter-spacing: 0.25px;
  color: ${(props: { active: string }) =>
    props.active === "true" ? "#0090E0" : STEPPER["inactive"]};
  font-weight: ${(props: { active: string }) =>
    props.active === "true" ? "500" : "400"};
`;

const StyledStepBox = styled(Box)`
  width: 518px;
  display: flex;
  justify-content: space-around;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 0px auto;
  border-bottom: ${(props: {
    active: string;
    activeonboardingstepindex: number;
  }) =>
    props.active === "true"
      ? `3px solid #008EE0`
      : props.activeonboardingstepindex === 0
      ? `0.5px solid`
      : `none`};
`;

export default OnboardingStepper;
