import {
  Box,
  Collapse,
  Divider,
  ListItem as MuiListItem,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import { PackageCardDisplayMode } from "../card/package/PackageCard";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export type PackageCardListItemProps = {
  itemId: string;
  openItemId: string;
  handleItemToggle: (itemId: string) => void;
  displayMode: PackageCardDisplayMode;
  name: string;
  description: string;
  disclaimer?: string;
  visible: boolean;
};

const PackageCardListItem = (props: PackageCardListItemProps): JSX.Element => {
  const isOpen = () => props.itemId === props.openItemId;

  const generateAccordionCaret = () => {
    return (
      props.displayMode === PackageCardDisplayMode.EXPANDED && (
        <Box sx={{ color: "neutral_dark.light" }}>
          <FontAwesomeIcon
            icon={isOpen() ? faCaretUp : faCaretDown}
            size={"xs"}
          />
        </Box>
      )
    );
  };

  const generateAccordion = () => {
    return (
      props.displayMode === PackageCardDisplayMode.EXPANDED && (
        <Collapse in={isOpen()} timeout={"auto"} unmountOnExit>
          <ItemDescription>{props.description}</ItemDescription>
        </Collapse>
      )
    );
  };

  const generateTooltip = () => {
    return (
      props.displayMode === PackageCardDisplayMode.COLLAPSED && (
        <Tooltip title={props.description} arrow placement={"right"}>
          <Box
            sx={{
              verticalAlign: "middle",
              color: "neutral_dark.light",
              fontSize: "12px",
              marginLeft: "8px",
            }}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Box>
        </Tooltip>
      )
    );
  };

  return (
    <Box
      sx={{
        display: props.visible ? "block" : "none",
      }}>
      <StyledListItem
        onClick={() => props.handleItemToggle(props.itemId)}
        id={props.itemId}>
        <StyledListItemText
          primary={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {props.name}
              {generateTooltip()}
            </Box>
          }
          secondary={
            props?.disclaimer && (
              <Box component="span" sx={{ fontWeight: 500 }}>
                {props?.disclaimer}
              </Box>
            )
          }
          primaryTypographyProps={{ sx: { color: "text.primary" } }}
          secondaryTypographyProps={{ sx: { color: "primary.dark" } }}
        />
        {generateAccordionCaret()}
      </StyledListItem>
      {generateAccordion()}
      <Divider sx={{ width: "352px" }} />
    </Box>
  );
};

const StyledListItem = styled(MuiListItem)`
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px 4px 0px;
  gap: 1;
`;

const StyledListItemText = styled(ListItemText)({
  fontWeight: "400",
  margin: "0px",
  ".MuiListItemText-primary": {
    minHeight: "20px",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  ".MuiListItemText-secondary": {
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.5px",
  },
});

const ItemDescription = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
`;

export default PackageCardListItem;
