import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const OptionDescription = styled(Typography)({
  width: "637px",
  minHeight: "60px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: BLACK[60],
  flex: "none",
  order: 2,
  flexGrow: 0,
});
