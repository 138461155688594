import { Box, CircularProgress, styled, Typography } from "@mui/material";
import Page from "components/page/Page";
import { useEffect } from "react";

export const PAGE_TITLE = `Retrieving your agreement!`;
export const PAGE_SUBTITLE = `This may take several seconds...`;

const RetrievingAgreement = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page label={"retrieving-pdf-page"}>
      <OnboardingContainer>
        <PageTitle>{PAGE_TITLE}</PageTitle>
        <Subtitle>{PAGE_SUBTITLE}</Subtitle>
        <StyledSpinner size={50} />
      </OnboardingContainer>
    </Page>
  );
};

const StyledSpinner = styled(CircularProgress)({
  color: "inherit",
});

const PageTitle = styled(Typography)({
  height: "40px",
  fontWeight: "500",
  fontSize: "30px",
  lineHeight: "28px",
  letterSpacing: "0.25px",
  marginLeft: "7px",
  color: "#1B222D",
});

const Subtitle = styled(Typography)({
  marginTop: "27px",
  height: "60px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
});

const OnboardingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "60px",
});

export default RetrievingAgreement;
