import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Package } from "models/packages/Package";

export type SavedPackageStoreState = {
  savedPackages: Package[];
};

const savePackageSlice = createSlice({
  name: "saved-packages",
  initialState: {
    savedPackages: [],
  } as SavedPackageStoreState,
  reducers: {
    savedPackageAdded(state, action: PayloadAction<Package>) {
      state.savedPackages = [...state.savedPackages, action.payload];
    },
    savedPackageChanged(
      state,
      action: PayloadAction<{
        id: string;
        changes: Partial<Package>;
      }>,
    ) {
      const { id, changes } = action.payload;

      state.savedPackages = state.savedPackages.map((r) => {
        if (r.packageId !== id) {
          return r;
        }

        return {
          ...r,
          ...changes,
        };
      });
    },
    savedPackageRemoved(state, action: PayloadAction<string>) {
      state.savedPackages = state.savedPackages.filter((r) => {
        return r.packageId !== action.payload;
      });
    },
    savedPackagesReceived(state, action) {
      state.savedPackages = action.payload;
    },
    savedPackagesCleared(state) {
      state.savedPackages = [];
    },
  },
});

export default savePackageSlice;
