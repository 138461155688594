import { Box, styled } from "@mui/material";
import { BUILD_CUSTOM_PACKAGE_BACKGROUND } from "styles/colors";

export const BuildCustomPackage = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "9px",
  width: "100%",
  height: "87px",
  left: "95px",
  top: "1398px",
  background: BUILD_CUSTOM_PACKAGE_BACKGROUND,
  borderRadius: "8px",
  margin: "153px auto 45px auto",
});
