import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const CustomPackageQuestion = styled(Typography)({
  height: "28px",
  fontSize: "18px",
  lineHeight: "28px",
  textAlign: "center",
  letterSpacing: "0.25px",
  color: BLACK[87],
  flex: "none",
  order: 0,
  flexGrow: 0,
});
