import { styled, Button } from "@mui/material";

export const BuildCustomPackageButton = styled(Button)(({ theme }) => ({
  width: "258px",
  height: "40px",
  color: theme?.palette?.primary.dark,
  border: `2px solid ${theme?.palette?.primary.dark}}`,
  fontWeight: 500,
  lineHeight: "16.41px",
  letter: "1px",
}));
