import { styled, Typography } from "@mui/material";

export const DisplayCountFooter = styled(Typography)`
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding-top: 2px;
  margin-right: 15px;
`;
