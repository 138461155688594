import { styled, Typography } from "@mui/material";

export const PopularTag = styled(Typography)({
  display: "contents",
  width: "45px",
  height: "18px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0.5px",
  color: "white",
  flex: "none",
  order: 0,
  flexGrow: 0,
});
