export const lastOccurrenceWhere = <T>(array: T[], predicate: (a: T) => boolean): T => {
    let right = array.length - 1;
    let left = 0;

    while (left <= right) {
        const middle: number = Math.floor((left + right) / 2);

        predicate(array[middle]) ? left = middle + 1 : right = middle - 1
    }

    return array[left -1];
};
