import { createTheme, tooltipClasses } from "@mui/material";
import {
  BACKGROUND_DISABLED,
  BLUE,
  DISABLED,
  MENU_ITEM_BACKGROUND,
} from "./colors";
import palette from "./palette";

export const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette,
  components: {
    MuiTypography: {
      defaultProps: {
        fontStyle: "normal",
      },
    },
    MuiLink: {
      defaultProps: {
        fontFamily: "Roboto",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: DISABLED,
            backgroundColor: BACKGROUND_DISABLED,
          },
        },
        outlined: {
          "&.MuiButton-outlined": {
            height: "40px",
            padding: "6px 22px",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "14px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            borderRadius: "2px",
            "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: "0px" },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: BLUE[30],
            border: `2px solid ${BLUE[30]}`,
            fontWeight: "700",
            "&:hover": {
              color: BLUE[30],
              backgroundColor: "white",
              border: `2px solid ${BLUE[30]}`,
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          color: "#DBE0E2",
          height: "1px",
          width: "200px",
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          alignSelf: "flex-start",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: palette?.neutral_dark?.light,
          padding: "4px",
          margin: "0 5px",
          "&.Mui-checked": {
            color: palette?.secondary?.main,
          },
          "&:hover": {
            backgroundColor: "rgba(0, 142, 224, 0.04)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: palette?.neutral_dark?.light,
          padding: "4px",
          margin: "0 5px",
          "&.Mui-checked": {
            color: palette?.secondary?.main,
          },
          "&:hover": {
            backgroundColor: "rgba(0, 142, 224, 0.04)",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "14px",
          "&.Mui-focused": {
            color: palette?.secondary?.main,
          },
          "&[data-shrink='false']": {
            minHeight: "14px",
            lineHeight: "8px",
            overflow: "inherit",
          },
          "&.MuiInputLabel-shrink": {
            fontSize: "16px",
            lineHeight: "22px",
          },
          whiteSpace: "unset",
          overflow: "unset",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette?.secondary?.main,
          },
          "& svg.svg-inline--fa": {
            color: palette?.secondary?.dark,
          },
          "& .MuiSelect-iconOutlined": {
            transform: "scale(1.5)",
          },
          "& .MuiSelect-iconOpen": {
            transform: "scale(1.5) rotate(180deg)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": { backgroundColor: MENU_ITEM_BACKGROUND },
          "&.Mui-selected:hover": {
            backgroundColor: MENU_ITEM_BACKGROUND,
          },
          "&.Mui-selected": {
            backgroundColor: MENU_ITEM_BACKGROUND,
          },
          "&.Mui-focusVisible": {
            backgroundColor: MENU_ITEM_BACKGROUND,
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: MENU_ITEM_BACKGROUND,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded:before": {
            opacity: 1,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          alignSelf: "center",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "9px 32px 9px 8px !important",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "tertiary_grey.dark",
          width: "auto",
          fontWeight: "400",
          fontSize: "10px",
          lineHeight: "18px",
          letterSpacing: "0.5px",
          padding: "4px 8px",
          borderRadius: "4px",
          filter:
            "drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1))",
          [`.${tooltipClasses.popper}[data-popper-placement*="left"] &`]: {
            marginRight: "6px",
          },
          [`.${tooltipClasses.popper}[data-popper-placement*="right"] &`]: {
            marginLeft: "6px",
          },
        },
        arrow: {
          color: "tertiary_grey.dark",
        },
      },
    },
  },
});
