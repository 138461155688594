import {
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { DocusignContact } from "models/onboarding/DocusignContact";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { OnboardingStoreState } from "store/slices/onboarding-slice";
import StyledTextFieldWithLabel from "./StyledTextFieldWithLabel";
import { useCreateDocusignContactMutation } from "store/slices/marketplaceApi";
import { useQuery } from "react-query/react";
import { hubspotEmailValidator } from "utils/email";
import { fetchBlockedDomainsCsv } from "../../utils/csv-parser";

type UserAgreementForm = {
  fullName: string;
  email: string;
};

interface UserAgreementProps {
  activeButton: string;
  setActiveButton: (activeButton: string) => void;
}

const UserAgreementForm = ({
  activeButton,
  setActiveButton,
}: UserAgreementProps): JSX.Element => {
  const onboardingStore: OnboardingStoreState = useSelector(
    (state: RootState) => state.onboarding,
  );

  const [createDocusignContact, { isLoading: isCreateContactPending }] =
    useCreateDocusignContactMutation();

  const { data, status } = useQuery("domains", fetchBlockedDomainsCsv);

  const { handleSubmit, control, watch } = useForm<UserAgreementForm>({
    reValidateMode: "onBlur",
    defaultValues: {
      fullName: "",
      email: "",
    },
  });

  const buildPostObject = (formData: UserAgreementForm) => {
    // TODO should we throw if onboardingSubmission and/or envelopeId are not available?
    let postObject: DocusignContact = {
      signerFullName: formData.fullName,
      signerEmail: formData.email,
      envelopeStatus: "sent",
      onboardingSubmission: onboardingStore.onboardingSubmission ?? undefined,
    };

    if (onboardingStore.viewedAgreement) {
      postObject = {
        ...postObject,
        envelopeId: onboardingStore.envelopeId ?? undefined,
      };
    }

    return postObject;
  };

  const onSubmit = handleSubmit(async (formData) => {
    setActiveButton("sendButton");
    const postObject = buildPostObject(formData);
    await createDocusignContact(postObject);
  });

  const emptyRequiredFields = watch(["fullName", "email"]).some(
    (requiredField) => !requiredField,
  );

  const onlyASCIIPrintableCharacters = new RegExp(/^[ 0-9a-zA-ZÀ-ÿ-']*$/);

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
            letterSpacing: "0.25px",
          }}>
          Send agreement for signature
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.5px",
            marginBottom: "27px",
            width: "465px",
          }}>
          To complete Onboarding, the following agreement must be signed by an
          Authorized Company Representative*
        </Typography>
        <Grid>
          <Controller
            control={control}
            name="fullName"
            rules={{
              required: "Signer's full Name is required.",
              validate: (value) =>
                onlyASCIIPrintableCharacters.test(value)
                  ? undefined
                  : "Please use only letters, numbers, and basic symbols.",
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledUserFormInput
                id="full-name-input"
                label="Enter signer's full name"
                placeholder="Enter full name"
                errorMsg={error}
                autoFocus
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email Address is required.",
              validate: (value) => hubspotEmailValidator(value, status, data),
            }}
            render={({ field, fieldState: { error } }) => (
              <StyledUserFormInput
                id="email-address-input"
                label="Enter signer's email"
                placeholder="Enter email"
                errorMsg={error}
                {...field}
              />
            )}
          />
          <Grid>
            <StyledSubmitButton
              disabled={emptyRequiredFields || isCreateContactPending}
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation>
              Send
              {isCreateContactPending && activeButton === "sendButton" && (
                <StyledSpinner size={15} />
              )}
            </StyledSubmitButton>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

const FormContainer = styled(Box)({
  width: "600px",
  height: "245px",
  marginTop: "40px",
});

const StyledSubmitButton = styled(Button)({
  width: "89px",
  height: "32px",
  borderRadius: "2px",
  marginTop: "12px",
});

const StyledSpinner = styled(CircularProgress)({
  marginLeft: "10px",
  color: "inherit",
});

const StyledUserFormInput = styled(StyledTextFieldWithLabel)({
  marginBottom: "15px",
  width: "462px",
});

export default UserAgreementForm;
