import { Box } from "@mui/material";
import { customizePopularPackagePath, selectPackagePath } from "App";
import SummaryCard from "components/card/summary/SummaryCard";
import { CustomizePackageCardHeader } from "components/CustomizePackageCard/Card/CustomizePackageCardHeader";
import { CustomizePackageCardTitle } from "components/CustomizePackageCard/Card/CustomizePackageCardTitle";
import { StyledCustomizePackageCard } from "components/CustomizePackageCard/Card/StyledCustomizePackageCard";
import CustomizePackageCard from "components/CustomizePackageCard/CustomizePackageCard";
import Page from "components/page/Page";
import { DepthOfCoverage } from "models/DepthOfCoverage";
import { ServiceOfCoverage } from "models/ServiceOfCoverage";
import {
  PackageBuilder,
  PackageBuilderAlert,
  PackageBuilderAlertType,
} from "pages/GuidedPackageBuilder/packageBuilder";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  useGetDepthCategoriesQuery,
  useGetDepthsQuery,
  useGetServiceCategoriesQuery,
  useGetServicesQuery,
} from "store/slices/marketplaceApi";
import { LocationState } from "types/LocationState.d";
import { PackageDepthOfCoverage } from "../../models/packages/PackageDepthOfCoverage";
import { CustomizePackageProps } from "./CustomizePackage.d";
import { StyledSubtitle } from "./Typography/StyledSubtitle";
import { StyledTitle } from "./Typography/StyledTitle";
import { Category } from "models/category/Category";

export const PAGE_TITLE = "Build and customize";
export const PAGE_SUBTITLE =
  "Click into each category to customize your selection and make sure you have the coverage you need.";

const SEVEN_YEAR_DEPTH_IDS = new Set([
  "DEPTH_ADDRESS_2",
  "DEPTH_ADDRESS_3",
  "DEPTH_ADDRESS_4",
  "DEPTH_NAME_2",
  "DEPTH_NAME_3",
]);

const TEN_YEAR_DEPTH_IDS = new Set([
  "DEPTH_ADDRESS_5",
  "DEPTH_ADDRESS_6",
  "DEPTH_ADDRESS_7",
  "DEPTH_NAME_4",
  "DEPTH_NAME_5",
]);

const makeDepthsFilter = (packageBuilder: PackageBuilder) => {
  if (packageBuilder.hasDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_1")) {
    // 7-year depth selected; hide 10-year depth options
    return ({ symbolicId }: PackageDepthOfCoverage) => {
      return !TEN_YEAR_DEPTH_IDS.has(symbolicId);
    };
  } else if (packageBuilder.hasDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_2")) {
    // 10-year depth selected; hide 7-year depth options
    return ({ symbolicId }: PackageDepthOfCoverage) => {
      return !SEVEN_YEAR_DEPTH_IDS.has(symbolicId);
    };
  } else {
    // no criminal depth option selected; don't bother filtering
    // options at this stage as they will not be visible anyway
    return () => true;
  }
};

const EMPTY_VALUE: unknown[] = [];

const formatAlerts = (
  alerts: PackageBuilderAlert[],
  depthCategories: Category[],
) => {
  return alerts.map((alert) => {
    if (alert.type === PackageBuilderAlertType.SSN) {
      return `Social Security Number Trace (Bureau) and/or Social Security Number
        Trace may not be the only service selected. National Criminal Records Search has been
        automatically selected.`;
    } else if (alert.type === PackageBuilderAlertType.DefaultDepth) {
      const depthCategoryTitle = depthCategories?.find(
        ({ id }) => id === alert.depth.categoryId,
      )?.name;

      return `Your depth of coverage for ${depthCategoryTitle} has been automatically selected.`;
    } else {
      return "Unknown alert";
    }
  });
};

const groupByCategoryOptions = (
  categoryOptions: (DepthOfCoverage | ServiceOfCoverage)[],
) => {
  return categoryOptions?.reduce((groupCategoryOptions, categoryOption) => {
    const { categoryId } = categoryOption;
    groupCategoryOptions[categoryId] = groupCategoryOptions[categoryId] ?? [];
    groupCategoryOptions[categoryId].push(categoryOption);
    return groupCategoryOptions;
  }, {} as Record<number, (DepthOfCoverage | ServiceOfCoverage)[]>);
};

const sortCategoryOptions = (
  groupedByCategoryOptions: Record<
    number,
    (DepthOfCoverage | ServiceOfCoverage)[]
  > = {},
) => {
  return Object.keys(groupedByCategoryOptions)?.reduce(
    (sortedGroupCategoryOptions, groupCategoryKey) => {
      const categoryId = Number(groupCategoryKey);
      sortedGroupCategoryOptions[categoryId] = [
        ...groupedByCategoryOptions[categoryId],
      ]?.sort(
        (firstCategoryOption, secondCategoryOption) =>
          firstCategoryOption.order - secondCategoryOption.order,
      );
      return sortedGroupCategoryOptions;
    },
    {} as Record<number, (DepthOfCoverage | ServiceOfCoverage)[]>,
  );
};

const CustomizePackage = ({
  handleStepIndexChange,
}: CustomizePackageProps): JSX.Element => {
  const { data: depths, isFetching: isDepthsFetching } = useGetDepthsQuery();
  const { data: rawServices, isFetching: isServicesFetching } =
    useGetServicesQuery();
  const { data: depthCategories, isFetching: isDepthCategoriesFetching } =
    useGetDepthCategoriesQuery();
  const { data: serviceCategories, isFetching: isServiceCategoriesFetching } =
    useGetServiceCategoriesQuery();

  // the drug-testing category is unique in that only one option can be selected
  // - here we supplement the list of services returned from the API with a special "none"
  // option which can be used to clear the user's selection
  const services = useMemo(() => {
    // services not yet available
    if (rawServices === undefined) {
      return undefined;
    }

    return [
      ...rawServices,
      {
        categoryId: 5,
        id: -1,
        symbolicId: "NONE",
        name: "None",
        description: "",
        order: 100,
        pricingRules: [{ price: 0, depthIds: [] }],
        requiredDepthCategoryIds: [],
      } as ServiceOfCoverage,
    ];
  }, [rawServices]);

  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;
  const { selectedPackage } = locationState;
  const [alertMessages, setAlertMessages] = useState<string[]>([]);

  const initialPackage = selectedPackage;

  const [packageBuilder, setPackageBuilder] = useState<
    PackageBuilder | undefined
  >();

  useEffect(() => {
    if (
      services === undefined ||
      depths === undefined ||
      serviceCategories === undefined ||
      depthCategories === undefined
    ) {
      // package builder not yet ready
      return;
    }

    if (packageBuilder !== undefined) {
      // package builder has already been initialized
      return;
    }

    // initialize the package builder
    const builder = new PackageBuilder(
      services,
      depths,
      serviceCategories,
      depthCategories,
    );

    if (initialPackage !== undefined) {
      builder.name = initialPackage.name;

      initialPackage.services.forEach(({ id }) => builder.addService(id));
      initialPackage.depths.forEach(({ id }) => builder.addDepth(id));

      builder.applyBusinessRules();
    }

    setPackageBuilder(builder);
  }, [
    services,
    depths,
    serviceCategories,
    depthCategories,
    setPackageBuilder,
    initialPackage,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    location?.pathname === customizePopularPackagePath
      ? handleStepIndexChange(1)
      : handleStepIndexChange(0);
  }, []);

  const loading =
    isDepthsFetching ||
    isServicesFetching ||
    isDepthCategoriesFetching ||
    isServiceCategoriesFetching ||
    packageBuilder === undefined;

  const depthsFilter = useMemo(() => {
    if (packageBuilder === undefined) {
      return () => true;
    }

    return makeDepthsFilter(packageBuilder);
  }, [packageBuilder]);

  const sortedDepthsCategoryOptions = useMemo(
    () =>
      sortCategoryOptions(
        groupByCategoryOptions((depths ?? []).filter(depthsFilter)),
      ) as Record<number, DepthOfCoverage[]>,
    [depths, depthsFilter],
  );

  const sortedServicesCategoryOptions = useMemo(
    () =>
      sortCategoryOptions(groupByCategoryOptions(services ?? [])) as Record<
        number,
        ServiceOfCoverage[]
      >,
    [services],
  );

  const visibleDepthCategories = useMemo(() => {
    const assignedServices = packageBuilder?.assignedServices ?? [];

    const selectedDepthCategoryIds = [...assignedServices].reduce((acc, r) => {
      r.requiredDepthCategoryIds.forEach((depthCategoryId) =>
        acc.add(depthCategoryId),
      );

      return acc;
    }, new Set<number>());

    return depthCategories?.filter((r) => selectedDepthCategoryIds.has(r.id));
  }, [packageBuilder]);

  if (
    location?.pathname === customizePopularPackagePath &&
    initialPackage === undefined
  ) {
    return <Navigate to={selectPackagePath} />;
  }

  if (loading || packageBuilder === undefined) {
    return <Page label={"customize-package-page"} />;
  }

  return (
    <Page label={"customize-package-page"}>
      <Box>
        <StyledTitle component={"h1"} color="tertiary.dark">
          {location?.pathname === customizePopularPackagePath
            ? "Customize"
            : "Build and customize"}
        </StyledTitle>
        <StyledSubtitle color="common.black">{PAGE_SUBTITLE}</StyledSubtitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "24px",
            marginTop: "24px",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "56px",
            }}>
            <Box aria-label="customize-package-card">
              <StyledCustomizePackageCard>
                <CustomizePackageCardHeader
                  disableTypography={true}
                  title={
                    <CustomizePackageCardTitle>
                      Services
                    </CustomizePackageCardTitle>
                  }
                />
                {serviceCategories?.map((serviceCategory) => {
                  const options =
                    sortedServicesCategoryOptions[serviceCategory.id];

                  if (serviceCategory.singleSelect) {
                    const values =
                      packageBuilder.assignedServiceIdsByCategory[
                        serviceCategory.id
                      ] ?? EMPTY_VALUE;
                    const value = values.length ? values[0] : -1;

                    return (
                      <CustomizePackageCard
                        key={serviceCategory.id}
                        category={serviceCategory}
                        options={options}
                        value={value}
                        onChange={(id: number) => {
                          const nextPackageBuilder = packageBuilder.clone();

                          if (value) {
                            nextPackageBuilder.removeService(value);
                          }

                          if (id > 0) {
                            nextPackageBuilder.addService(id);
                          }

                          const alerts =
                            nextPackageBuilder.applyBusinessRules();

                          setAlertMessages(
                            formatAlerts(alerts, depthCategories ?? []),
                          );
                          setPackageBuilder(nextPackageBuilder);
                        }}
                      />
                    );
                  } else {
                    const values =
                      packageBuilder.assignedServiceIdsByCategory[
                        serviceCategory.id
                      ] ?? EMPTY_VALUE;

                    return (
                      <CustomizePackageCard
                        multiple
                        key={serviceCategory.id}
                        category={serviceCategory}
                        options={options}
                        values={values}
                        onChange={(id: number) => {
                          const nextPackageBuilder = packageBuilder.clone();

                          nextPackageBuilder.hasService(id)
                            ? nextPackageBuilder.removeService(id)
                            : nextPackageBuilder.addService(id);

                          const alerts =
                            nextPackageBuilder.applyBusinessRules();

                          setAlertMessages(
                            formatAlerts(alerts, depthCategories ?? []),
                          );
                          setPackageBuilder(nextPackageBuilder);
                        }}
                      />
                    );
                  }
                })}
              </StyledCustomizePackageCard>
            </Box>

            {visibleDepthCategories && visibleDepthCategories.length > 0 ? (
              <Box aria-label="customize-package-card">
                <StyledCustomizePackageCard>
                  <CustomizePackageCardHeader
                    disableTypography={true}
                    title={
                      <CustomizePackageCardTitle>
                        Depth of coverage
                      </CustomizePackageCardTitle>
                    }
                  />
                  {visibleDepthCategories?.map((depthCategory) => {
                    const options =
                      sortedDepthsCategoryOptions[depthCategory.id];
                    const value =
                      packageBuilder.assignedDepthIdsByCategory[
                        depthCategory.id
                      ] ?? undefined;

                    return (
                      <CustomizePackageCard
                        value={value}
                        key={depthCategory.id}
                        category={depthCategory}
                        options={options}
                        onChange={(id: number) => {
                          const nextPackageBuilder = packageBuilder.clone();

                          nextPackageBuilder.addDepth(id);

                          const alerts =
                            nextPackageBuilder.applyBusinessRules();

                          setAlertMessages(
                            formatAlerts(alerts, depthCategories ?? []),
                          );
                          setPackageBuilder(nextPackageBuilder);
                        }}
                      />
                    );
                  })}
                </StyledCustomizePackageCard>
              </Box>
            ) : null}
          </Box>
          <SummaryCard
            customizedPackage={
              initialPackage
                ? {
                    // apply the PackageBuilder changes on top of the original underlying package so that its ID etc. are preserved
                    ...initialPackage,
                    ...packageBuilder.build(),
                  }
                : packageBuilder.build()
            }
            alertMessages={alertMessages}
            setAlertMessages={setAlertMessages}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default CustomizePackage;
