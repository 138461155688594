import {
  Box,
  Divider,
  List as MuiList,
  styled,
  Typography,
} from "@mui/material";
import { PackageCardListItemProps } from "./PackageCardListItem";
import { useRef } from "react";
import ComparePackageInfoCardListItem from "./ComparePackageInfoCardListItem";
import { CompareRef } from "pages/SelectPackage/SelectPackage";

export type ComparePackageInfoCardListProps = {
  title: string;
  openItemId: string;
  handleItemToggle: (itemId: string) => void;
  items: Array<PackageCardListItemProps>;
  width?: string;
  compareRefs: CompareRef[];
  setCompareRefs: (compareRefs: CompareRef[]) => void;
};

const ComparePackageInfoCardList = ({
  title,
  openItemId,
  handleItemToggle,
  items,
  width = "200px",
  compareRefs,
  setCompareRefs,
}: ComparePackageInfoCardListProps): JSX.Element => {
  const childRefs = useRef<Array<HTMLDivElement | unknown>>([]);

  return (
    <StyledList width={width} id={title}>
      <ListTitle color="text.primary">{title}</ListTitle>
      <Divider sx={{ width: { width } }} />
      {items.map((item, index) => {
        return (
          <Box
            ref={(el) => (childRefs.current[index] = el)}
            key={`${title}-${index}`}>
            <ComparePackageInfoCardListItem
              key={`${title}-${index}`}
              itemId={`${title}-${index}`}
              openItemId={openItemId}
              handleItemToggle={handleItemToggle}
              name={item.name}
              description={item.description}
              disclaimer={item?.disclaimer}
              compareRefs={compareRefs}
              setCompareRefs={setCompareRefs}
            />
          </Box>
        );
      })}
    </StyledList>
  );
};
const StyledList = styled(MuiList)`
  width: ${(props: { width: string }) => props.width};
  padding: 8px 8px 12px 8px;
`;

const ListTitle = styled(Typography)`
  height: 24px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 0px 0px 4px 0px;
`;

export default ComparePackageInfoCardList;
