import { Package } from "models/packages/Package";
import Card from "../Card";
import { useState } from "react";
import { Box } from "@mui/material";
import { SharePackage } from "./Typography/SharePackage";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { SharePackageBox } from "./Box/SharePackageBox";
import SharePackageDialog from "components/SharePackageDialog";
import ComparePackageCardContents from "./ComparePackageCardContents";
import { CompareRef } from "pages/SelectPackage/SelectPackage";

export interface ComparePackageCardProps {
  inputPackage: Package;
  combinedPackage: Package;
  infoBoxHeight: number;
  compareRefs: CompareRef[];
}

const ComparePackageCard = ({
  inputPackage,
  combinedPackage,
  infoBoxHeight,
  compareRefs,
}: ComparePackageCardProps): JSX.Element => {
  const [openSharePackageDialog, setOpenSharePackageDialog] =
    useState<boolean>(false);

  const handleOpenSharePackageDialog = () => setOpenSharePackageDialog(true);
  const handleCloseSharePackageDialog = () => setOpenSharePackageDialog(false);

  const selectedPackage =
    inputPackage &&
    ({
      name: inputPackage?.name,
      price: inputPackage?.price,
      turnaround: inputPackage?.turnaround,
      depths: inputPackage?.depths,
      services: inputPackage?.services,
    } as Package);

  return (
    <>
      <SharePackageDialog
        open={openSharePackageDialog}
        packages={[selectedPackage]}
        handleCloseSharePackageDialog={handleCloseSharePackageDialog}
      />
      <Box aria-label={"compare-package-card"}>
        <Card
          sx={{
            width: "300px",
          }}
          actionSx={{
            padding: "0px",
            margin: "0px",
            width: "inherit",
            height: "auto",
          }}
          actions={
            <SharePackageBox>
              <EmailRoundedIcon
                fontSize={"small"}
                htmlColor={"primary.dark"}
                sx={{ marginRight: "6px", cursor: "pointer" }}
                onClick={handleOpenSharePackageDialog}
              />
              <SharePackage
                sx={{ cursor: "pointer" }}
                onClick={handleOpenSharePackageDialog}>
                Share package
              </SharePackage>
            </SharePackageBox>
          }>
          <ComparePackageCardContents
            inputPackage={inputPackage}
            infoBoxHeight={infoBoxHeight}
            combinedPackage={combinedPackage}
            compareRefs={compareRefs}
          />
        </Card>
      </Box>
    </>
  );
};

export default ComparePackageCard;
