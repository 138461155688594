import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import StyledTextFieldWithLabel from "components/Onboarding/StyledTextFieldWithLabel";
import { EmailCount } from "./Typography/EmailCount";
import { BLACK, EMAIL_CHIP_BACKGROUND } from "styles/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  SharePackage,
  SharePackageDialogProps,
  SharePackageStatusDialogProps,
} from "./SharePackageDialog.d";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ReCAPTCHA from "react-google-recaptcha";
import {
  useGetReCaptchaSiteKeyQuery,
  useSharePackageMutation,
} from "store/slices/marketplaceApi";
import { Package } from "models/share-package/Package";
import { hubspotEmailValidator, isValidEmail } from "utils/email";
import { useQuery } from "react-query/react";
import { fetchBlockedDomainsCsv } from "../../utils/csv-parser";

const SharePackageStatusDialog = ({
  open,
  children,
  handleCloseSharePackageStatusDialog,
}: SharePackageStatusDialogProps): JSX.Element => {
  return (
    <Dialog
      maxWidth={"md"}
      onClose={handleCloseSharePackageStatusDialog}
      aria-labelledby="share-package-dialog"
      open={open}>
      <DialogTitle sx={{ marginBottom: "0px", padding: "27px 40px" }}>
        <IconButton
          aria-label={"Close"}
          onClick={handleCloseSharePackageStatusDialog}
          sx={{
            position: "absolute",
            right: 24,
            top: 10,
            color: BLACK[54],
          }}>
          <CloseIcon sx={{ padding: "4px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "564px",
          height: "336px",
          padding: "0px 40px",
        }}>
        <Box sx={{ marginTop: "14px", textAlign: "center" }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

const SharePackageDialog = ({
  open,
  packages,
  handleCloseSharePackageDialog,
}: SharePackageDialogProps): JSX.Element => {
  const { data: reCaptchaKey } = useGetReCaptchaSiteKeyQuery();

  const [sharePackage, { isLoading: isSharePackagePending }] =
    useSharePackageMutation();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitted, isSubmitSuccessful },
    reset,
    setError,
    clearErrors,
  } = useForm<SharePackage>({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      emailFrom: "",
      emailTo: "",
      marketingEmailOptIn: true,
    },
  });

  const [openSharePackageStatusDialog, setOpenSharePackageStatusDialog] =
    useState<boolean>(false);

  const [sharePackageSuccessStatus, setSharePackageSuccessStatus] =
    useState<boolean>(false);

  const [reCaptchaValue, setReCaptchaValue] = useState("");

  const handleOpenSharePackageStatusDialog = () =>
    setOpenSharePackageStatusDialog(true);

  const handleCloseSharePackageStatusDialog = () => {
    setEmails([]);
    setOpenSharePackageStatusDialog(false);
  };

  const [emails, setEmails] = useState<string[]>([]);

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const { data, status } = useQuery("domains", fetchBlockedDomainsCsv);

  const handleDeleteToEmail = (deleteToEmailIndex: number) => () => {
    const newEmails = [...emails];
    newEmails?.splice(deleteToEmailIndex, 1);
    setEmails(newEmails);
    if (isSubmitted) {
      if (newEmails?.some((email) => !isValidEmail(email))) {
        setError("emailTo", {
          type: "required",
          message: "Invalid email format",
        });
      } else {
        clearErrors("emailTo");
      }
      if (!newEmails?.length) {
        setError("emailTo", {
          type: "required",
          message: "Email Address is required",
        });
      }
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const reCaptchaOnChange = (token: string | null) => {
    if (token == null) {
      setReCaptchaValue("");
    } else {
      setReCaptchaValue(token);
    }
  };

  const emptyRequiredFields =
    watch(["firstName", "lastName", "emailFrom", "emailTo"]).some(
      (requiredField) => !requiredField,
    ) ||
    (isSubmitted && !emails?.length);

  const onlyASCIIPrintableCharacters = new RegExp(/^[ 0-9a-zA-ZÀ-ÿ-']*$/);

  const onSharePackageSubmit = handleSubmit(async (formData) => {
    reCaptchaRef?.current?.reset();

    if (isSharePackagePending) {
      return;
    }

    sharePackage({
      packages: packages.map(
        ({ name, price, turnaround, depths, services }) =>
          ({
            name,
            price,
            turnaround,
            depthIds: depths?.map((depth) => depth.id),
            serviceIds: services?.map((service) => service.id),
          } as Package),
      ),
      user: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailFrom,
        marketingEmailOptIn: formData.marketingEmailOptIn,
      },
      recipientEmails: emails,
      reCaptchaAnswer: reCaptchaValue,
    })
      .unwrap()
      .then(() => {
        setSharePackageSuccessStatus(true);
        handleOpenSharePackageStatusDialog();
      })
      .catch(() => {
        setSharePackageSuccessStatus(false);
        handleOpenSharePackageStatusDialog();
      });

    handleCloseSharePackageDialog();
  });

  const handleEmailToBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event?.target?.value;
    const newEmails = [...emails, ...((value && value?.split(",")) || [])];
    value && setEmails(newEmails);

    if (newEmails?.some((email) => !isValidEmail(email))) {
      setError("emailTo", {
        type: "required",
        message: "Invalid email format",
      });
    } else {
      clearErrors("emailTo");
    }
    if (!emails?.length && !value) {
      setError("emailTo", {
        type: "required",
        message: "Email Address is required",
      });
    }
  };

  const handleEmailToOnChange = (
    event: React.SyntheticEvent<Element, Event>,
  ) => {
    const target = event?.target as HTMLInputElement;
    const value = target?.value;
    const newEmails = [...emails, ...((value && value?.split(",")) || [])];
    value && setEmails(newEmails);
  };

  return (
    <>
      <Dialog
        maxWidth={"md"}
        onClose={handleCloseSharePackageDialog}
        aria-labelledby="share-package-dialog"
        open={open}>
        <DialogTitle sx={{ marginBottom: "0px", padding: "27px 40px" }}>
          <IconButton
            aria-label={"Close"}
            onClick={handleCloseSharePackageDialog}
            sx={{
              position: "absolute",
              right: 24,
              top: 10,
              color: BLACK[54],
            }}>
            <CloseIcon sx={{ padding: "4px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "564px",
            minHeight: "585px",
            height: "100%",
            padding: "0px 40px",
          }}>
          <Box sx={{ marginTop: "14px" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                lineHeight: "32px",
                letterSpacing: "0.1px",
                color: BLACK[87],
                flex: "none",
                order: 1,
                alignSelf: "stretch",
                flexGrow: 0,
                marginBottom: "14px",
              }}>
              {packages?.length === 1 ? "Share Package" : "Share Packages"}
            </Typography>
            <form id={"share-package"} onSubmit={onSharePackageSubmit}>
              <Controller
                control={control}
                name={"firstName"}
                rules={{
                  required: "First Name is required.",
                  validate: (value) =>
                    onlyASCIIPrintableCharacters.test(value)
                      ? undefined
                      : "Please use only letters, numbers, and basic symbols.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledTextFieldWithLabel
                    sx={{ maxWidth: "564px", marginBottom: "36px" }}
                    id={"share-package-first-name-from"}
                    label={"Enter your first name. *"}
                    placeholder={"First Name"}
                    errorMsg={error}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={"lastName"}
                rules={{
                  required: "Last Name is required.",
                  validate: (value) =>
                    onlyASCIIPrintableCharacters.test(value)
                      ? undefined
                      : "Please use only letters, numbers, and basic symbols.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledTextFieldWithLabel
                    sx={{ maxWidth: "564px", marginBottom: "36px" }}
                    id={"share-package-last-name-from"}
                    label={"Enter your last name. *"}
                    placeholder={"Last Name"}
                    errorMsg={error}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={"emailFrom"}
                rules={{
                  required: "Email Address is required.",
                  validate: (value) =>
                    hubspotEmailValidator(value, status, data),
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledTextFieldWithLabel
                    sx={{ maxWidth: "564px", marginBottom: "24px" }}
                    id={"share-package-email-from"}
                    label={"Enter your email address. *"}
                    placeholder={"sampleemail@sample.com"}
                    errorMsg={error}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={"emailTo"}
                rules={{
                  required: "Email Address is required.",
                  validate: () => {
                    return emails?.some((email) => !isValidEmail(email))
                      ? "Invalid email format"
                      : undefined;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    id={"email_to"}
                    options={[]}
                    value={emails}
                    multiple={true}
                    freeSolo={true}
                    clearIcon={null}
                    clearOnBlur={true}
                    onChange={handleEmailToOnChange}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={`emailTo_${index}`}
                          sx={{ backgroundColor: EMAIL_CHIP_BACKGROUND }}
                          variant={"outlined"}
                          label={option}
                          deleteIcon={
                            <CancelIcon
                              sx={{
                                "&&": {
                                  color: "secondary.dark",
                                  fontSize: "18px",
                                },
                              }}
                            />
                          }
                          onDelete={handleDeleteToEmail(index)}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <>
                        <InputLabel
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.25px",
                          }}>
                          {
                            "Enter the email addresses of the recipients you want to share with. Add multiple by separating email address with commas. *"
                          }
                        </InputLabel>
                        <TextField
                          sx={{ marginBottom: "33px" }}
                          {...params}
                          {...field}
                          variant={"outlined"}
                          type={"email"}
                          placeholder={
                            !emails?.length
                              ? "sampleemail@sample.com, sampletwo@sample.com"
                              : ""
                          }
                          size={"small"}
                          inputProps={{ ...params?.inputProps, multiple: true }}
                          error={!!error}
                          helperText={error?.message}
                          onBlur={handleEmailToBlur}
                        />
                      </>
                    )}
                  />
                )}
              />
              {emails?.length > 0 && (
                <EmailCount>{`${emails.length} Emails added`}</EmailCount>
              )}
              <Controller
                control={control}
                name="marketingEmailOptIn"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox checked={field.value} />}
                    label="Stay up to date on industry news and information from Orange Tree."
                  />
                )}
              />
            </form>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0 40px 40px",
          }}>
          {reCaptchaKey?.siteKey && (
            <ReCAPTCHA
              sitekey={reCaptchaKey.siteKey}
              ref={reCaptchaRef}
              onChange={reCaptchaOnChange}
            />
          )}
          <Button
            form={"share-package"}
            type={"submit"}
            disabled={
              emptyRequiredFields ||
              reCaptchaValue === "" ||
              isSharePackagePending
            }
            aria-label="Share"
            sx={{
              width: "126px",
              height: "54px",
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "0.5rem",
            }}
            variant={"contained"}
            color={"secondary"}>
            {"Share"}
          </Button>
        </DialogActions>
      </Dialog>
      <SharePackageStatusDialog
        open={openSharePackageStatusDialog}
        handleCloseSharePackageStatusDialog={
          handleCloseSharePackageStatusDialog
        }>
        {sharePackageSuccessStatus ? (
          <>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                lineHeight: "32px",
                letterSpacing: "0.1px",
                color: BLACK[87],
                marginTop: "54px",
                marginBottom: "18px",
              }}>
              {`Your ${
                packages.length === 1 ? "package has" : "packages have"
              } been shared!`}
            </Typography>
            <Box
              sx={{
                width: "478px",
                margin: "0px auto",
                textAlign: "start",
              }}>
              <Box>
                <CheckRoundedIcon
                  width={"40px"}
                  htmlColor={"#298E51"}
                  sx={{
                    position: "absolute",
                    fontSize: "57px",
                    margin: "33px 220px",
                    zIndex: 1,
                  }}
                />
              </Box>
              {emails.map((email, index) => (
                <Chip
                  key={`success_email_${index}`}
                  sx={{
                    backgroundColor: "#E2F8EB",
                    color: "#36BD6C",
                    border: "1px solid #36BD6C",
                    opacity: 0.32,
                    margin: "0px 6px 12px 0px",
                  }}
                  variant={"outlined"}
                  label={email}></Chip>
              ))}
            </Box>
          </>
        ) : (
          <>
            <ErrorOutlineIcon
              sx={{ marginTop: "106px" }}
              htmlColor={"#B3261E"}
            />
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.25px",
                color: BLACK[87],
              }}>
              {"Something went wrong. Please try again later."}
            </Typography>
          </>
        )}
      </SharePackageStatusDialog>
    </>
  );
};

export default SharePackageDialog;
