export const ORANGE = {
  30: "#DB6C22",
  40: "#F58026",
  50: "#F0C198",
};

export const BLUE = {
  30: "#067BBE",
  40: "#008EE0",
  50: "#34A5E6",
};

export const BLUE_GREY = {
  40: "#1B222D",
  50: "#84888E",
  60: "#A7AAAE",
};

export const GREY = {
  40: "#5F6D79",
  50: "#A9B0B7",
  60: "#C1C7CB",
};

export const LIGHT_GREY = {
  40: "#BCC5C9",
  50: "#DBE0E2",
  80: "#F0F2F3",
  90: "#FAFBFB",
};

export const MEDIUM_BLUE = {
  30: "#00649D",
};

export const TYPOGRAPHY = {
  header: "#222222",
  body: "#444444",
};

export const NEUTRAL_DARK = {
  30: "#485661",
  40: "#57636C",
  50: "#646F77",
};

export const STEPPER = {
  active: "#0372B3",
  inactive: "#5F6D79",
  divider: "#BDBDBD",
};

export const BLACK = {
  12: "rgba(0, 0, 0, 0.12)",
  38: "rgba(0, 0, 0, 0.38)",
  54: "rgba(0, 0, 0, 0.54)",
  55: "rgba(0, 0, 0, 0.55)",
  60: "rgba(0, 0, 0, 0.6)",
  87: "rgba(0, 0, 0, 0.87)",
};

export const SELECT_PACKAGES_HEADER = "#FAFBFB";

export const FOOTER_BACKGROUND = "#2B2B2B";

export const CHECK = "#0372B3";

export const PACKAGE_BADGE_COUNT = "#0372B3";

export const DISABLED = "#979FA4";

export const BACKGROUND_DISABLED = "#D8DBDD";

export const BUILD_CUSTOM_PACKAGE_BACKGROUND = "rgba(252, 243, 234, 0.5)";

export const MENU_ITEM_BACKGROUND = "#F2FAFF";

export const EXPAND_COVERAGE = "#0372B3";

export const EDIT_HOVER_BACKGROUND = "#CCE7F9";

export const CANCEL_HOVER_BACKGROUND = "#EAEDEE";

export const SAVE = "#36BD6C";

export const SAVE_HOVER_BACKGROUND = "#E2F6EA";

export const ALERT_BACKGROUND = "#F2FAFF";

export const ALERT_INFO_ICON = "#0090E0";

export const ALERT_CLOSE_ICON = "#007EC4";

export const FAQ_BACKGROUND = "#FBFCFD";

export const FAQ_TITLE = "#030608";

export const FAQ_ANSWER = "#8A9399";

export const SHARE_PACKAGE = "#007EC4";

export const SHARE_PACKAGE_BACKGROUND = "#EAEDEE";

export const EMAIL_CHIP_BACKGROUND = "#FCF3EA";

export const SUCCESS_CHECK = "#36BD6C";

export const SUCCESS_BLUE = "#007EC4";
