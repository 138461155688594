export interface BusinessRule {
  serviceId: string;
  affectedDepthCategoryCode: string;
  defaultDepthId: string;
}

export const businessRules: BusinessRule[] = [
  {
    serviceId: "SERVICE_LOCATOR_3",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_LOCATOR_4",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_CRIMINAL_1",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_CRIMINAL_2",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_GOVT_3",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_GOVT_5",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_CRIMINAL_1",
    affectedDepthCategoryCode: "ADDRESS",
    defaultDepthId: "DEPTH_ADDRESS_2",
  },
  {
    serviceId: "SERVICE_CRIMINAL_2",
    affectedDepthCategoryCode: "ADDRESS",
    defaultDepthId: "DEPTH_ADDRESS_2",
  },
  {
    serviceId: "SERVICE_VERIFICATION_2",
    affectedDepthCategoryCode: "EMPLOYMENT",
    defaultDepthId: "DEPTH_EMPLOYMENT_2",
  },
  {
    serviceId: "SERVICE_VERIFICATION_3",
    affectedDepthCategoryCode: "EMPLOYMENT",
    defaultDepthId: "DEPTH_EMPLOYMENT_2",
  },
  {
    serviceId: "SERVICE_VERIFICATION_4",
    affectedDepthCategoryCode: "EMPLOYMENT",
    defaultDepthId: "DEPTH_EMPLOYMENT_2",
  },
  {
    serviceId: "SERVICE_CRIMINAL_1",
    affectedDepthCategoryCode: "CRIMINAL_LAWSUITS",
    defaultDepthId: "DEPTH_CRIMINAL_LAWSUITS_1",
  },
  {
    serviceId: "SERVICE_CRIMINAL_2",
    affectedDepthCategoryCode: "CRIMINAL_LAWSUITS",
    defaultDepthId: "DEPTH_CRIMINAL_LAWSUITS_1",
  },
  {
    serviceId: "SERVICE_GOVT_9",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_GOVT_10",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_GOVT_1",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_1",
  },
  {
    serviceId: "SERVICE_GOVT_2",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_1",
  },
  {
    serviceId: "SERVICE_GOVT_6",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_GOVT_7",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_2",
  },
  {
    serviceId: "SERVICE_VERIFICATION_13",
    affectedDepthCategoryCode: "NAME",
    defaultDepthId: "DEPTH_NAME_1",
  },
];
