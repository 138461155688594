import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { QuestionProps, YesNoStep } from "../Step";

export default function ProfessionalLicenseStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  return (
    <YesNoStep<GuidedPackageResponses>
      stepperProps={stepperProps}
      name="isProfessionalLicenseRequired"
      title="Does the role you're hiring for require a professional license?"
      subtitle="This helps us know if you need to add professional license verification to your package."
    />
  );
}
