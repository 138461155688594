import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import ComparePackageCard from "components/card/package/ComparePackageCard";
import ComparePackageInfoCard from "components/card/package/ComparePackageInfoCard";
import PackageCard from "components/card/package/PackageCard";
import ComparePackageDialog from "components/dialog/ComparePackageDialog";
import { useAppDispatch } from "hooks";
import { DepthOfCoverage } from "models/DepthOfCoverage";
import { Package } from "models/packages/Package";
import { PackageDepthOfCoverage } from "models/packages/PackageDepthOfCoverage";
import { PackageService } from "models/packages/PackageService";
import { useEffect, useRef, useState } from "react";
import {
  useGetDepthsQuery,
  useGetPackagesQuery,
  useGetServiceCategoriesQuery,
  useGetServicesQuery,
} from "store/slices/marketplaceApi";
import { BLACK, BLUE, SELECT_PACKAGES_HEADER } from "../../styles/colors";
import { PackageFooter } from "./Box/PackageFooter";
import { PackageGrid } from "./Box/PackageGrid";
import { PackageHeader } from "./Box/PackageHeader";
import FilterChips from "./FilterChips";
import { DisplayCountFooter } from "./Typography/DisplayCountFooter";
import { DisplayToggleFooterLink } from "./Typography/DisplayToggleFooterLink";
import { DisplayToggleHeaderLink } from "./Typography/DisplayToggleHeaderLink";
import { FilterLabel } from "./Typography/FilterLabel";
import { PopularPackagesHeader } from "./Typography/PopularPackagesHeader";
import { SelectionCountHeader } from "./Typography/SelectionCountHeader";

export interface SelectPackageProps {
  handleStepIndexChange: (index: number) => void;
}

export interface CompareRef {
  id: string;
  height: number;
}

const DISPLAY_COUNT_COLLAPSED = 3;

const INDUSTRY_OPTIONS = [
  { label: "All Industries", value: "All Industries" },
  { label: "Finance", value: "Finance" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Pharmaceuticals", value: "Pharmaceuticals" },
  { label: "Staffing", value: "Staffing" },
  { label: "Transportation", value: "Transportation" },
];

const TYPE_OF_SERVICE_OPTIONS = [
  { label: "Locator", value: "LOCATOR" },
  { label: "Criminal", value: "CRIMINAL" },
  { label: "Government Database", value: "GOVERNMENT_DATABASE" },
  { label: "Verification", value: "VERIFICATION" },
];

const SelectPackage = ({
  handleStepIndexChange,
}: SelectPackageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [compareRefs, setCompareRefs] = useState<CompareRef[]>([]);

  const { data: packages = [], isFetching: isPackagesFetching } =
    useGetPackagesQuery();
  const { data: services = [], isFetching: isServicesFetching } =
    useGetServicesQuery();
  const { data: depths = [], isFetching: isDepthsFetching } =
    useGetDepthsQuery();
  const {
    data: serviceCategories = [],
    isFetching: isServiceCategoriesFetching,
  } = useGetServiceCategoriesQuery();

  const serviceIds = services.map((service) => service.id);

  const findDepthBySymbolicId = (value: string) =>
    depths.find(({ symbolicId }) => symbolicId === value) ?? {
      name: "Unknown",
      value: "UNKNOWN",
    };

  const findServiceCategoryById = (value: number) =>
    serviceCategories.find(({ id }) => id === value) ?? {
      symbolicId: "Unknown",
    };

  const findServiceById = (value: string) =>
    TYPE_OF_SERVICE_OPTIONS.find((option) => option.value === value) ?? {
      label: "Unknown",
      value: "UNKNOWN",
    };

  const [displayProps, setDisplayProps] = useState<{
    displayAll: boolean;
    count: number;
  }>({
    displayAll: false,
    count: DISPLAY_COUNT_COLLAPSED,
  });

  const [industry, setIndustry] = useState<string>("");
  const [typeOfService, setTypeOfService] = useState<string[]>([]);

  const [depthOfCoverage, setDepthOfCoverage] = useState<string[]>([]);

  const typeOfServiceRef = useRef(null);
  const depthOfCoverageRef = useRef(null);
  const packageFilter = Boolean(
    industry || typeOfService?.length || depthOfCoverage?.length,
  );

  const handleIndustryOptionChange = (event: SelectChangeEvent) => {
    setIndustry(event.target.value);
    setDisplayProps((prevDisplayProps) => ({
      ...prevDisplayProps,
      displayAll: false,
    }));
  };

  const handleTypeOfServiceOptionChange = (
    event: SelectChangeEvent<typeof typeOfService>,
  ) => {
    let {
      target: { value },
    } = event;

    if (typeof value === "string") {
      value = value.split(",");
    }

    setTypeOfService(value);
    setDisplayProps((prevDisplayProps) => ({
      ...prevDisplayProps,
      displayAll: false,
    }));
  };

  const handleDepthOfCoverageOptionChange = (
    event: SelectChangeEvent<typeof depthOfCoverage>,
  ) => {
    let {
      target: { value },
    } = event;

    if (typeof value === "string") {
      value = value.split(",");
    }

    setDepthOfCoverage(value);
    setDisplayProps((prevDisplayProps) => ({
      ...prevDisplayProps,
      displayAll: false,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleStepIndexChange(0);
  }, [dispatch]);

  const isLoading =
    isPackagesFetching ||
    isServicesFetching ||
    isDepthsFetching ||
    isServiceCategoriesFetching;

  const defaultPackages = Array(displayProps.count).fill({});

  const sortedPackages: Package[] = !isLoading
    ? packages?.map((item: Package) => {
        return {
          ...item,
          packageType: "popular",
          services: [...item.services]?.sort((a, b) => {
            return serviceIds
              ? serviceIds?.indexOf(a.id) - serviceIds?.indexOf(b.id)
              : 0;
          }),
        };
      }) ?? []
    : defaultPackages;

  let filterPackages: Package[] = [];
  const hasIndustry = (target: Package): boolean =>
    !industry || industry === "All Industries" || target.industry === industry;

  const hasService = (target: Package): boolean => {
    return (
      !typeOfService?.length ||
      target.services.some(({ categoryId }) =>
        typeOfService.includes(findServiceCategoryById(categoryId).symbolicId),
      )
    );
  };

  const hasDepth = (target: Package): boolean => {
    return (
      !depthOfCoverage?.length ||
      target.depths.some(({ symbolicId }) =>
        depthOfCoverage.includes(symbolicId),
      )
    );
  };

  // TODO: this should be in the slice
  filterPackages = sortedPackages.filter(
    (current) =>
      hasIndustry(current) && hasService(current) && hasDepth(current),
  );

  const handleToggleItemCount = () => {
    setIndustry("");
    setTypeOfService([]);
    setDepthOfCoverage([]);
    setDisplayProps({
      displayAll: !displayProps.displayAll,
      count: !displayProps.displayAll
        ? sortedPackages?.length
        : DISPLAY_COUNT_COLLAPSED,
    });
  };

  const generateDisplayCountHeaderText = () => {
    if (packageFilter) {
      return `Displaying ${filterPackages.length} selections`;
    } else {
      return displayProps.displayAll
        ? "Displaying all of our selections"
        : "Displaying our Top 3 selections";
    }
  };

  const generateShowOptionHeaderText = () => {
    if (packageFilter) {
      return "Show all packages";
    } else {
      return displayProps.displayAll
        ? "Show our Top 3 selections"
        : "Show all packages";
    }
  };

  const generatePackageHeader = () => {
    return (
      <Box
        sx={{
          backgroundColor: SELECT_PACKAGES_HEADER,
          width: "100%",
          minWidth: "1440px",
        }}>
        <PackageHeader>
          <Box sx={{fontFamily: "Roboto", fontStyle: "normal", letterSpacing: "0.1px", marginBottom: "25px", marginTop: "-30px"}}>
            <Box>
              Welcome to Orange Tree, where you can buy background and drug screening services. It&apos;s as easy as 1-2-3:
            </Box>
            <ol>
              <li>Select and customize your package.</li>
              <li>Provide your contact information.</li>
              <li>Review and sign agreement.</li>
            </ol>
            <Box>
              Get started by browsing popular packages below.
            </Box>
          </Box>
          <PopularPackagesHeader component={"h1"}>
            Popular Packages
          </PopularPackagesHeader>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <SelectionCountHeader>
              {generateDisplayCountHeaderText()}
            </SelectionCountHeader>
            <Link
              underline={"none"}
              component={"button"}
              onClick={handleToggleItemCount}
              aria-label={"display-toggle-header"}
              sx={{ color: "secondary.dark" }}>
              <DisplayToggleHeaderLink>
                {generateShowOptionHeaderText()}
              </DisplayToggleHeaderLink>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "30px",
              width: "1240px",
            }}>
            <FilterLabel>Refine your search:</FilterLabel>
            <FormControl style={{ marginLeft: "2rem" }} fullWidth>
              <InputLabel id="industry-label">Industry</InputLabel>
              <Select
                // displayEmpty
                labelId="industry-label"
                id="industry-select"
                value={industry}
                label="Industry"
                onChange={handleIndustryOptionChange}>
                {INDUSTRY_OPTIONS?.map(({ label, value }, index) => (
                  <MenuItem value={value} key={`${label}_${index}`}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginLeft: "2rem" }} fullWidth>
              <InputLabel id={"type-of-service-select-label"}>
                Type of service
              </InputLabel>
              <Select
                sx={{
                  "& .MuiSelect-multiple": {
                    textOverflow: "clip !important",
                  },
                }}
                ref={typeOfServiceRef}
                labelId={"type-of-service-select-label"}
                id={"type-of-service-select"}
                multiple
                value={typeOfService}
                label="Type of service"
                onChange={handleTypeOfServiceOptionChange}
                renderValue={(selected) => {
                  const selectedFilters = selected
                    .map((s) => findServiceById(s))
                    .filter((s) => s !== undefined);

                  return (
                    <FilterChips
                      value={selectedFilters}
                      formatChip={({ label }) => label}
                      parentRef={typeOfServiceRef}
                      onDelete={({ value }) =>
                        setTypeOfService(
                          typeOfService.filter((v) => v !== value),
                        )
                      }
                    />
                  );
                }}>
                {TYPE_OF_SERVICE_OPTIONS?.map(({ label, value }, index) => (
                  <MenuItem value={value} key={`${label}_${index}`}>
                    <Checkbox checked={typeOfService.indexOf(value) > -1} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginLeft: "2rem" }} fullWidth>
              <InputLabel id="mscf-chip-label">Depth of coverage</InputLabel>
              <Select
                sx={{
                  "& .MuiSelect-multiple": {
                    textOverflow: "clip !important",
                  },
                }}
                ref={depthOfCoverageRef}
                labelId="mscf-chip-label"
                id="mscf-chip"
                multiple
                value={depthOfCoverage}
                onChange={handleDepthOfCoverageOptionChange}
                input={
                  <OutlinedInput
                    id="select-mscf-chip"
                    label="Depth of coverage"
                  />
                }
                renderValue={(selected) => {
                  const selectedFilters = selected
                    .map((s) => {
                      const depth = findDepthBySymbolicId(s) as DepthOfCoverage;
                      return depth;
                    })
                    .filter((s) => s !== undefined);

                  return (
                    <FilterChips
                      value={selectedFilters}
                      formatChip={({ name }) => {
                        const maxLabelChars = 20;
                        const truncatedLabelName =
                          name.length > maxLabelChars
                            ? name.substring(0, maxLabelChars) + `...`
                            : name;
                        return truncatedLabelName;
                      }}
                      parentRef={depthOfCoverageRef}
                      onDelete={({ symbolicId }) =>
                        setDepthOfCoverage(
                          depthOfCoverage.filter((v) => v !== symbolicId),
                        )
                      }
                    />
                  );
                }}>
                <ListSubheader>Name and Aliases</ListSubheader>
                <MenuItem value={"DEPTH_NAME_1"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_NAME_1") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_NAME_1")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_NAME_2"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_NAME_2") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_NAME_2")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_NAME_3"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_NAME_3") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_NAME_3")?.name}
                  />
                </MenuItem>
                <ListSubheader>Addresses</ListSubheader>
                <MenuItem value={"DEPTH_ADDRESS_1"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_ADDRESS_1") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_ADDRESS_1")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_ADDRESS_2"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_ADDRESS_2") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_ADDRESS_2")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_ADDRESS_3"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_ADDRESS_3") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_ADDRESS_3")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_ADDRESS_4"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_ADDRESS_4") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_ADDRESS_4")?.name}
                  />
                </MenuItem>
                <ListSubheader>Criminal</ListSubheader>
                <MenuItem value={"DEPTH_CRIMINAL_LAWSUITS_1"}>
                  <Checkbox
                    checked={
                      depthOfCoverage.indexOf("DEPTH_CRIMINAL_LAWSUITS_1") > -1
                    }
                  />
                  <ListItemText
                    primary={
                      findDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_1")?.name
                    }
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_CRIMINAL_LAWSUITS_2"}>
                  <Checkbox
                    checked={
                      depthOfCoverage.indexOf("DEPTH_CRIMINAL_LAWSUITS_2") > -1
                    }
                  />
                  <ListItemText
                    primary={
                      findDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_2")?.name
                    }
                  />
                </MenuItem>
                <ListSubheader>Employment</ListSubheader>
                <MenuItem value={"DEPTH_EMPLOYMENT_1"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_EMPLOYMENT_1") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_EMPLOYMENT_1")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_EMPLOYMENT_2"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_EMPLOYMENT_2") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_EMPLOYMENT_2")?.name}
                  />
                </MenuItem>
                <MenuItem value={"DEPTH_EMPLOYMENT_3"}>
                  <Checkbox
                    checked={depthOfCoverage.indexOf("DEPTH_EMPLOYMENT_3") > -1}
                  />
                  <ListItemText
                    primary={findDepthBySymbolicId("DEPTH_EMPLOYMENT_3")?.name}
                  />
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </PackageHeader>
      </Box>
    );
  };

  const generatePackageFooter = () => {
    return (
      !isPackagesFetching && (
        <PackageFooter>
          <Box sx={{ display: "inline-flex", marginRight: "110px" }}>
            <DisplayCountFooter>
              {`Displaying ${
                packageFilter ? filterPackages.length : displayProps.count
              } of ${packages?.length ?? "unknown"}`}
            </DisplayCountFooter>
            <Link
              underline="none"
              onClick={handleToggleItemCount}
              component={"button"}
              sx={{ color: "secondary.dark" }}>
              <DisplayToggleFooterLink>
                {`Show ${
                  displayProps.displayAll ||
                  displayProps.count === sortedPackages?.length
                    ? "Less"
                    : "All"
                }`}
              </DisplayToggleFooterLink>
            </Link>

            <FontAwesomeIcon
              style={{ alignSelf: "center" }}
              color={BLUE[30]}
              cursor={"pointer"}
              onClick={handleToggleItemCount}
              icon={displayProps.displayAll ? faCaretUp : faCaretDown}
              size={"sm"}
            />
          </Box>
        </PackageFooter>
      )
    );
  };

  const [comparePackages, setComparePackages] = useState<string[]>([]);
  const [combinedPackage, setCombinedPackage] = useState<Package>({
    depths: [],
    services: [],
    description: "",
    industry: "Staffing",
    name: "Combined",
    packageType: "popular",
    price: 0,
    order: 0,
    turnaround: "",
  } as Package);

  const [infoBoxHeight, setInfoBoxHeight] = useState<number>(0);
  const [clearCheckboxes, setClearCheckboxes] = useState<boolean>(false);
  const [openComparePackageDialog, setOpenComparePackageDialog] =
    useState<boolean>(false);

  const handleClearPackages = () => {
    setComparePackages([]);
    setClearCheckboxes(true);
  };

  const handleOpenComparePackageDialog = () => {
    setOpenComparePackageDialog(true);
  };

  const handleCloseComparePackageDialog = () => {
    setOpenComparePackageDialog(false);
  };

  const getCompareDepths = () => {
    const tempDepths: PackageDepthOfCoverage[] = [];
    packages
      .filter((pkg) => comparePackages.includes(pkg.name))
      .map((packg) => {
        packg.depths.map((depth) => {
          if (!tempDepths.find((dpth) => dpth.name === depth.name)) {
            tempDepths.push(depth);
          }
        });
      });

    return tempDepths;
  };

  const getCompareServices = () => {
    const tempServices: PackageService[] = [];
    packages
      .filter((pkg) => comparePackages.includes(pkg.name))
      .map((packg) => {
        packg.services.map((service) => {
          if (!tempServices.find((svc) => svc.name === service.name)) {
            tempServices.push(service);
          }
        });
      });

    return tempServices;
  };

  const calculateInfoBoxHeight = (
    depths: PackageDepthOfCoverage[],
    services: PackageService[],
  ) => {
    const depthsAndServices = [...depths, ...services];
    const descriptionLineLength = 42;
    const descriptionLineHeight = 21;
    const infoLabelHeight = 28;
    const headingsHeight = 90;
    const disclaimerLineLength = 53;
    const depthsAndServicesHeight = infoLabelHeight * depthsAndServices.length;

    const getLargestDescriptionHeight = (
      largest: number,
      item: PackageDepthOfCoverage | PackageService,
    ) => {
      const current =
        Math.ceil(item.description.length / descriptionLineLength) *
        descriptionLineHeight;

      return current > largest ? current : largest;
    };

    const getDisclaimerHeight = (totalHeight: number, item: PackageService) => {
      if (item.disclaimer) {
        return (totalHeight +=
          Math.ceil(item.disclaimer.length / disclaimerLineLength) *
          infoLabelHeight);
      }

      return totalHeight;
    };

    const largestDescriptionHeight = depthsAndServices.reduce(
      getLargestDescriptionHeight,
      0,
    );

    const disclaimerHeight = services.reduce(getDisclaimerHeight, 0);

    return (
      depthsAndServicesHeight +
      headingsHeight +
      largestDescriptionHeight +
      disclaimerHeight
    );
  };

  useEffect(() => {
    if (comparePackages.length) {
      const compareDepths = getCompareDepths();
      const compareServices = getCompareServices();
      setCombinedPackage({
        ...combinedPackage,
        depths: compareDepths,
        services: compareServices,
      });

      setInfoBoxHeight(calculateInfoBoxHeight(compareDepths, compareServices));
    }
  }, [comparePackages]);

  const ComparePackageGrid = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: `repeat(${comparePackages?.length + 1}, 1fr)`,
    justifyContent: "center",
    paddingTop: "50px",
    gap: "0px",
  }));

  return (
    <>
      <ComparePackageDialog
        open={openComparePackageDialog}
        handleCloseComparePackageDialog={handleCloseComparePackageDialog}>
        <ComparePackageGrid
          sx={{
            width: `${300 * (comparePackages?.length + 1)}px`,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          id={"select-package"}
          aria-label={"select-package-page"}>
          <ComparePackageInfoCard
            combinedPackage={combinedPackage}
            infoBoxHeight={infoBoxHeight}
            compareRefs={compareRefs}
            setCompareRefs={setCompareRefs}
          />
          {(packageFilter ? filterPackages : packages)
            .slice(0, packageFilter ? undefined : displayProps.count)
            .filter((currentPackage) =>
              comparePackages.includes(currentPackage.name),
            )
            .map((currentPackage, index) => (
              <ComparePackageCard
                key={`${currentPackage.name}_${index}`}
                inputPackage={currentPackage}
                combinedPackage={combinedPackage}
                infoBoxHeight={infoBoxHeight}
                compareRefs={compareRefs}
              />
            ))}
        </ComparePackageGrid>
      </ComparePackageDialog>
      {generatePackageHeader()}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "1440px", marginLeft: "auto", marginRight: "auto" }}>
          <Container>
            <PackageGrid
              id={"select-package"}
              aria-label={"select-package-page"}>
              {(packageFilter ? filterPackages : sortedPackages)
                .slice(0, packageFilter ? undefined : displayProps.count)
                .map((currentPackage, index) =>
                  !isLoading ? (
                    <PackageCard
                      key={`${currentPackage.name}_${index}`}
                      inputPackage={currentPackage}
                      clearCheckboxes={clearCheckboxes}
                      setClearCheckboxes={setClearCheckboxes}
                      comparePackages={comparePackages}
                      setComparePackages={setComparePackages}
                    />
                  ) : (
                    <Skeleton
                      key={`loading_package_${index}`}
                      variant={"rectangular"}
                      width={332}
                      height={741}
                    />
                  ),
                )}
            </PackageGrid>
          </Container>
        </Box>
      </Box>
      {generatePackageFooter()}

      <Backdrop
        aria-label="compare-backdrop"
        sx={{
          position: "sticky",
          background: BLACK[55],
          color: "white",
          bottom: "0px",
          height: "185px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          paddingLeft: "150px",
          paddingRight: "150px",
        }}
        open={comparePackages?.length > 1}>
        {!openComparePackageDialog && comparePackages?.length > 1 && (
          <Box
            sx={{
              width: "1440px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box>
              <Typography
                sx={{
                  marginLeft: "120px",
                  marginTop: "30px",
                }}>{`${comparePackages?.length} Selected`}</Typography>
            </Box>
            <Box
              sx={{
                marginRight: "120px",
              }}>
              <ClearAllButton
                aria-label="clear-all-button"
                name="clear-all-button"
                onClick={handleClearPackages}>
                Clear all
              </ClearAllButton>
              <CompareButton
                aria-label="compare-packages-button"
                onClick={handleOpenComparePackageDialog}>
                Compare
              </CompareButton>
            </Box>
          </Box>
        )}
      </Backdrop>
    </>
  );
};

const ClearAllButton = styled(Button)(() => ({
  height: "41px",
  color: "white",
  fontWeight: 700,
  fontSize: "20px",
  letter: ".15px",
  lineHeight: "30px",
  marginTop: "18px",
  marginRight: "31px",
  textTransform: "none",
}));

const CompareButton = styled(Button)(({ theme }) => ({
  width: "149px",
  height: "41px",
  color: "white",
  backgroundColor: theme?.palette?.primary.dark,
  fontWeight: 500,
  lineHeight: "18.75px",
  letter: "1px",
  marginTop: "18px",
}));

export default SelectPackage;
