import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BLACK } from "styles/colors";

interface ComparePackageDialogProps {
  open: boolean;
  children: React.ReactNode;
  handleCloseComparePackageDialog: () => void;
}

const ComparePackageDialog = ({
  open,
  children,
  handleCloseComparePackageDialog,
}: ComparePackageDialogProps): JSX.Element => {
  return (
    <Dialog
      maxWidth="xl"
      open={open}
      aria-labelledby="compare-package-dialog-title"
      aria-describedby="compare-package-dialog-description">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <StyledTitle>Compare Packages</StyledTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseComparePackageDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: BLACK[54],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: "0px",
          paddingLeft: "40px",
          paddingRight: "40px",
          minWidth: "1300px",
        }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

const StyledTitle = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "28px",
  lineHeight: "38px",
  letterSpacing: "0.1px",
  color: "#000000",
  marginLeft: "30px",
  marginTop: "30px",
});

export default ComparePackageDialog;
