import { Package } from "models/packages/Package";
import { PackageCardListItemProps } from "components/list/PackageCardListItem";
import { PackageService } from "models/packages/PackageService";
import PackageCardList from "components/list/PackageCardList";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormLabel,
  keyframes,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CardHeader from "../CardHeader";
import { PackageCardDisplayMode } from "./PackageCard";
import { useLocation, useNavigate } from "react-router-dom";
import CardDetails from "../CardDetails";
import { PackageDepthOfCoverage } from "models/packages/PackageDepthOfCoverage";
import LaunchIcon from "@mui/icons-material/Launch";
import { BLUE } from "../../../styles/colors";
import {
  useGetDepthsQuery,
  useGetServicesQuery,
} from "store/slices/marketplaceApi";
import {
  customizeCustomPackagePath,
  customizePopularPackagePath,
  selectPackagePath,
} from "App";

export interface PackageCardContentsProps {
  sx?: Record<string, string>;
  inputPackage: Package;
  handleOpenPackageDialog: () => void;
  displayMode: PackageCardDisplayMode;
  comparePackages?: string[];
  setComparePackages?: Dispatch<SetStateAction<string[]>>;
  clearCheckboxes?: boolean;
  setClearCheckboxes?: Dispatch<SetStateAction<boolean>>;
}

const PackageCard = ({
  sx = {},
  inputPackage,
  handleOpenPackageDialog,
  displayMode,
  comparePackages,
  setComparePackages,
  clearCheckboxes,
  setClearCheckboxes,
}: PackageCardContentsProps): JSX.Element => {
  const [openItemId, setOpenItemId] = useState("");
  const handleItemToggle = (itemId: string) =>
    setOpenItemId(openItemId === itemId ? "" : itemId);

  const [hiddenItemCount, setHiddenItemCount] = useState(0);
  const [contentsLoading, setContentsLoading] = useState(true);
  const updateHiddenItemCount = (key: string, count: number) => {
    setHiddenItemCount(count);
    setContentsLoading(false);
  };

  const [listContainerHeight, setListContainerHeight] = useState(0);
  const [depthContainerOffset, setDepthContainerOffset] = useState(0);
  const [serviceContainerOffset, setServiceContainerOffset] = useState(0);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const depthContainerRef = useRef<HTMLDivElement>(null);
  const serviceContainerRef = useRef<HTMLDivElement>(null);

  const { data: depths } = useGetDepthsQuery();
  const { data: services } = useGetServicesQuery();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const depthOfCoverageBoxHeight = 160;
  const rowHeight = 30;

  const depthCategories = [
    ...new Set(depths?.map((depth) => depth.categoryId)),
  ];
  const serviceIds = services?.map((service) => service.id);

  const mapDepths = (currentPackage: Package): PackageCardListItemProps[] => {
    return Object.values(currentPackage.depths)
      .filter((depth: PackageDepthOfCoverage) => !!depth)
      .sort((a, b) => {
        return (
          depthCategories?.indexOf(a.categoryId) -
          depthCategories?.indexOf(b.categoryId)
        );
      })
      .map((depth: PackageDepthOfCoverage) => {
        return {
          name: depth.name,
          description: depth.description,
        } as PackageCardListItemProps;
      });
  };

  const mapServices = (currentPackage: Package): PackageCardListItemProps[] => {
    return Object.values(currentPackage.services)
      .filter((service: PackageService) => !!service)
      .sort((a, b) => {
        return serviceIds
          ? serviceIds?.indexOf(a.id) - serviceIds?.indexOf(b.id)
          : 0;
      })
      .map((service: PackageService) => {
        return {
          name: service.name,
          description: service.description,
          disclaimer: service?.disclaimer,
        } as PackageCardListItemProps;
      });
  };

  useEffect(() => {
    if (clearCheckboxes && setClearCheckboxes) {
      setIsChecked(false);
      setClearCheckboxes(false);
    }
  }, [clearCheckboxes]);

  useEffect(() => {
    setDepthContainerOffset(
      (depthContainerRef.current?.offsetTop || 0) -
        (listContainerRef.current?.offsetTop || 0),
    );
    setServiceContainerOffset(
      (serviceContainerRef.current?.offsetTop || 0) -
        (listContainerRef.current?.offsetTop || 0),
    );
    setListContainerHeight(listContainerRef?.current?.offsetHeight || 0);
  }, []);

  const navigate = useNavigate();

  const generateLearnMoreLink = () => {
    return (
      displayMode === PackageCardDisplayMode.COLLAPSED && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Tooltip title={"Expand package"} arrow={true}>
            <Box
              onClick={handleOpenPackageDialog}
              sx={{
                display: "flex",
                cursor: "pointer",
              }}>
              {hiddenItemCount === 0 ? (
                <ViewDetailsText>View Details</ViewDetailsText>
              ) : (
                <PlusMoreText>+ {hiddenItemCount} more services</PlusMoreText>
              )}
              <LaunchIcon
                sx={{ marginLeft: "0.25rem", color: BLUE[30] }}
                fontSize="small"
                color="secondary"
              />
            </Box>
          </Tooltip>
        </Box>
      )
    );
  };

  const selectAndCustomizeButton = () => {
    const location = useLocation();
    return (
      <Button
        onClick={() => {
          navigate(
            location?.pathname === selectPackagePath
              ? customizePopularPackagePath
              : customizeCustomPackagePath,
            {
              state: {
                selectedPackage: inputPackage,
              },
            },
          );
        }}
        variant={"contained"}
        color={"secondary"}
        fullWidth
        sx={{
          height: "40px",
          width: "352px",
          padding: "0px 58px",
        }}>
        select and customize
      </Button>
    );
  };

  return (
    <CardContentsBox
      sx={{
        ...sx,
        ...(!contentsLoading && { animation: `${fadeIn} 500ms` }),
        ...(contentsLoading && { opacity: 0 }),
      }}>
      {displayMode === PackageCardDisplayMode.COLLAPSED && (
        <Box sx={{ textAlign: "end" }}>
          <FormLabel
            sx={{
              fontSize: "12px",
              lineHeight: "18px",
              letterSpacing: "0.5px",
              color: "tertiary.main",
            }}>
            Compare
          </FormLabel>
          <Checkbox
            sx={{ padding: "4px" }}
            aria-label="compare-checkbox"
            size="small"
            onClick={(event) => {
              const checked = (event?.target as HTMLInputElement)?.checked;
              if (checked) {
                setComparePackages &&
                  setComparePackages((prevComparePackages) => [
                    ...prevComparePackages,
                    inputPackage?.name as string,
                  ]);
                setIsChecked(true);
              } else {
                setComparePackages &&
                  setComparePackages((prevComparePackages) => [
                    ...prevComparePackages.filter(
                      (packageName) => packageName !== inputPackage?.name,
                    ),
                  ]);
                setIsChecked(false);
              }
            }}
            checked={isChecked}
            disabled={
              comparePackages && !isChecked && comparePackages.length > 2
            }
          />
        </Box>
      )}

      <CardHeader
        name={inputPackage.name}
        description={inputPackage.description}
        displayMode={displayMode}
        handleOpenPackageDialog={handleOpenPackageDialog}
      />
      <Divider sx={{ width: "368px" }} />
      <CardDetails inputPackage={inputPackage} />
      <Box sx={{ padding: "8px 8px 28px" }}>{selectAndCustomizeButton()}</Box>
      <DepthAndServiceBox
        ref={listContainerRef}
        sx={{
          ...(displayMode == PackageCardDisplayMode.COLLAPSED && {
            height: "390px",
            display: "flex",
            flexDirection: "column",
          }),
        }}>
        <Box ref={serviceContainerRef}>
          <PackageCardList
            openItemId={openItemId}
            handleItemToggle={handleItemToggle}
            displayMode={displayMode}
            title={"Services"}
            items={mapServices(inputPackage)}
            width={"352px"}
            parentOffset={serviceContainerOffset}
            parentHeight={
              mapDepths(inputPackage).length
                ? listContainerHeight - depthOfCoverageBoxHeight - rowHeight
                : listContainerHeight
            }
            updateHiddenItemCountCallback={updateHiddenItemCount}
          />
          {generateLearnMoreLink()}
        </Box>
        <Box
          sx={{
            ...(mapDepths(inputPackage).length && {
              height: depthOfCoverageBoxHeight,
              marginTop: "auto",
            }),
          }}>
          <PackageCardList
            openItemId={openItemId}
            handleItemToggle={handleItemToggle}
            displayMode={displayMode}
            title={"Depth of Coverage"}
            items={mapDepths(inputPackage)}
            width={"352px"}
            parentOffset={depthContainerOffset}
            parentHeight={listContainerHeight}
            updateHiddenItemCountCallback={updateHiddenItemCount}
          />
        </Box>
      </DepthAndServiceBox>
    </CardContentsBox>
  );
};

const PlusMoreText = styled(Typography)`
  padding-left: 8px;
  font-weight: 400;
  color: #007ec4;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.5px;
  gap: 4px;
`;

const ViewDetailsText = styled(Typography)`
  padding-left: 8px;
  font-weight: 500;
  color: #007ec4;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  gap: 4px;
`;

const DepthAndServiceBox = styled(Box)`
  overflow: hidden;
`;

const CardContentsBox = styled(Box)``;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1; }
`;

export default PackageCard;
