import { Chip } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

interface FilterChipsProps<T> {
  value: T[];
  onDelete: (value: T) => void;
  parentRef: React.MutableRefObject<null>;
  formatChip: (value: T) => string;
}

function FilterChips<T>({
  value,
  onDelete,
  formatChip,
  parentRef,
}: FilterChipsProps<T>): JSX.Element {
  const selectInputPaddingWidth = 75;
  let totalChipsWidth = selectInputPaddingWidth;

  const [chips, setChips] = useState<ReactNode[]>([]);
  const [hiddenChips, setHiddenChips] = useState<ReactNode[]>([]);

  useEffect(() => {
    setHiddenChips([]);

    setChips(
      value.map((v) => {
        const label = formatChip(v);

        // eslint-disable-next-line prefer-const
        let chip: ReactNode;

        const ref = (node: HTMLDivElement) => {
          if (!node) return;

          const { width } = node.getBoundingClientRect();
          if (parentRef.current) {
            const inputWidth = parentRef.current["offsetWidth"];
            if (totalChipsWidth + width >= inputWidth) {
              setHiddenChips((prev) => {
                return prev.concat(chip);
              });
            } else {
              totalChipsWidth += width;
            }
          }
        };

        chip = (
          <Chip
            sx={{
              marginRight: "4px",
            }}
            // note: implies that all chips have unique labels
            key={label}
            ref={ref}
            label={label}
            size="small"
            variant="outlined"
            color={"secondary"}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onDelete={() => onDelete(v)}
          />
        );

        return chip;
      }),
    );
  }, [value, setHiddenChips, setChips]);

  const visibleChips = chips.filter(
    (chip) => !hiddenChips.some((hidden) => hidden === chip),
  );

  return (
    <>
      {visibleChips}
      {hiddenChips.length > 0 ? (
        <Chip
          key="hidden-chips-count"
          label={`+${hiddenChips.length}`}
          sx={{ marginRight: "4px" }}
          size="small"
          variant="outlined"
          color={"secondary"}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        />
      ) : null}
    </>
  );
}

export default FilterChips;
