import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { QuestionProps, YesNoStep } from "../Step";

export default function ValuableAssetAccessStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  return (
    <YesNoStep<GuidedPackageResponses>
      stepperProps={stepperProps}
      name="includesValuableAssetAccess"
      title="Does the role include access to valuable company assets?"
      subtitle="This helps us know if you need employment credit and civil search in
          your package."
    />
  );
}
