export const formatCategoryOptionName = (
  name: string,
  categoryCode: string,
): string => {
  const criminalPrefix = "Criminal - ";
  const employmentPrefix = "Employment - ";

  if (categoryCode === "CRIMINAL_LAWSUITS" && name.includes(criminalPrefix)) {
    return name.replace(criminalPrefix, "");
  }

  if (categoryCode === "EMPLOYMENT" && name.includes(employmentPrefix)) {
    return name.replace(employmentPrefix, "");
  }

  return name;
};
