import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { Step, QuestionProps, YesNoQuestion, YesNoRadio } from "../Step";

export default function DrivingRequiredStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  const { watch, resetField } = useFormContext<GuidedPackageResponses>();

  const isDrivingRequired = watch("isDrivingRequired");
  const isDotRegulated = watch("isDotRegulated");
  const isFMCSARegulated = watch("isFMCSARegulated");

  useEffect(() => {
    if (isDrivingRequired === false) {
      resetField("isDotRegulated");
    }

    if (isDotRegulated === false) {
      resetField("isFMCSARegulated");
    }
  }, [isDrivingRequired, isDotRegulated]);

  const isForwardDisabled =
    isDrivingRequired === undefined ||
    (isDrivingRequired === true && isDotRegulated === undefined) ||
    (isDrivingRequired === true &&
      isDotRegulated === true &&
      isFMCSARegulated === undefined);

  return (
    <Step
      title="Does the role you’re hiring for include a requirement to drive?"
      subtitle="This helps us know if you need to include driving records in your package."
      stepperProps={{
        ...stepperProps,
        forwardDisabled: isForwardDisabled,
      }}>
      <YesNoQuestion<GuidedPackageResponses>
        name="isDrivingRequired"
        secondaryQuestionIfYes={
          <>
            <YesNoRadio
              label="Is this driving role DOT regulated?"
              name="isDotRegulated"
              id="is-dot-regulated"
            />

            {isDotRegulated ? (
              <YesNoRadio
                label="Is the position FMCSA regulated?"
                name="isFMCSARegulated"
                id="is-fmcsa-regulated"
              />
            ) : null}
          </>
        }
      />
    </Step>
  );
}
