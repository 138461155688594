import {
  PackageBuilder,
  PackageBuilderAlert,
  PackageBuilderAlertType,
} from "..";

/**
 * Automatically adds a National Criminal Records Search if either or
 * both SSN trace services are the only other services configured.
 */
export function applySSNRule(
  packageBuilder: PackageBuilder,
  alerts: PackageBuilderAlert[],
): void {
  if (
    (packageBuilder.assignedServices.size === 1 &&
      (packageBuilder.hasServiceBySymbolicId("SERVICE_LOCATOR_1") ||
        packageBuilder.hasServiceBySymbolicId("SERVICE_LOCATOR_2"))) ||
    (packageBuilder.assignedServices.size === 2 &&
      packageBuilder.hasServiceBySymbolicId("SERVICE_LOCATOR_1") &&
      packageBuilder.hasServiceBySymbolicId("SERVICE_LOCATOR_2"))
  ) {
    packageBuilder.addServiceBySymbolicId("SERVICE_LOCATOR_3");

    alerts.push({ type: PackageBuilderAlertType.SSN });
  }
}
