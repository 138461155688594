import { styled, Typography } from "@mui/material";

export const DisplayToggleFooterLink = styled(Typography)({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "28px",
  letterSpacing: "0.25px",
  cursor: "pointer",
  marginRight: "10px",
});
