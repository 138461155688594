import { styled, Link } from "@mui/material";

export const PackageDisclaimer = styled(Link)(({ theme }) => ({
  height: "18px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0.5px",
  color: theme.palette.secondary.main,
  flex: "none",
  order: 1,
  flexGrow: 0,
}));
