import { HTMLAttributes, ReactNode, useEffect, useRef } from "react";
import { onlyText } from "utils/only-text";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { lastOccurrenceWhere } from "../utils/last-occurrence-where";

export interface TruncateProps extends HTMLAttributes<HTMLSpanElement> {
  ellipsis?: ReactNode;
  sx?: SxProps<Theme>;
  lines?: number;
  breakWord?: boolean;
}

export function Truncate(props: TruncateProps): JSX.Element {
  const {
    lines = 1,
    ellipsis = "...Read more",
    children,
    sx,
    breakWord = false,
  } = props;
  const ellipsisSpanRef = useRef<HTMLSpanElement>(null);
  const rootSpanRef = useRef<HTMLSpanElement>(null);
  const text = onlyText(children);

  useEffect(() => {
    const ellipsisSpan = ellipsisSpanRef.current!;
    const rootSpan = rootSpanRef.current!;
    const textNode = rootSpan.firstChild!;

    ellipsisSpan.style.display = "none";
    textNode.nodeValue = text;

    if (rootSpan.getClientRects().length <= lines) {
      return;
    }

    ellipsisSpan.style.display = "inline-block";

    const breakPoint = lastOccurrenceWhere(
      getAvailableBreakPoints(breakWord, text),
      (index) => {
        textNode.nodeValue = text.slice(0, index);

        return rootSpan.getClientRects().length <= lines;
      },
    );

    textNode.nodeValue = text.slice(0, breakPoint);
  }, [lines, ellipsis, text]);

  return (
    <Box component="span" ref={rootSpanRef} sx={sx}>
      {text}
      <Box
        component="span"
        ref={ellipsisSpanRef}
        sx={{ display: "none", marginLeft: "4px" }}>
        {ellipsis}
      </Box>
    </Box>
  );
}

// return list of appropriate breakpoints based on component prop: breakWord
const getAvailableBreakPoints = (bw: boolean, value: string): number[] => {
  return bw
    ? Array.from(Array(value.length)).map((_, index) => index)
    : value
        .split("")
        .reduce(
          (acc, current, index) => (current === " " ? [...acc, index] : acc),
          [] as number[],
        );
};
