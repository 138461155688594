import { styled, Typography, TypographyTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { BLACK } from "styles/colors";

export const PageTitle = styled(Typography)({
  height: "34px",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "34px",
  letterSpacing: "0.1px",
  flex: "none",
  order: 0,
  flexGrow: 0,
  color: BLACK[87],
}) as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;
