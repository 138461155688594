import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import Page from "components/page/Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { useEffect } from "react";
import { BLACK, SUCCESS_CHECK, SUCCESS_BLUE } from "styles/colors";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const PAGE_TITLE = `We’ve received your information!`;

const Success = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page label={"success-page"}>
      <OnboardingContainer>
        <MainContent>
          <Grid sx={{ flexDirection: "column" }} container alignItems="center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              height="38px"
              width="38px"
              fontSize="45px"
              color={SUCCESS_CHECK}
            />
            <PageTitle component={"h1"}>{PAGE_TITLE}</PageTitle>
          </Grid>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "35px",
              marginTop: "30px",
              letterSpacing: "0.25px",
            }}>
            {`We'll contact you within 1 business day to complete the onboarding
            process. In the meantime, `}
            <span style={{ fontWeight: "bold" }}>{"check your inbox "}</span>
            {`for the following:`}
          </Typography>
          <Box sx={{ marginTop: "9px", marginBottom: "71px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "middle",
                marginBottom: "11px",
              }}>
              <ArrowRightAltRoundedIcon
                htmlColor={SUCCESS_BLUE}
                sx={{ alignSelf: "center", marginRight: "13px" }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "37px",
                  letterSpacing: "0.25px",
                }}>
                {"A confirmation email with your order details and "}
                <span style={{ fontWeight: "bold" }}>next steps</span>
                {"."}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "middle",
                marginBottom: "11px",
              }}>
              <ArrowRightAltRoundedIcon
                htmlColor={SUCCESS_BLUE}
                sx={{ alignSelf: "center", marginRight: "13px" }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.25px",
                }}>
                {"An email from "}
                <span style={{ fontWeight: "bold" }}>DocuSign </span>
                {"so you can sign the necessary agreements."}
              </Typography>
            </Box>
          </Box>
          <Grid sx={{ flexDirection: "column" }} container alignItems="center">
            <Button
              sx={{
                color: SUCCESS_BLUE,
                border: `1px solid ${SUCCESS_BLUE}`,
                borderRadius: "8px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "100%",
                letterSpacing: "0.34px",
                textTransform: "none",
                ":hover": {
                  border: `1px solid ${SUCCESS_BLUE}`,
                },
              }}
              variant="outlined"
              href="https://blog.orangetreescreening.com/?utm_source=marketplace&utm_medium=referral&utm_campaign=confirmation_page"
              target={"_blank"}
              rel={"noopener"}>
              {"Check out the latest news on background screening"}
            </Button>
          </Grid>
        </MainContent>
      </OnboardingContainer>
    </Page>
  );
};

const PageTitle = styled(Typography)({
  height: "32px",
  fontWeight: "500",
  fontSize: "22px",
  lineHeight: "32px",
  letterSpacing: "0.1px",
  marginTop: "26px",
  marginLeft: "7px",
  color: BLACK[87],
}) as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;

const OnboardingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "60px",
});

const MainContent = styled(Container)({
  width: "610px",
});

export default Success;
