import { styled, Typography } from "@mui/material";

export const FAQQuestion = styled(Typography)(({ theme }) => ({
  height: "24px",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: theme?.palette?.neutral_dark?.light,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));
