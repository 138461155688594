import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BLACK } from "styles/colors";

interface PackageDialogProps {
  open: boolean;
  children: React.ReactNode;
  handleClosePackageDialog: () => void;
}

const PackageDialog = ({
  open,
  children,
  handleClosePackageDialog,
}: PackageDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      aria-labelledby="package-dialog-title"
      aria-describedby="package-dialog-description">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={handleClosePackageDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: BLACK[54],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export default PackageDialog;
