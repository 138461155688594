import { styled, Typography } from "@mui/material";

export const CustomizePackageCardTitle = styled(Typography)(({ theme }) => ({
  width: "inherit",
  height: "32px",
  fontWeight: 500,
  fontSize: "22px",
  margin: "16px 0px 16px 8px",
  lineHeight: "32px",
  letterSpacing: "0.1px",
  color: theme?.palette?.text?.primary,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));
