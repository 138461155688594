import { Accordion, styled } from "@mui/material";

export const CustomizePackageAccordion = styled(Accordion)({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2px 32px",
  gap: "8px",
  width: "864px",
  height: "auto",
  flex: "none",
  order: 1,
  alignSelf: "stretch",
  flexGrow: 0,
  "&:last-of-type": {
    borderRadius: "0px",
  },
  "&.Mui-expanded": {
    "&::before": {
      opacity: 1,
    },
  },
});
