import { Box, styled, Typography, TypographyTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useNavigate } from "react-router-dom";
import { BLACK } from "styles/colors";
import StarIcon from "@mui/icons-material/Star";
import ConstructionIcon from "@mui/icons-material/Construction";
import SignpostIcon from "@mui/icons-material/Signpost";
import {
  customizeCustomPackagePath,
  guidedPackageBuilderPath,
  selectPackagePath,
} from "App";

const ChooseYourPath = (): JSX.Element => {
  return (
    <>
      <GetServicesBox>
        <GetServicesHeading component="h1">
          Get the Services You Need
        </GetServicesHeading>
        <GetServicesDescription>
          Choose how you want to find the right services for your candidates.
        </GetServicesDescription>
      </GetServicesBox>
    </>
  );
};

export const ChooseYourPathBar = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <ChooseYourPathBox>
      <ChooseYourPathHeading>Choose your path</ChooseYourPathHeading>
      <ChooseYourPathServicesBox>
        <ServiceBox onClick={() => navigate(selectPackagePath)}>
          <ServiceIconBox>
            <StarIcon fontSize="large" />
          </ServiceIconBox>
          <ServiceBoxHeading>Explore Popular Packages</ServiceBoxHeading>
          <ServiceDescription textAlign={"center"}>
            Based on what others in the industry are doing
          </ServiceDescription>
        </ServiceBox>
        <ServiceBox onClick={() => navigate(customizeCustomPackagePath)}>
          <ServiceIconBox>
            <ConstructionIcon fontSize="large" />
          </ServiceIconBox>
          <ServiceBoxHeading>Build a Custom Package</ServiceBoxHeading>
          <ServiceDescription textAlign={"center"}>
            Pick the exact services you need to screen your candidates
          </ServiceDescription>
        </ServiceBox>
        <ServiceBox onClick={() => navigate(guidedPackageBuilderPath)}>
          <ServiceIconBox>
            <SignpostIcon fontSize="large" />
          </ServiceIconBox>
          <ServiceBoxHeading>Guided Package Builder</ServiceBoxHeading>
          <ServiceDescription textAlign={"center"}>
            I need help building the package of services that works for me
          </ServiceDescription>
        </ServiceBox>
      </ChooseYourPathServicesBox>
    </ChooseYourPathBox>
  );
};

const GetServicesBox = styled(Box)({
  marginLeft: "100px",
  marginTop: "48px",
  marginBottom: "48px",
  width: "100%",
});

const GetServicesDescription = styled(Typography)({
  fontStyle: "regular",
  fontSize: "16px",
  lineHeight: "24px",
  align: "left",
  verticalAlign: "top",
  letterSpacing: "0.25px",
  color: BLACK[60],
  marginTop: "8px",
});

const GetServicesHeading = styled(Typography)({
  fontStyle: "regular",
  fontSize: "50px",
  lineHeight: "68px",
  align: "left",
  verticalAlign: "top",
}) as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;

const ChooseYourPathBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "279px",
  background: "#FFEDE5",
  width: "100%",
  paddingTop: "48px",
  paddingBottom: "48px",
});

const ChooseYourPathHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "25px",
  letterSpacing: ".15px",
});

const ChooseYourPathServicesBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "222px",
  width: "966px",
  marginTop: "32px",
});

const ServiceBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "222px",
  width: "242px",
  paddingLeft: "32px",
  paddingRight: "32px",
  background: "#FFFFFF",
  border: "1px solid #DFE0E2",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#E7F6FF",
  },
  cursor: "pointer",
});

const ServiceIconBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "64px",
  width: "64px",
  marginTop: "32px",
  background: "#CCEAFF",
  borderRadius: "999px",
  color: "#E8720E",
});

const ServiceBoxHeading = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "22px",
  marginTop: "24px",
  marginBottom: "8px",
});

const ServiceDescription = styled(Typography)({
  color: BLACK[60],
});

export default ChooseYourPath;
