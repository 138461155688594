import { styled, Typography } from "@mui/material";
import { FAQ_TITLE } from "styles/colors";

export const FAQTitle = styled(Typography)(({ theme }) => ({
  width: "219px",
  height: "24px",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: FAQ_TITLE,
  flex: "none",
  order: 0,
  flexGrow: 0,
  marginBottom: "22px",
}));
