import { Box, styled } from "@mui/material";
import { SHARE_PACKAGE_BACKGROUND } from "styles/colors";

export const SharePackageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  width: "inherit",
  height: "33px",
  left: "1px",
  top: "951px",
  color: theme?.palette?.primary?.dark,
  background: SHARE_PACKAGE_BACKGROUND,
  borderRadius: "0px 0px 4px 4px",
  textAlign: "center",
}));
