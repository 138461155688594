import { Card, styled } from "@mui/material";

export const StyledCustomizePackageCard = styled(Card)({
  borderRadius: 0,
  boxSizing: "border-box",
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  width: "864px",
  height: "auto",
  border: "1px solid #DBE0E2",
  flex: "none",
  order: 0,
  flexGrow: 0,
});
