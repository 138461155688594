import { Box, Button, Typography } from "@mui/material";
import { customizeCustomPackagePath, onboardingCustomPath } from "App";
import { Package } from "models/packages/Package";
import { useNavigate } from "react-router-dom";
import GuidedPackageBuilderCard from "./GuidedPackageBuilderCard";

interface GuidedPackageBuilderReviewProps {
  newPackage: Package;
}

export default function GuidedPackageBuilderReview({
  newPackage,
}: GuidedPackageBuilderReviewProps): JSX.Element | null {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: 567,
        mx: "auto",
        my: 4,
      }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="bold">
          We&apos;ve created a package for you
        </Typography>
        <Typography variant="h6" color="#545961" fontWeight="normal">
          Based on your selections, we created a personalized package
        </Typography>
      </Box>

      <GuidedPackageBuilderCard newPackage={newPackage} />

      <Box sx={{ display: "flex", justifyContent: "end", mt: 4 }}>
        <Button
          disableElevation
          color="secondary"
          variant="outlined"
          onClick={() =>
            navigate(customizeCustomPackagePath, {
              state: {
                selectedPackage: newPackage,
              },
            })
          }
          sx={{
            textTransform: "none",
            mr: 2,
            // FIXME FIXME FIXME theme should be updated so that outlined variant does not have fixed height
            height: "56px !important",
            px: 3,
            borderRadius: 2,
          }}>
          Customize Package
        </Button>

        <Button
          disableElevation
          variant="contained"
          color="secondary"
          onClick={() => navigate(onboardingCustomPath)}
          sx={{ textTransform: "none", height: 56, px: 3, borderRadius: 2 }}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}
