import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  customizePopularPackagePath,
  reviewCustomPackagesPath,
  reviewPopularPackagesPath,
} from "App";
import { useAppDispatch } from "hooks";
import { Package } from "models/packages/Package";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  savedPackageAdded,
  savedPackageChanged,
} from "store/actions/save-package-actions";
import { useGetDepthsQuery } from "store/slices/marketplaceApi";
import {
  ALERT_BACKGROUND,
  ALERT_CLOSE_ICON,
  ALERT_INFO_ICON,
  DISABLED,
} from "styles/colors";
import { LocationState } from "types/LocationState.d";
import { v4 as uuidv4 } from "uuid";
import CardDetails from "../CardDetails";
import CardHeader from "../CardHeader";
import { PackageCardDisplayMode } from "../package/PackageCard";
import { AlertDescription } from "../Typography/AlertDescription";

export interface SummaryCardContentsProps {
  customizedPackage: Package;
  alertMessages: string[];
  setAlertMessages: Dispatch<SetStateAction<string[]>>;
}

export interface CoverageItem {
  name: string;
  price: string;
  disclaimer?: string;
}

const SummaryCardContents = ({
  customizedPackage,
  alertMessages,
  setAlertMessages,
}: SummaryCardContentsProps): JSX.Element => {
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;
  const { selectedPackage } = locationState;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [packageName, setPackageName] = useState<string>(
    customizedPackage?.name,
  );

  const emptyPackagePrice = !customizedPackage.price;

  const saveAndReviewButton = () => {
    return (
      <Button
        disabled={emptyPackagePrice}
        onClick={() => {
          if (customizedPackage.packageId) {
            dispatch(
              savedPackageChanged({
                id: customizedPackage.packageId,
                changes: {
                  ...customizedPackage,
                  name: packageName || "My Package",
                },
              }),
            );
          } else {
            dispatch(
              savedPackageAdded({
                ...customizedPackage,
                name: packageName || "My Package",
                packageId: uuidv4(),
              }),
            );
          }

          navigate(
            location?.pathname === customizePopularPackagePath
              ? reviewPopularPackagesPath
              : reviewCustomPackagesPath,
            {
              state: {
                selectedPackage: customizedPackage,
                oldSelectedPackage: selectedPackage,
              },
            },
          );
        }}
        variant={"contained"}
        color={"secondary"}
        fullWidth
        startIcon={
          <FontAwesomeIcon
            icon={faBookmark}
            color={emptyPackagePrice ? DISABLED : "white"}
            style={{
              fontSize: "16px",
              padding: "0px 2px",
            }}
          />
        }
        sx={{
          height: "40px",
          width: "409px",
          gap: "8px",
          margin: "8px 0px 24px",
          fontWeight: 700,
        }}>
        save and review
      </Button>
    );
  };

  const { data: depths } = useGetDepthsQuery();

  const depthCategories = [
    ...new Set(depths?.map((depth) => depth.categoryId)),
  ];

  const mapDepths = (currentPackage: Package) => {
    const depths = currentPackage?.depths?.length
      ? [...currentPackage.depths]
      : [];
    return depths
      ?.sort((a, b) => {
        return (
          depthCategories?.indexOf(a.categoryId) -
          depthCategories?.indexOf(b.categoryId)
        );
      })
      .map((currentPackageDepth) => ({
        name: currentPackageDepth?.name,
        price: "",
      }));
  };

  const mapServices = (currentPackage: Package) => {
    const services = currentPackage?.services?.length
      ? [...currentPackage.services]
      : [];
    return services?.map((currentPackageService) => ({
      name: currentPackageService?.name,
      price: "",
      disclaimer: `${
        services?.find((service) => service.id === currentPackageService.id)
          ?.disclaimer || ""
      }`,
    }));
  };

  // TODO colin: these can be extracted as functional components
  const coverageList = (title: string, coverageItems: Array<CoverageItem>) => {
    const baseLabel = title.toLowerCase().replaceAll(" ", "-");

    return (
      <Box
        aria-label={`${baseLabel}-summary-list`}
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px 8px 0px 8px",
          gap: "4px",
        }}>
        <ListTitle aria-label={`${baseLabel}-summary-list-title`}>
          {title}
        </ListTitle>
        <Divider sx={{ width: "379.04px" }} />
        {coverageItems?.filter(({ name }) => name !== "None")?.length ? (
          coverageItems
            ?.filter(({ name }) => name !== "None")
            ?.map((item, index) => (
              <Box
                aria-label={`${baseLabel}-summary-list-item-${index}`}
                key={index}>
                {coverageListItem(item.name, item.price, item?.disclaimer)}
              </Box>
            ))
        ) : (
          <ListItem
            color={"tertiary_grey.main"}
            aria-label={`Select from our list of services to begin`}
            sx={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}>
            {"Select from our list of services to begin"}
          </ListItem>
        )}
      </Box>
    );
  };

  const coverageListItem = (
    displayText: string,
    price: string,
    disclaimer?: string,
  ) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
          }}>
          <ListItem
            aria-label={`summary-list-item-name`}
            sx={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}>
            {displayText}
          </ListItem>
          <ListItem
            aria-label={`summary-list-item-price`}
            sx={{
              height: "20px",
              paddingLeft: "8px",
              flex: 0,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}>
            {price}
          </ListItem>
        </Box>
        <ListItemDisclaimer sx={{ color: "primary.dark" }}>
          {disclaimer}
        </ListItemDisclaimer>
        <Divider sx={{ width: "379.04px" }} />
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
        marginBottom: "16px",
      }}>
      <CardHeader
        name={packageName || "My Package"}
        setName={setPackageName}
        editName={true}
        description={customizedPackage?.description}
        displayMode={PackageCardDisplayMode.EXPANDED}
        sx={{
          padding: "0px 0px 4px 0px",
          margin: "0px",
          width: "409px",
        }}
      />
      <Divider sx={{ width: "409px" }} />
      <CardDetails inputPackage={customizedPackage} />
      {saveAndReviewButton()}
      <ListContainer sx={{ backgroundColor: "tertiary_light_grey.light" }}>
        {alertMessages?.length > 0 && (
          <Stack sx={{ width: "100%" }} spacing={1}>
            {alertMessages.map((alertMessage, index) => (
              <Fade
                key={`${alertMessage}_${index}`}
                in={!!alertMessage}
                addEndListener={() =>
                  setTimeout(
                    () =>
                      setAlertMessages((prevAlertMessages) => {
                        const newAlertMessages = [...prevAlertMessages];
                        newAlertMessages.splice(0, 1);
                        return newAlertMessages;
                      }),
                    5000,
                  )
                }>
                <Alert
                  action={
                    <IconButton
                      sx={{ color: ALERT_CLOSE_ICON }}
                      size={"small"}
                      onClick={() =>
                        setAlertMessages((prevAlertMessages) => {
                          const newAlertMessages = [...prevAlertMessages];
                          newAlertMessages.splice(index, 1);
                          return newAlertMessages;
                        })
                      }>
                      <CloseRoundedIcon />
                    </IconButton>
                  }
                  sx={{
                    backgroundColor: ALERT_BACKGROUND,
                  }}
                  severity={"info"}
                  icon={<InfoIcon htmlColor={ALERT_INFO_ICON} />}>
                  <AlertDescription>{alertMessage}</AlertDescription>
                </Alert>
              </Fade>
            ))}
          </Stack>
        )}
        {coverageList("Services", mapServices(customizedPackage))}
        {mapDepths(customizedPackage).length > 0 &&
          coverageList("Depth of Coverage", mapDepths(customizedPackage))}
      </ListContainer>
    </Box>
  );
};

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "395.04px",
  padding: "21px 7.52px 0px 6.44px",
  gap: "12px",
});

const ListTitle = styled(Typography)({
  height: "20px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "0.50px",
  padding: "0px 0px 4px 0px",
  color: "text.primary",
});

const ListItem = styled(Typography)({
  display: "flex",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.50px",
  padding: "4px 0px",
  color: "text.primary",
});

const ListItemDisclaimer = styled(Typography)({
  fontStyle: "italic",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0.5px",
});

export default SummaryCardContents;
