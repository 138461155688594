import { styled, Typography } from "@mui/material";
import { EXPAND_COVERAGE } from "styles/colors";

export const View = styled(Typography)({
  fontSize: "14px",
  cursor: "pointer",
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: EXPAND_COVERAGE,
  flex: "none",
  order: 0,
  flexGrow: 0,
  marginTop: "16px",
});
