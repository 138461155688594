import createPalette from "@mui/material/styles/createPalette";
import {
  BLUE,
  BLUE_GREY,
  GREY,
  LIGHT_GREY,
  NEUTRAL_DARK,
  ORANGE,
  TYPOGRAPHY,
} from "./colors";

const palette = createPalette({
  primary: {
    dark: ORANGE[30],
    main: ORANGE[40],
    light: ORANGE[50],
  },
  secondary: {
    dark: BLUE[30],
    main: BLUE[40],
    light: BLUE[50],
  },
  text: {
    primary: TYPOGRAPHY["header"],
    secondary: TYPOGRAPHY["body"],
  },
  tertiary: {
    dark: BLUE_GREY[40],
    main: BLUE_GREY[50],
    light: BLUE_GREY[60],
  },
  tertiary_grey: {
    dark: GREY[40],
    main: GREY[50],
    light: GREY[60],
  },
  tertiary_light_grey: {
    dark: LIGHT_GREY[40],
    main: LIGHT_GREY[50],
    light: LIGHT_GREY[80],
  },
  neutral_dark: {
    dark: NEUTRAL_DARK[30],
    main: NEUTRAL_DARK[40],
    light: NEUTRAL_DARK[50],
  },
});

export default palette;
