// Strips all html and return only text nodes

import { ReactNode, Children, isValidElement } from "react";
import { hasChildren } from "./has-children";

type Primitive = 'String' | 'Number' | 'Boolean' | 'Undefined' | 'Null'

const isType = (type: Primitive) => (value: unknown) => Object.prototype.toString.call(value) === `[object ${type}]`

const isUndefined = isType('Undefined')
const isBoolean = isType('Boolean')
const isNull = isType('Null')

export const childToString = (child: ReactNode): string => {
  if(isUndefined(child) || isBoolean(child) || isNull(child) || JSON.stringify(child) === '{}') {
    return '';
  }

  return (<number | string>child).toString();
}

export const onlyText = (children: ReactNode): string => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  return <string>Children.toArray(children).reduce((acc: string, child: ReactNode): string => {
    if (isValidElement(child) && hasChildren(child)) {
      return acc.concat(onlyText(child.props.children));
    } else if (isValidElement(child) && !hasChildren(child)) {
      return acc;
    } else {
      return acc.concat(childToString(child));
    }
  }, '')
}
