import { Box, Typography, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ReactNode } from "react";

export interface StepperProps {
  currentStep: number;
  totalSteps: number;
  onBackClick: () => void;
  onForwardClick: () => void;
  backDisabled?: boolean;
  forwardDisabled?: boolean;
  children: ReactNode;
}

export default function Stepper({
  currentStep,
  totalSteps,
  onBackClick,
  onForwardClick,
  backDisabled,
  forwardDisabled,
  children,
}: StepperProps): JSX.Element {
  return (
    <Box sx={{ w: 1 }}>
      <Typography>
        Question {currentStep}/{totalSteps}
      </Typography>

      <Box sx={{ my: 4 }}>{children}</Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          disableElevation
          color="secondary"
          disabled={backDisabled}
          onClick={onBackClick}
          sx={{ textTransform: "none", height: 56, px: 3, borderRadius: 2 }}>
          <ArrowBackIosNewIcon />
          &nbsp; Back
        </Button>

        <Button
          disableElevation
          variant="contained"
          color="secondary"
          disabled={forwardDisabled}
          onClick={onForwardClick}
          sx={{ textTransform: "none", height: 56, px: 3, borderRadius: 2 }}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}
