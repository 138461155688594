import { Store } from "@reduxjs/toolkit";

export function loadPersistedState<T>(
  key: string,
  // optionally filter the state retrieved from storage
  whitelistState: (state: T) => T | Partial<T> = (state) => state,
): T | Partial<T> | undefined {
  try {
    const rawState = localStorage.getItem(key);

    if (rawState === null) {
      throw new Error("No saved state available");
    }

    const parsedState: T | Partial<T> = whitelistState(JSON.parse(rawState));

    // TODO should consider adding some kind of validation or versioning here
    return parsedState;
  } catch (e) {
    return undefined;
  }
}

function savePersistedState<T>(key: string, state: T) {
  const serialized = JSON.stringify(state);

  localStorage.setItem(key, serialized);
}

function stateIsShallowlyEqual<T>(prevState: T, nextState: T) {
  for (const key in nextState) {
    if (prevState[key] !== nextState[key]) {
      return false;
    }
  }

  return true;
}

export function persistState<T>(
  store: Store<T>,
  storageKey: string,
  // optionally filter the state persisted to storage
  whitelistState: (state: T) => T | Partial<T> = (state) => state,
): void {
  let prevState: T | Partial<T> | undefined = undefined;

  store.subscribe(() => {
    const nextState = whitelistState(store.getState());

    if (
      prevState === undefined ||
      !stateIsShallowlyEqual(prevState, nextState)
    ) {
      savePersistedState<Partial<T>>(storageKey, nextState);
    }

    prevState = nextState;
  });
}
