import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const PriceAndTimeDisclaimer = styled(Typography)({
  fontWeight: "400",
  fontSize: "11px",
  lineHeight: "16px",
  letterSpacing: "0.68px",
  color: BLACK[60],
});
