import {
  Box,
  Divider,
  List as MuiList,
  styled,
  Typography,
} from "@mui/material";
import PackageCardListItem, {
  PackageCardListItemProps,
} from "./PackageCardListItem";
import { PackageCardDisplayMode } from "../card/package/PackageCard";
import { useEffect, useRef, useState } from "react";

export type PackageCardListProps = {
  title: string;
  openItemId: string;
  handleItemToggle: (itemId: string) => void;
  displayMode: PackageCardDisplayMode;
  items: Array<PackageCardListItemProps>;
  width?: string;
  parentOffset: number;
  parentHeight: number;
  updateHiddenItemCountCallback: (key: string, count: number) => void;
};

const PackageCardList = ({
  title,
  openItemId,
  handleItemToggle,
  displayMode,
  items,
  width = "200px",
  parentOffset,
  parentHeight,
  updateHiddenItemCountCallback,
}: PackageCardListProps): JSX.Element => {
  const childRefs = useRef<Array<HTMLDivElement | unknown>>([]);

  const [visibleChildren, setVisibleChildren] = useState(
    new Array<boolean>(items.length).fill(true),
  );
  const [initialized, setInitialized] = useState(false);

  const shouldHideItem = (offset: number, itemIndex: number) => {
    return (
      parentHeight > 0 && offset > parentHeight && visibleChildren[itemIndex]
    );
  };

  useEffect(() => {
    const updatedVisibleChildren = new Array<boolean>(items.length).fill(true);

    let dirty = false;
    let numberOfVisibleItems = items.length;

    // If we're in a collapsed mode, we need to calculate the number ot items that overflow and hide them
    if (displayMode === PackageCardDisplayMode.COLLAPSED) {
      childRefs.current
        .filter((ref) => !!ref)
        .map((ref: HTMLDivElement | unknown) => ref as HTMLDivElement)
        .forEach((ref: HTMLDivElement, index) => {
          const myBottomOffset =
            parentOffset + ref?.offsetTop + ref?.offsetHeight;
          if (shouldHideItem(myBottomOffset, index)) {
            updatedVisibleChildren[index] = false;
            numberOfVisibleItems--;
            dirty = true;
          }
        });
    }

    if (dirty || !initialized) {
      setVisibleChildren(updatedVisibleChildren);
      setInitialized(true);
      updateHiddenItemCountCallback(title, items.length - numberOfVisibleItems);
    }
  });

  return (
    <StyledList width={width} id={title}>
      <ListTitle color="text.primary">{title}</ListTitle>
      <Divider sx={{ width: { width } }} />
      {items.map((item, index) => (
        <Box
          ref={(el) => (childRefs.current[index] = el)}
          key={`${title}-${index}`}>
          <PackageCardListItem
            key={`${title}-${index}`}
            itemId={`${title}-${index}`}
            openItemId={openItemId}
            handleItemToggle={handleItemToggle}
            displayMode={displayMode}
            name={item.name}
            description={item.description}
            disclaimer={item?.disclaimer}
            visible={visibleChildren[index]}
          />
        </Box>
      ))}
    </StyledList>
  );
};
const StyledList = styled(MuiList)`
  width: ${(props: { width: string }) => props.width};
  padding: 8px 8px 12px 8px;
`;

const ListTitle = styled(Typography)`
  height: 24px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 0px 0px 4px 0px;
`;

export default PackageCardList;
