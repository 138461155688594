import { styled, Typography } from "@mui/material";

export const OptionPrice = styled(Typography)(({ theme }) => ({
  width: "25px",
  height: "18px",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "0.5px",
  display: "inline-flex",
  color: theme?.palette?.neutral_dark?.light,
  flex: "none",
  order: 2,
  flexGrow: 0,
  float: "right",
  margin: "5px 0px",
}));
