import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Card,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardDetails from "components/card/CardDetails";
import CardHeader from "components/card/CardHeader";
import { Package } from "models/packages/Package";

interface GuidedPackageBuilderCardProps {
  newPackage: Package;
}

const Accordion = styled(MuiAccordion)({
  background: "transparent",
});

const AccordionSummary = styled(MuiAccordionSummary)({
  margin: 0,
  padding: 0,
  minHeight: "auto",
  "&::before, &.Mui-expanded::before": {
    position: "absolute",
    left: 0,
    top: "-1px",
    right: 0,
    height: "1px",
    content: "''",
    opacity: 1,
    backgroundColor: "#DBE0E2",
  },
  "&::first-of-type::before": {
    opacity: 0,
  },
  "& .MuiAccordionSummary-content": {
    margin: "6px 0",
  },
});

export default function GuidedPackageBuilderCard({
  newPackage,
}: GuidedPackageBuilderCardProps): JSX.Element {
  return (
    <Card sx={{ p: 2, boxSizing: "border-box" }}>
      {/* CardHeader requires us to pass an empty description */}
      <CardHeader name={newPackage.name} description="" />

      <Divider sx={{ width: 1 }} />

      <CardDetails inputPackage={newPackage} />

      {/* put remaining contents in a box to match the padding of CardDetails */}
      <Box sx={{ p: 1, background: "#F0F2F3" }}>
        <Typography variant="h6" marginY="2px">
          Services
        </Typography>

        {newPackage.services.map(({ id, name, description, disclaimer }) => (
          <Accordion key={id} disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Box>
                <Typography>{name}</Typography>

                {disclaimer ? (
                  <Typography
                    color="primary.dark"
                    fontStyle="italic"
                    fontWeight="bold"
                    fontSize="12px">
                    {disclaimer}
                  </Typography>
                ) : null}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h6" marginY="2px">
          Depth of Coverage
        </Typography>

        {newPackage.depths.map(({ id, name, description }) => (
          <Accordion key={id} disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Box>
                <Typography>{name}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Card>
  );
}
