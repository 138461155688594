import { styled, Typography } from "@mui/material";

export const FilterLabel = styled(Typography)(({ theme }) => ({
  width: "158px",
  height: "28px",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "28px",
  letterSpacing: "0.25px",
  color: theme?.palette?.tertiary_grey?.dark,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));
