import {
  Box,
  Collapse,
  Divider,
  ListItem as MuiListItem,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import { CompareRef } from "pages/SelectPackage/SelectPackage";

export type ComparePackageInfoCardListItemProps = {
  itemId: string;
  openItemId: string;
  handleItemToggle: (itemId: string) => void;
  name: string;
  description: string;
  disclaimer?: string;
  compareRefs: CompareRef[];
  setCompareRefs: (compareRefs: CompareRef[]) => void;
};

const ComparePackageInfoCardListItem = (
  props: ComparePackageInfoCardListItemProps,
): JSX.Element => {
  const isOpen = () => props.itemId === props.openItemId;

  const generateAccordionCaret = () => {
    return (
      <Box sx={{ color: "neutral_dark.light" }}>
        <FontAwesomeIcon
          icon={isOpen() ? faCaretUp : faCaretDown}
          size={"xs"}
        />
      </Box>
    );
  };

  const generateAccordion = () => {
    return (
      <Collapse in={isOpen()} timeout={"auto"} unmountOnExit>
        <ItemDescription>{props.description}</ItemDescription>
      </Collapse>
    );
  };

  const localCompareRefs = [...props.compareRefs];

  const onRefChange = useCallback(
    (node: any) => {
      if (!node) {
        return;
      }

      const { height } = node.getBoundingClientRect();
      const itemRef = {
        id: props.name,
        height: height,
      };

      if (!localCompareRefs.find((i) => i.id === props.name)) {
        localCompareRefs.push(itemRef);
        props.setCompareRefs(localCompareRefs);
      }
    },
    [isOpen()],
  );

  return (
    <Box>
      <StyledListItem
        ref={onRefChange}
        onClick={() => props.handleItemToggle(props.itemId)}
        id={props.itemId}>
        <StyledListItemText
          primary={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {props.name}
            </Box>
          }
          secondary={
            props?.disclaimer && (
              <Box component="span" sx={{ fontWeight: 500 }}>
                {props?.disclaimer}
              </Box>
            )
          }
          primaryTypographyProps={{ sx: { color: "text.primary" } }}
          secondaryTypographyProps={{ sx: { color: "primary.dark" } }}
        />
        {generateAccordionCaret()}
      </StyledListItem>
      {generateAccordion()}
      <Divider sx={{ width: "352px" }} />
    </Box>
  );
};

const StyledListItem = styled(MuiListItem)`
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px 4px 0px;
  gap: 1;
`;

const StyledListItemText = styled(ListItemText)({
  fontWeight: "400",
  margin: "0px",
  ".MuiListItemText-primary": {
    minHeight: "20px",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  ".MuiListItemText-secondary": {
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.5px",
  },
});

const ItemDescription = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
`;

export default ComparePackageInfoCardListItem;
