import { businessRules } from "pages/CustomizePackage/BusinessRules";
import {
  PackageBuilder,
  PackageBuilderAlert,
  PackageBuilderAlertType,
} from "..";

/**
 * If a service is associated with one or more depth categories
 * and they have not been otherwise configured, assign the appropriate
 * default depth(s).
 */
export function applyDefaultDepthsRule(
  packageBuilder: PackageBuilder,
  alerts: PackageBuilderAlert[],
): void {
  // apply default service depths
  [...packageBuilder.assignedServices].forEach(({ symbolicId }) => {
    // NOTE: these are distinct from 'businessRules' found on
    // the service model
    const serviceBusinessRules = businessRules.filter(
      (r) => r.serviceId === symbolicId,
    );

    serviceBusinessRules.forEach(
      ({ affectedDepthCategoryCode, defaultDepthId }) => {
        const category = packageBuilder.findDepthCategoryBySymbolicId(
          affectedDepthCategoryCode,
        );

        const existingDepth = [...packageBuilder.assignedDepths].find(
          (r) => r.categoryId === category.id,
        );

        if (existingDepth) {
          return;
        }

        packageBuilder.addDepthBySymbolicId(defaultDepthId);

        alerts.push({
          type: PackageBuilderAlertType.DefaultDepth,
          depth: packageBuilder.findDepthBySymbolicId(defaultDepthId),
        });
      },
    );
  });
}
