import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { Step, QuestionProps, YesNoQuestion, YesNoRadio } from "../Step";

export default function MinimumExperienceStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  const { watch, resetField } = useFormContext<GuidedPackageResponses>();

  const isMinimumExperienceRequired = watch("isMinimumExperienceRequired");
  const isSpecificDegreeRequired = watch("isSpecificDegreeRequired");

  useEffect(() => {
    if (isMinimumExperienceRequired === false) {
      resetField("isSpecificDegreeRequired");
    }
  }, [isMinimumExperienceRequired]);

  const isForwardDisabled =
    isMinimumExperienceRequired === undefined ||
    (isMinimumExperienceRequired === true &&
      isSpecificDegreeRequired === undefined);

  return (
    <Step
      title="Does the role you're hiring for require a minimum amount of
      experience?"
      subtitle="This helps us know if you need an employment verification screening
      service in your package."
      stepperProps={{
        ...stepperProps,
        forwardDisabled: isForwardDisabled,
      }}>
      <YesNoQuestion<GuidedPackageResponses>
        name="isMinimumExperienceRequired"
        secondaryQuestionIfYes={
          <YesNoRadio
            label="Does the role you're hiring for require a certain degree?"
            name="isSpecificDegreeRequired"
            // TODO is this necessary?
            id="minimum-degree-required"
          />
        }
      />
    </Step>
  );
}
