import { styled, Typography } from "@mui/material";

export const StyledSubtitle = styled(Typography)`
  height: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
