import { Package } from "models/packages/Package";
import Card from "../Card";
import { Dispatch, SetStateAction, useState } from "react";
import PackageDialog from "components/dialog/PackageDialog";
import PackageCardContents from "./PackageCardContents";
import { Box } from "@mui/material";
import { SharePackage } from "./Typography/SharePackage";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { SharePackageBox } from "./Box/SharePackageBox";
import SharePackageDialog from "components/SharePackageDialog";

export enum PackageCardDisplayMode {
  COLLAPSED,
  EXPANDED,
}

export interface PackageCardProps {
  inputPackage: Package;
  clearCheckboxes?: boolean;
  setClearCheckboxes?: Dispatch<SetStateAction<boolean>>;
  comparePackages?: string[];
  setComparePackages?: Dispatch<SetStateAction<string[]>>;
}

const PackageCard = ({
  inputPackage,
  comparePackages,
  setComparePackages,
  clearCheckboxes,
  setClearCheckboxes,
}: PackageCardProps): JSX.Element => {
  const [openPackageDialog, setOpenPackageDialog] = useState(false);
  const handleOpenPackageDialog = () => setOpenPackageDialog(true);
  const handleClosePackageDialog = () => setOpenPackageDialog(false);

  const [openSharePackageDialog, setOpenSharePackageDialog] =
    useState<boolean>(false);

  const handleOpenSharePackageDialog = () => setOpenSharePackageDialog(true);
  const handleCloseSharePackageDialog = () => setOpenSharePackageDialog(false);

  return (
    <>
      <SharePackageDialog
        open={openSharePackageDialog}
        packages={[inputPackage]}
        handleCloseSharePackageDialog={handleCloseSharePackageDialog}
      />
      <PackageDialog
        open={openPackageDialog}
        handleClosePackageDialog={handleClosePackageDialog}>
        <PackageCardContents
          sx={{ padding: "0px 24px 20px 24px" }}
          inputPackage={inputPackage}
          handleOpenPackageDialog={handleOpenPackageDialog}
          displayMode={PackageCardDisplayMode.EXPANDED}
        />
        <SharePackageBox>
          <EmailRoundedIcon
            fontSize={"small"}
            htmlColor={"primary.dark"}
            sx={{ marginRight: "6px", cursor: "pointer" }}
            onClick={handleOpenSharePackageDialog}
          />
          <SharePackage
            sx={{ cursor: "pointer" }}
            onClick={handleOpenSharePackageDialog}>
            Share package
          </SharePackage>
        </SharePackageBox>
      </PackageDialog>
      <Box aria-label={"package-card"}>
        <Card
          actionSx={{
            padding: "0px",
            margin: "0px",
            width: "inherit",
            height: "auto",
          }}
          actions={
            <SharePackageBox>
              <EmailRoundedIcon
                fontSize={"small"}
                htmlColor={"primary.dark"}
                sx={{ marginRight: "6px", cursor: "pointer" }}
                onClick={handleOpenSharePackageDialog}
              />
              <SharePackage
                sx={{ cursor: "pointer" }}
                onClick={handleOpenSharePackageDialog}>
                Share package
              </SharePackage>
            </SharePackageBox>
          }>
          <PackageCardContents
            inputPackage={inputPackage}
            handleOpenPackageDialog={handleOpenPackageDialog}
            displayMode={PackageCardDisplayMode.COLLAPSED}
            clearCheckboxes={clearCheckboxes}
            setClearCheckboxes={setClearCheckboxes}
            comparePackages={comparePackages}
            setComparePackages={setComparePackages}
          />
        </Card>
      </Box>
    </>
  );
};

export default PackageCard;
