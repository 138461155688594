import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type ExpandDescriptionIconProps = {
  displayDescription: boolean;
  onClick?: () => void;
};

export const ExpandDescriptionIcon: FC<ExpandDescriptionIconProps> = ({
  displayDescription,
  onClick,
}) => (
  <Tooltip title={"Expand description"} arrow={true}>
    <IconButton onClick={onClick}>
      {displayDescription ? (
        <ExpandMoreIcon color="secondary" />
      ) : (
        <ExpandLessIcon color="secondary" />
      )}
    </IconButton>
  </Tooltip>
);
