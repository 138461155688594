import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PackageDepthOfCoverage } from "models/packages/PackageDepthOfCoverage";
import { PackageService } from "models/packages/PackageService";
import { ChangeEvent, useState } from "react";
import palette from "styles/palette";
import { formatCategoryOptionName } from "utils/format-category-option-name";
import { CustomizeAccordionDetails } from "./Accordion/CustomizeAccordionDetails";
import { CustomizePackageAccordion } from "./Accordion/CustomizePackageAccordion";
import { CustomizePackageAccordionDescription } from "./Accordion/CustomizePackageAccordionDescription";
import { CustomizePackageAccordionTitle } from "./Accordion/CustomizePackageAccordionTitle";
import { Popular } from "./Box/Popular";
import { CustomizePackageCardProps } from "./CustomizePackageCard.d";
import { ExpandDescriptionIcon } from "./ExpandDescriptionIcon/ExpandDescriptionIcon";
import { Disclaimer } from "./Typography/Disclaimer";
import { OptionDescription } from "./Typography/OptionDescription";
import { OptionPrice } from "./Typography/OptionPrice";
import { PopularTag } from "./Typography/PopularTag";
import { SelectedCount } from "./Typography/SelectedCount";

const popularCategoryOptions = {
  NAME: ["DEPTH_NAME_2"],
  ADDRESS: ["DEPTH_ADDRESS_2"],
  EMPLOYMENT: ["DEPTH_EMPLOYMENT_2"],
  CRIMINAL: ["DEPTH_CRIMINAL_LAWSUITS_1"],
} as Record<string, string[]>;

const CustomizePackageCard = <
  T extends PackageDepthOfCoverage | PackageService,
>({
  category,
  multiple,
  options,
  value,
  values,
  onChange,
}: CustomizePackageCardProps<T>): JSX.Element => {
  const [defaultValue, setDefaultValue] = useState<boolean>(true);

  const { name, description } = category;

  const numSelected = multiple ? values?.length : value && value > 0 ? 1 : 0;

  return (
    <CustomizePackageAccordion
      disableGutters={true}
      // TODO return computeDisplay

      sx={{ display: "" }}>
      <AccordionSummary
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "8px 0",
        }}
        aria-controls="customize-category-content"
        id="customize-category-panel-header"
        expandIcon={
          <FontAwesomeIcon
            icon={faChevronDown}
            size={"sm"}
            color={palette?.secondary?.dark}
          />
        }>
        <Box>
          <CustomizePackageAccordionTitle>
            {name}
          </CustomizePackageAccordionTitle>
          <CustomizePackageAccordionDescription sx={{ marginTop: "4px" }}>
            {description}
          </CustomizePackageAccordionDescription>
        </Box>
        <SelectedCount>
          {numSelected !== 0 ? `${numSelected} selected` : null}
        </SelectedCount>
      </AccordionSummary>
      <CustomizeAccordionDetails>
        <FormControl sx={{ border: "none" }} component={"fieldset"}>
          <FormLabel
            sx={visuallyHidden}
            component={"legend"}
            htmlFor={`${name}_${multiple ? "checkbox_group" : "radio_group"}`}>
            Select a coverage option
          </FormLabel>
          {multiple ? (
            <FormGroup id={`${name}_checkbox_group`}>
              {options.map((item: T) => {
                const checked = values?.includes(item.id);

                return (
                  <CustomizeAccordionItem
                    categoryCode={category.symbolicId}
                    multiple
                    key={item.id}
                    name={item.name}
                    description={item.description}
                    disclaimer={(item as any).disclaimer}
                    popular={popularCategoryOptions?.[
                      category.symbolicId
                    ]?.some(
                      (popularCategoryOption) =>
                        popularCategoryOption === item.symbolicId,
                    )}
                    price={checked ? "Included" : ""}
                    checked={checked}
                    onChange={() => onChange(item.id)}
                  />
                );
              })}
            </FormGroup>
          ) : (
            <RadioGroup
              id={`${name}_radio_group`}
              name={`${name}-radio-buttons-group`}>
              {options.map((item: T) => {
                const checked = value === item.id && value > 0;
                const noneChecked =
                  value === item.id && item.id === -1 && !defaultValue;

                return (
                  <CustomizeAccordionItem
                    categoryCode={category.symbolicId}
                    key={item.id}
                    name={item.name}
                    description={item.description}
                    disclaimer={(item as any).disclaimer}
                    popular={popularCategoryOptions?.[
                      category.symbolicId
                    ]?.some(
                      (popularCategoryOption) =>
                        popularCategoryOption === item.symbolicId,
                    )}
                    price={checked ? "Included" : ""}
                    checked={checked || noneChecked}
                    onChange={() => {
                      onChange(item.id);
                      setDefaultValue(false);
                    }}
                  />
                );
              })}
            </RadioGroup>
          )}
        </FormControl>
      </CustomizeAccordionDetails>
    </CustomizePackageAccordion>
  );
};

interface CustomizeAccordionItemProps {
  categoryCode: string;
  name: string;
  description?: string;
  disclaimer?: string;
  price?: string;
  multiple?: boolean;
  popular?: boolean;
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLElement>) => void;
}

function CustomizeAccordionItem({
  multiple = false,
  description,
  price,
  categoryCode,
  name,
  disclaimer,
  popular,
  checked,
  onChange,
}: CustomizeAccordionItemProps) {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const formattedName = formatCategoryOptionName(name, categoryCode);

  return (
    <Box
      sx={{
        minWidth: "738px",
        minHeight: "55px",
      }}>
      <FormControlLabel
        sx={{ width: "auto" }}
        value={formattedName}
        checked={checked}
        control={
          multiple ? (
            <Checkbox onChange={onChange} />
          ) : (
            <Radio onChange={onChange} />
          )
        }
        label={formattedName}
      />
      {popular ? (
        <Popular>
          <PopularTag>Popular</PopularTag>
        </Popular>
      ) : null}
      {description ? (
        <ExpandDescriptionIcon
          displayDescription={isDescriptionExpanded}
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        />
      ) : null}
      <OptionPrice aria-label={`option-price-${name}`}>{price}</OptionPrice>
      <Box sx={{ marginLeft: "31px" }}>
        {disclaimer ? <Disclaimer>{disclaimer}</Disclaimer> : null}
        {isDescriptionExpanded ? (
          <OptionDescription>{description}</OptionDescription>
        ) : null}
      </Box>
    </Box>
  );
}

export default CustomizePackageCard;
