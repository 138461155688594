import { styled, Button } from "@mui/material";

export const PopularPackageButton = styled(Button)(({ theme }) => ({
  width: "258px",
  height: "40px",
  color: "white",
  backgroundColor: theme?.palette?.primary.dark,
  fontWeight: 500,
  lineHeight: "16.41px",
  letter: "1px",
  margin: "36px 0px 18px 0px",
}));
