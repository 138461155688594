import { Box, SxProps, Theme } from "@mui/material";

interface PageProps {
  label: string;
  children?: JSX.Element | JSX.Element[] | null;
  sx?: SxProps<Theme>;
}

const Page = ({ label, children, sx }: PageProps): JSX.Element | null => {
  return (
    <Box
      aria-label={label}
      sx={
        sx || {
          padding: "40px 56px",
          gap: "24px",
          width: "1328px",
        }
      }>
      {children}
    </Box>
  );
};

export default Page;
