import { PackageBuilder } from "..";

// mapping of appropriate NAME and ADDRESS depths for a given
// CRIMINAL depth configuration - either 7 or 10 years
const DEPTH_MAPPINGS = [
  {
    sevenYear: "DEPTH_ADDRESS_2",
    tenYear: "DEPTH_ADDRESS_5",
  },
  {
    sevenYear: "DEPTH_ADDRESS_3",
    tenYear: "DEPTH_ADDRESS_6",
  },
  {
    sevenYear: "DEPTH_ADDRESS_4",
    tenYear: "DEPTH_ADDRESS_7",
  },
  {
    sevenYear: "DEPTH_NAME_2",
    tenYear: "DEPTH_NAME_4",
  },
  {
    sevenYear: "DEPTH_NAME_3",
    tenYear: "DEPTH_NAME_5",
  },
];

/**
 * Aligns the NAME and ADDRESS depths with the current CRIMINAL
 * depth.
 */
export function applyReconcileDepthsWithCriminalRule(
  packageBuilder: PackageBuilder,
): void {
  // align criminal depth with name/alias and address
  if (packageBuilder.hasDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_1")) {
    // 7-year depth selected; align all other depths to 7 years
    DEPTH_MAPPINGS.forEach(({ sevenYear, tenYear }) => {
      if (packageBuilder.hasDepthBySymbolicId(tenYear)) {
        packageBuilder.removeDepthBySymbolicId(tenYear);
        packageBuilder.addDepthBySymbolicId(sevenYear);
      }
    });
  } else if (packageBuilder.hasDepthBySymbolicId("DEPTH_CRIMINAL_LAWSUITS_2")) {
    // 10-year depth selected; align all other depths to 7 years
    DEPTH_MAPPINGS.forEach(({ sevenYear, tenYear }) => {
      if (packageBuilder.hasDepthBySymbolicId(sevenYear)) {
        packageBuilder.removeDepthBySymbolicId(sevenYear);
        packageBuilder.addDepthBySymbolicId(tenYear);
      }
    });
  }
}
