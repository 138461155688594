import { GuidedPackageResponses } from "../GuidedPackageBuilderContext";
import { QuestionProps, YesNoStep } from "../Step";

export default function HealthcareIndustryStep({
  stepperProps,
}: QuestionProps): JSX.Element {
  return (
    <YesNoStep<GuidedPackageResponses>
      stepperProps={stepperProps}
      name="isHealthcareIndustry"
      title="Is this role in the healthcare industry?"
      subtitle="This helps us know if you need to add Office of Inspector General (OIG), (GSA(SAM))."
    />
  );
}
