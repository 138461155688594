import { Button, styled } from "@mui/material";
import { SHARE_PACKAGE_BACKGROUND } from "styles/colors";

export const SharePackageButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "0",
  textTransform: "none",
  height: "33px",
  background: SHARE_PACKAGE_BACKGROUND,
  color: theme?.palette?.primary?.dark,
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
}));
