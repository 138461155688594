import {
  Grid,
  InputLabel,
  StandardTextFieldProps,
  styled,
  TextField,
} from "@mui/material";
import { FieldError } from "react-hook-form";
import { FC, forwardRef } from "react";

const StyledInput = styled(Grid)({
  display: "contents",
  width: "100%",
});

const StyledInputLabel = styled(InputLabel)({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
});

interface StyledTextFieldWithLabelProps extends StandardTextFieldProps {
  errorMsg?: FieldError;
}

const StyledTextFieldWithLabel: FC<StyledTextFieldWithLabelProps> = forwardRef(
  ({ id, label, errorMsg, ...rest }: StyledTextFieldWithLabelProps, ref) => {
    return (
      <StyledInput>
        {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
        <TextField
          ref={ref}
          id={id}
          type="text"
          fullWidth
          size="small"
          error={!!errorMsg}
          helperText={errorMsg?.message}
          color="secondary"
          {...rest}
        />
      </StyledInput>
    );
  },
);

StyledTextFieldWithLabel.displayName = 'StyledTextFieldWithLabel';

export default StyledTextFieldWithLabel;
