import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper as MuiStepper,
  styled,
  Typography,
} from "@mui/material";
import { STEPPER } from "styles/colors";

export type StepperProps = {
  steps?: Array<string>;
  activeStepIndex: number;
};

const Stepper = ({ steps, activeStepIndex }: StepperProps): JSX.Element => {
  return (
    <StyledBox
      sx={{ width: "1416px", marginLeft: "auto", marginRight: "auto" }}>
      <StyledStepper
        activeStep={activeStepIndex}
        connector={<StyledStepConnector />}>
        {steps?.map((stepLabel, index) => {
          const active = activeStepIndex === index;

          return (
            <Step key={index}>
              <StepLabel
                icon={
                  <StyledStepIcon active={active.toString()}>
                    <Typography>{index + 1}</Typography>
                  </StyledStepIcon>
                }>
                <StyledStepText active={active.toString()}>
                  {stepLabel}
                </StyledStepText>
              </StepLabel>
            </Step>
          );
        })}
      </StyledStepper>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 28px;
  padding: 16px 24px 18px 0px;
  border-radius: 8px;
`;

const StyledStepper = styled(MuiStepper)`
  gap: 32px;
`;

const StyledStepConnector = styled(StepConnector)`
  width: 99px;
  background: ${STEPPER["divider"]};
  flex: none;
`;

const StyledStepIcon = styled(Box)`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0px 7px;
  border: 2px solid;
  border-radius: 64px;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  text-align: center;
  text-transform: uppercase;
  color: ${(props: { active: string }) =>
    props.active === "true" ? STEPPER["active"] : STEPPER["inactive"]};
`;

const StyledStepText = styled(Typography)`
  height: 28px;
  font-size: 18px;
  line-heights: 28px;
  letter-spacing: 0.25px;
  color: ${(props: { active: string }) =>
    props.active === "true" ? STEPPER["active"] : STEPPER["inactive"]};
  font-weight: ${(props: { active: string }) =>
    props.active === "true" ? "500" : "400"};
`;

export default Stepper;
