import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const EstimatedPrice = styled(Typography)({
  width: "180px",
  height: "24px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: BLACK[87],
  marginLeft: "auto",
});
