import { styled, Typography } from "@mui/material";
import { TYPOGRAPHY } from "styles/colors";

export const Delete = styled(Typography)({
  width: "169px",
  height: "34px",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "34px",
  letterSpacing: "0.1px",
  color: TYPOGRAPHY["header"],
  flex: "none",
  order: 0,
  flexgrow: 0,
});
