import { Box, Grid } from "@mui/material";
import { Package } from "models/packages/Package";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetDepthCategoriesQuery,
  useGetDepthsQuery,
  useGetServiceCategoriesQuery,
  useGetServicesQuery,
} from "store/slices/marketplaceApi";
import GuidedPackageBuilderCard from "./GuidedPackageBuilderCard";
import {
  GuidedPackageBuilder,
  GuidedPackageResponses,
} from "./GuidedPackageBuilderContext";
import DrivingRequiredStep from "./steps/DrivingRequiredStep";
import ExecutiveOrHighlyVisibleStep from "./steps/ExecutiveOrHighlyVisibleStep";
import FederalContractStep from "./steps/FederalContractStep";
import HealthcareIndustryStep from "./steps/HealthcareIndustryStep";
import MinimumExperienceStep from "./steps/MinimumExperienceStep";
import ProfessionalLicenseStep from "./steps/ProfessionalLicenseStep";
import ValuableAssetAccessStep from "./steps/ValuableAssetAccessStep";

const STEPS = [
  MinimumExperienceStep,
  ValuableAssetAccessStep,
  ProfessionalLicenseStep,
  HealthcareIndustryStep,
  DrivingRequiredStep,
  // DrugFreeWorkplaceStep,
  FederalContractStep,
  ExecutiveOrHighlyVisibleStep,
];

interface GuidedPackageBuilderQuestionnaireProps {
  onComplete: (newPackage: Package) => void;
  onAbort: () => void;
}

export default function GuidedPackagedBuilderQuestionnaire({
  onComplete,
  onAbort,
}: GuidedPackageBuilderQuestionnaireProps): JSX.Element | null {
  const form = useForm<GuidedPackageResponses>({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const responses = form.watch();

  const { data: services = [], isFetching: isServicesLoading } =
    useGetServicesQuery();
  const { data: depths = [], isFetching: isDepthsLoading } =
    useGetDepthsQuery();
  const { data: depthCategories = [], isFetching: isDepthCategoriesFetching } =
    useGetDepthCategoriesQuery();
  const {
    data: serviceCategories = [],
    isFetching: isServiceCategoriesFetching,
  } = useGetServiceCategoriesQuery();

  const goForward = () => {
    if (currentStepIndex + 1 === STEPS.length) {
      onComplete(pkg);
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const goBack = () => {
    if (currentStepIndex === 0) {
      onAbort();
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const CurrentStep = STEPS[currentStepIndex];

  if (
    isServicesLoading ||
    isDepthsLoading ||
    isServiceCategoriesFetching ||
    isDepthCategoriesFetching
  ) {
    // TODO need a better loading state
    return null;
  }

  const builder = new GuidedPackageBuilder(
    services,
    depths,
    serviceCategories,
    depthCategories,
  );
  const pkg = builder.build(responses) as Package;

  return (
    <FormProvider {...form}>
      <Box margin={4}>
        <Grid container spacing={8}>
          <Grid item xs={7}>
            <CurrentStep
              stepperProps={{
                currentStep: currentStepIndex + 1,
                totalSteps: STEPS.length,
                onBackClick: goBack,
                onForwardClick: goForward,
              }}
            />
          </Grid>

          <Grid item xs={5}>
            {pkg ? <GuidedPackageBuilderCard newPackage={pkg} /> : null}
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
}
