import { Dialog, DialogContent } from "@mui/material";
import WhatDoesThisMeanDialogContent from "./content/WhatDoesThisMeanDialogContent";

interface WhatDoesThisMeanDialogProps {
  open: boolean;
  handleCloseDialog: () => void;
}

const WhatDoesThisMeanDialog = ({
  open,
  handleCloseDialog,
}: WhatDoesThisMeanDialogProps): JSX.Element => {
  return (
    <Dialog open={open} aria-labelledby="what-does-this-mean-dialog">
      <DialogContent
        sx={{
          padding: "16px 40px 40px",
          width: "405px",
          height: "194px",
        }}>
        <WhatDoesThisMeanDialogContent handleCloseDialog={handleCloseDialog} />
      </DialogContent>
    </Dialog>
  );
};

export default WhatDoesThisMeanDialog;
