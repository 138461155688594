import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  Box,
  CircularProgress,
  Grid,
  styled,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { visuallyHidden } from "@mui/utils";
import {
  landingPath,
  onboardingPopularPath,
  retrievingAgreementPath,
  successPath,
} from "App";
import OnboardingForm from "components/Onboarding/OnboardingForm";
import UserAgreementForm from "components/Onboarding/UserAgreementForm";
import Page from "components/page/Page";
import OnboardingStepper from "components/stepper/OnboardingStepper";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { setSuccess } from "store/actions/onboarding-actions";
import { savedPackagesCleared } from "store/actions/save-package-actions";
import {
  useCreateDocusignContactMutation,
  useLazyViewAgreementQuery,
} from "store/slices/marketplaceApi";

export interface OnboardingProps {
  handleStepIndexChange: (index: number) => void;
  setHideBackButton: (hideBackButton: boolean) => void;
}

export const PAGE_TITLE = `Thank you for choosing Orange Tree.`;
export const PAGE_SUBTITLE = `First-time users must complete the onboarding process 
  before ordering a background screening. To complete Onboarding, please enter the
  following contact information and view and/or sign your contract on the next page.`;

const Onboarding = ({
  handleStepIndexChange,
  setHideBackButton,
}: OnboardingProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onboardingStore = useSelector((state: RootState) => state.onboarding);

  const [getAgreementUrl] = useLazyViewAgreementQuery();
  const [createDocusignContact] = useCreateDocusignContactMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
    location?.pathname === onboardingPopularPath
      ? handleStepIndexChange(3)
      : handleStepIndexChange(2);
  }, []);

  useEffect(() => {
    if (onboardingStore.success) {
      handleStepIndexChange(4);
      dispatch(savedPackagesCleared());
      dispatch(setSuccess(false));
      setHideBackButton(false);
      navigate(successPath);
    }
  }, [onboardingStore.success]);

  const handleViewAgreement = async () => {
    const agreementWindow = window.open();

    if (!agreementWindow) {
      throw new Error("Unable to create agreement window");
    }

    agreementWindow.location.assign(retrievingAgreementPath);

    setActiveButton("viewAgreement");

    const { onboardingSubmission } = onboardingStore;

    if (!onboardingSubmission) {
      throw new Error("Unable to locate onboarding submission");
    }

    // colin: there's an argument to be made to eagerly fetch the agreement document
    // rather than triggering the query here - RTK could automatically clean up object URL
    // when the envelope ID changes
    const { envelopeId } = await createDocusignContact({
      signerEmail: "",
      signerFullName: "",
      envelopeStatus: "created",
      onboardingSubmission,
    }).unwrap();

    if (!envelopeId) {
      throw new Error("Unable to locate agreement");
    }

    const agreementUrl = await getAgreementUrl(envelopeId).unwrap();

    agreementWindow.location.assign(agreementUrl);
  };

  const [activeButton, setActiveButton] = useState<string>("");
  const [onboardingActiveStepIndex, setOnboardingActiveStepIndex] =
    useState<number>(0);

  const savedPackages = useAppSelector(
    (state) => state.savedPackages.savedPackages,
  );

  useEffect(() => {
    // Avoid a resubmission of the form through navigating backwards from the success page
    // after the saved packages have been cleared from localStorage.
    if (savedPackages?.length === 0) {
      navigate(landingPath);
    }
  }, [handleStepIndexChange]);

  return (
    <Page label={"onboarding-page"}>
      <OnboardingContainer>
        {onboardingActiveStepIndex === 0 && (
          <MainContent>
            <PageTitle component={"h1"}>{PAGE_TITLE}</PageTitle>
            <Subtitle>{PAGE_SUBTITLE}</Subtitle>
            <StepperContainer>
              <OnboardingStepper
                activeOnboardingStepIndex={onboardingActiveStepIndex}
              />
            </StepperContainer>
            <OnboardingForm
              setOnboardingActiveStepIndex={setOnboardingActiveStepIndex}
              setHideBackButton={setHideBackButton}
            />
          </MainContent>
        )}
        {onboardingActiveStepIndex === 1 && (
          <>
            <PageTitle sx={visuallyHidden} component={"h1"}>
              {PAGE_TITLE}
            </PageTitle>
            <StepperContainer>
              <OnboardingStepper
                activeOnboardingStepIndex={onboardingActiveStepIndex}
              />
            </StepperContainer>
            <ComplianceAndAgreementBox>
              <Grid container>
                <Grid item xs={5}>
                  <ViewAgreementBox
                    onClick={handleViewAgreement}
                    sx={{ cursor: "pointer" }}>
                    <Box
                      sx={{
                        width: "101px",
                        height: "137px",
                        marginLeft: "30px",
                        objectFit: "cover",
                        objectPosition: "50% 50%",
                      }}
                      component={"img"}
                      alt={"Review Document Icon"}
                      src={"/images/ReviewDocumentIcon.png"}></Box>
                    <Box
                      sx={{
                        display: "flex",
                      }}>
                      {onboardingStore.loading &&
                      activeButton === "viewAgreement" ? (
                        <StyledSpinner size={20} />
                      ) : (
                        <DownloadOutlinedIcon sx={{ color: "#007EC4" }} />
                      )}
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.25px",
                          color: "#007EC4",
                        }}>
                        View Agreement
                      </Typography>
                    </Box>
                  </ViewAgreementBox>
                </Grid>
                <Grid item xs={7}>
                  <UserAgreementForm
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                  />
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignContent: "flex-end",
                  }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "18px",
                      letterSpacing: "0.5px",
                    }}>
                    {`*An Authorized Company Representative is a person with signatory
                      powers designated to act on behalf of the Company by the Company’s leadership.`}
                  </Typography>
                </Grid>
              </Grid>
            </ComplianceAndAgreementBox>
          </>
        )}
      </OnboardingContainer>
    </Page>
  );
};

const PageTitle = styled(Typography)({
  height: "32px",
  fontWeight: "500",
  fontSize: "22px",
  lineHeight: "32px",
  letterSpacing: "0.1px",
}) as OverridableComponent<TypographyTypeMap<Record<string, unknown>, "span">>;

const Subtitle = styled(Typography)({
  marginTop: "17px",
  height: "24px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  marginBottom: "40px",
});

const OnboardingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "60px",
});

const MainContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "1037px",
  alignItems: "flex-start",
});

const StepperContainer = styled(Box)({
  maxWidth: "1037px",
  display: "flex",
  flexDirection: "column",
  marginBottom: 0,
});

const ComplianceAndAgreementBox = styled(Box)({
  display: "flex",
  width: "1037px",
  height: "396px",
  background: "#FBFCFD",
  border: "0.5px solid #B1B7BB",
  padding: "24px 40px 24px 40px",
});

const ViewAgreementBox = styled(Box)({
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "80px",
  width: "159px",
  height: "170px",
});

const StyledSpinner = styled(CircularProgress)({
  marginRight: "10px",
  color: "#007EC4",
});

export default Onboarding;
