import { styled, Typography } from "@mui/material";

export const Edit = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  width: "auto",
  height: "12px",
  fontSize: "12px",
  lineHeight: "12px",
  alignItems: "center",
  letterSpacing: "1px",
  color: theme?.palette?.secondary?.dark,
  flex: "none",
  order: 1,
  flexGrow: 0,
  textTransform: "uppercase",
  verticalAlign: "middle",
}));
