import { styled, Typography } from "@mui/material";
import { BLACK } from "styles/colors";

export const EmailCount = styled(Typography)(({ theme }) => ({
  height: "18px",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "right",
  letterSpacing: "0.5px",
  color: BLACK[60],
  flex: "none",
  order: 1,
  flexGrow: 0,
}));
