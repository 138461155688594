import { createSlice } from "@reduxjs/toolkit";
import { PackagePost } from "models/onboarding/PackagePost";
import { User } from "models/onboarding/User";
import { setSuccess } from "store/actions/onboarding-actions";
import { marketplaceApi } from "./marketplaceApi";

export type OnboardingStoreState = {
  onboardingSubmission: {
    user: User;
    packages: PackagePost[];
  } | null;
  envelopeId: string | null;
  viewedAgreement: boolean;
  loading: boolean;
  error: boolean;
  success: boolean;
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    loading: false,
    success: false,
    viewedAgreement: false,
    envelopeId: null,
  } as OnboardingStoreState,
  reducers: {},
  extraReducers: (builder) => {
    // can we derive this from another action rather than setting
    // it imperatively?
    builder.addCase(setSuccess, (state, action) => {
      state.success = action.payload;
    });

    builder.addMatcher(
      marketplaceApi.endpoints.viewAgreement.matchFulfilled,
      (state, action) => {
        // pull the envelope ID out of the original call
        const envelopeId = action.meta.arg.originalArgs;

        state.envelopeId = envelopeId;
        state.viewedAgreement = true;
      },
    );

    builder.addMatcher(
      marketplaceApi.endpoints.createContact.matchFulfilled,
      (state, action) => {
        state.viewedAgreement = false;
        state.envelopeId = "";

        // pull the contact info out of the original call
        // -- endpoint does not return any data
        state.onboardingSubmission = action.meta.arg.originalArgs;
      },
    );

    builder.addMatcher(
      marketplaceApi.endpoints.createDocusignContact.matchFulfilled,
      (state, action) => {
        // TODO split up DocusignContact and response type so that this is correctly typed
        // i.e. should probably never be undefined in this context
        // why does this action set success flag but not createContact?
        if (action.payload.envelopeStatus === "sent") {
          state.onboardingSubmission = null;
          state.success = true;
        } else if (action.payload.envelopeStatus === "created") {
          state.envelopeId = action.payload.envelopeId as string;
        }
      },
    );
  },
});

export default onboardingSlice;
