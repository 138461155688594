import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box, Button } from "@mui/material";
import { Container } from "@mui/system";
import {
  customizeCustomPackagePath,
  onboardingCustomPath,
  onboardingPopularPath,
  reviewPopularPackagesPath,
  selectPackagePath,
} from "App";
import ReviewPackagesRowWidget from "components/ReviewPackagesRowWidget";
import SharePackageDialog from "components/SharePackageDialog";
import Table from "components/Table";
import { Column } from "components/Table/Table.d";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../components/page/Page";
import { BuildCustomPackageButton } from "./Button/BuildCustomPackageButton";
import { PopularPackageButton } from "./Button/PopularPackageButton";
import { ReviewPackagesProps } from "./ReviewPackages.d";
import { NoPackagesSaved } from "./Typography/NoPackagesSaved";
import { PageTitle } from "./Typography/PageTitle";
import { SelectAPackage } from "./Typography/SelectAPackage";

export const PAGE_TITLE = "Review";

export const reviewPackagesColumns: Column[] = [
  { name: "Package Name" },
  { name: "Services" },
  { name: "Depth of Coverage" },
  {
    name: "Estimated Price",
    columnOptions: {
      align: "right",
    },
  },
  {
    name: "Actions",
    columnOptions: {
      align: "left",
    },
  },
];

const ReviewPackages = ({
  handleStepIndexChange,
}: ReviewPackagesProps): JSX.Element => {
  const [isSharePackageOpen, setIsSharePackageOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const savedPackages = useAppSelector(
    (state) => state.savedPackages.savedPackages,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    location?.pathname === reviewPopularPackagesPath
      ? handleStepIndexChange(2)
      : handleStepIndexChange(1);
  }, []);

  return (
    <>
      <SharePackageDialog
        open={isSharePackageOpen}
        packages={savedPackages}
        handleCloseSharePackageDialog={() => setIsSharePackageOpen(false)}
      />
      <Page
        sx={{ padding: "40px 56px 0px 56px", gap: "24px", width: "1328px" }}
        label={"review-packages-page"}>
        <Box sx={{ marginBottom: "127px" }}>
          <PageTitle component={"h1"}>{PAGE_TITLE}</PageTitle>
          <Box>
            {savedPackages?.length ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    placeContent: "flex-end",
                    marginBottom: "15px",
                  }}>
                  <Button
                    color="secondary"
                    aria-label="share-packages-button"
                    disabled={savedPackages.length < 1}
                    onClick={() => setIsSharePackageOpen(true)}
                    startIcon={
                      <EmailRoundedIcon
                        fontSize="small"
                        sx={{ marginRight: "6px", cursor: "pointer" }}
                      />
                    }>
                    Share {savedPackages.length === 1 ? "package" : "packages"}
                  </Button>
                </Box>
                <Table
                  id={"review_packages_table"}
                  columns={reviewPackagesColumns}
                  // needed because Package lacks an index type; consider
                  // changing this prop type to a simple array of objects
                  // (or better, make Table a generic component)
                  rows={savedPackages as any}
                  RowWidget={ReviewPackagesRowWidget}
                />
                <Box sx={{ marginTop: "25px", textAlign: "end" }}>
                  <Button
                    onClick={() => {
                      navigate(
                        location?.pathname === reviewPopularPackagesPath
                          ? selectPackagePath
                          : customizeCustomPackagePath,
                      );
                    }}
                    variant={"outlined"}>
                    Add Another Package
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(
                        location?.pathname === reviewPopularPackagesPath
                          ? onboardingPopularPath
                          : onboardingCustomPath,
                      )
                    }
                    variant={"contained"}
                    color={"secondary"}
                    disableElevation
                    endIcon={
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color={"white"}
                        style={{
                          fontSize: "16px",
                        }}
                      />
                    }
                    sx={{
                      height: "40px",
                      width: "191px",
                      margin: "0px 16px",
                    }}>
                    Continue
                  </Button>
                </Box>
              </>
            ) : (
              <Container sx={{ textAlign: "center" }} maxWidth={"xs"}>
                <Box
                  sx={{ display: "block", margin: "auto" }}
                  component={"img"}
                  alt={"Empty Review Packages"}
                  src={"/images/EmptyReviewPackages.png"}
                />
                <NoPackagesSaved>You have no packages saved</NoPackagesSaved>
                <SelectAPackage>
                  Select or build a package to get started.
                </SelectAPackage>
                <PopularPackageButton
                  variant={"contained"}
                  onClick={() => {
                    navigate(selectPackagePath);
                  }}>
                  Select a popular package
                </PopularPackageButton>
                <BuildCustomPackageButton
                  onClick={() => {
                    navigate(customizeCustomPackagePath);
                  }}>
                  Build a custom package
                </BuildCustomPackageButton>
              </Container>
            )}
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default ReviewPackages;
