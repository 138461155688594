import Page from "components/page/Page";
import { useAppDispatch } from "hooks";
import { Package } from "models/packages/Package";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { savedPackageAdded } from "store/actions/save-package-actions";
import { v4 as uuid } from "uuid";
import GuidedPackageBuilderQuestionnaire from "./GuidedPackageBuilderQuestionnaire";
import GuidedPackageBuilderReview from "./GuidedPackageBuilderReview";

enum GuidedPackageBuilderStep {
  Questionnaire = "QUESTIONNAIRE",
  Review = "REVIEW",
}

export default function GuidedPackageBuilder(): JSX.Element {
  const dispatch = useAppDispatch();

  const [newPackage, setNewPackage] = useState<Package>();

  const [currentStep, setStep] = useState(
    GuidedPackageBuilderStep.Questionnaire,
  );
  const navigate = useNavigate();

  const content =
    currentStep === GuidedPackageBuilderStep.Questionnaire ? (
      <GuidedPackageBuilderQuestionnaire
        onAbort={() => navigate("/")}
        onComplete={(pkg) => {
          const newPackage = {
            ...pkg,
            packageId: uuid(),
          };

          dispatch(savedPackageAdded(newPackage));

          setNewPackage(newPackage);
          setStep(GuidedPackageBuilderStep.Review);
        }}
      />
    ) : currentStep === GuidedPackageBuilderStep.Review &&
      newPackage !== undefined ? (
      <GuidedPackageBuilderReview newPackage={newPackage} />
    ) : (
      <Navigate to="/" />
    );

  return <Page label="guided package builder">{content}</Page>;
}
