import {
  Box,
  Divider,
  List as MuiList,
  styled,
  Typography,
} from "@mui/material";
import { PackageCardListItemProps } from "./PackageCardListItem";
import { useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { CompareRef } from "pages/SelectPackage/SelectPackage";

export type ComparePackageCardListProps = {
  title: string;
  items: Array<PackageCardListItemProps>;
  combinedItems: Array<PackageCardListItemProps>;
  width?: string;
  compareRefs: CompareRef[];
};

const ComparePackageCardList = ({
  title,
  items,
  combinedItems,
  width = "200px",
  compareRefs,
}: ComparePackageCardListProps): JSX.Element => {
  const childRefs = useRef<Array<HTMLDivElement | unknown>>([]);

  return (
    <StyledList width={width} id={title}>
      <ListTitle color="text.primary">{title}</ListTitle>
      <Divider sx={{ width: { width } }} />
      {compareRefs.length > 0 &&
        combinedItems.map((item, index) => {
          const itemRefCompare = compareRefs.find((i) => i.id === item.name);
          return (
            <Box
              ref={(el) => (childRefs.current[index] = el)}
              key={`${title}-${index}`}>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-around",
                  height: itemRefCompare ? itemRefCompare.height : "",
                }}>
                {items.find((i) => i.name === item.name) && (
                  <CheckIcon
                    sx={{ alignSelf: "center" }}
                    color="success"
                    fontSize="small"
                  />
                )}
              </Box>
              <Divider sx={{ width: "255px" }} />
            </Box>
          );
        })}
    </StyledList>
  );
};
const StyledList = styled(MuiList)`
  width: ${(props: { width: string }) => props.width};
  padding: 8px 8px 12px 8px;
`;

const ListTitle = styled(Typography)`
  height: 24px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 0px 0px 4px 0px;
`;

export default ComparePackageCardList;
