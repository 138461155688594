import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import {
  customizeCustomPackagePath,
  customizePopularPackagePath,
  guidedPackageBuilderPath,
  onboardingCustomPath,
  onboardingPopularPath,
  reviewCustomPackagesPath,
  reviewPopularPackagesPath,
  selectPackagePath,
} from "App";
import { BuildCustomPackage } from "pages/SelectPackage/Box/BuildCustomPackage";
import { BuildCustomPackageLink } from "pages/SelectPackage/Link/BuildCustomPackageLink";
import { CustomPackageQuestion } from "pages/SelectPackage/Typography/CustomPackageQuestion";
import { BLUE } from "styles/colors";

const DifferentExperienceBar = (): JSX.Element => {
  return (
    <>
      <BuildCustomPackage>
        <CustomPackageQuestion>
          Don’t see what you’re looking for? Try a different package-building
          experience:
        </CustomPackageQuestion>
        <Box
          sx={{
            display: "flex",
          }}>
          {location?.pathname !== guidedPackageBuilderPath && (
            <Box>
              <BuildCustomPackageLink
                href={guidedPackageBuilderPath}
                variant="subtitle2"
                underline="hover"
                aria-label={"Guided Package Builder"}>
                Guided Package Builder
              </BuildCustomPackageLink>
              <FontAwesomeIcon icon={faArrowRight} color={BLUE[30]} />
            </Box>
          )}
          {[
            customizeCustomPackagePath,
            reviewCustomPackagesPath,
            onboardingCustomPath,
            guidedPackageBuilderPath,
          ].includes(location?.pathname) && (
            <Box sx={{ marginLeft: "25px" }}>
              <BuildCustomPackageLink
                href={selectPackagePath}
                variant="subtitle2"
                underline="hover"
                aria-label={"Select a Popular Package"}>
                Select a Popular Package
              </BuildCustomPackageLink>
              <FontAwesomeIcon icon={faArrowRight} color={BLUE[30]} />
            </Box>
          )}
          {[
            selectPackagePath,
            customizePopularPackagePath,
            reviewPopularPackagesPath,
            onboardingPopularPath,
            guidedPackageBuilderPath,
          ].includes(location?.pathname) && (
            <Box sx={{ marginLeft: "25px" }}>
              <BuildCustomPackageLink
                href={customizeCustomPackagePath}
                variant="subtitle2"
                underline="hover"
                aria-label={"Select a Custom Package"}>
                Select a Custom Package
              </BuildCustomPackageLink>
              <FontAwesomeIcon icon={faArrowRight} color={BLUE[30]} />
            </Box>
          )}
        </Box>
      </BuildCustomPackage>
    </>
  );
};

export default DifferentExperienceBar;
