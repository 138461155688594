import Papa from "papaparse";

async function fetchCsv(path: string) {
  const response = await fetch(path);
  const reader = response?.body?.getReader();
  const result = await reader?.read();
  const decoder = new TextDecoder("utf-8");
  const decodedResult = decoder.decode(result?.value);
  const data = Papa.parse(decodedResult);

  return data.data as string[];
}

export function fetchBlockedDomainsCsv() {
  return fetchCsv("/data/blocked-domains.csv");
}