import { DepthOfCoverage } from "models/DepthOfCoverage";
import { Package } from "models/packages/Package";
import { PackageDepthOfCoverage } from "models/packages/PackageDepthOfCoverage";
import { PackageService } from "models/packages/PackageService";
import { ServiceOfCoverage } from "models/ServiceOfCoverage";
import { applySSNRule } from "./rules/01-ssn";
import { applyDefaultDepthsRule } from "./rules/02-defaultDepths";
import { applyOrphanDepthsRule } from "./rules/03-orphanDepths";
import { applyReconcileDepthsWithCriminalRule } from "./rules/04-reconcile-depths-with-criminal";
import { Category } from "../../../models/category/Category";

export interface PackageBuilderOperationResult {
  notices: string[];
}

export enum PackageBuilderAlertType {
  SSN = "SSN",
  DefaultDepth = "DEFAULT_DEPTH",
}

interface BasePackageBuilderAlert {
  type: PackageBuilderAlertType;
}

interface PackageBuilderSSNAlert extends BasePackageBuilderAlert {
  type: PackageBuilderAlertType.SSN;
}

interface PackageBuilderDefaultDepthAlert extends BasePackageBuilderAlert {
  type: PackageBuilderAlertType.DefaultDepth;
  depth: DepthOfCoverage;
}

export type PackageBuilderAlert =
  | PackageBuilderSSNAlert
  | PackageBuilderDefaultDepthAlert;

export class PackageBuilder {
  private availableServices: ServiceOfCoverage[];
  private availableDepths: DepthOfCoverage[];
  private availableServiceCategories: Category[];
  private availableDepthCategories: Category[];

  name: string;

  assignedServices: Set<ServiceOfCoverage>;
  assignedDepths: Set<DepthOfCoverage>;

  assignedServiceIdsByCategory: Record<number, number[]>;
  assignedDepthIdsByCategory: Record<number, number>;

  constructor(
    availableServices: ServiceOfCoverage[],
    availableDepths: DepthOfCoverage[],
    availableServiceCategories: Category[],
    availableDepthCategories: Category[],
    name = "My Package",
    assignedServices = new Set<ServiceOfCoverage>(),
    assignedDepths = new Set<DepthOfCoverage>(),
    assignedServiceIdsByCategory = {},
    assignedDepthIdsByCategory = {},
  ) {
    this.availableServices = availableServices;
    this.availableDepths = availableDepths;
    this.availableServiceCategories = availableServiceCategories;
    this.availableDepthCategories = availableDepthCategories;

    this.name = name;

    this.assignedServices = assignedServices;
    this.assignedDepths = assignedDepths;

    this.assignedServiceIdsByCategory = assignedServiceIdsByCategory;
    this.assignedDepthIdsByCategory = assignedDepthIdsByCategory;
  }

  /**
   * Return a new PackageBuilder with the same state. Useful for triggering changes in React components.
   */
  clone(): PackageBuilder {
    return new PackageBuilder(
      this.availableServices,
      this.availableDepths,
      this.availableServiceCategories,
      this.availableDepthCategories,
      this.name,
      this.assignedServices,
      this.assignedDepths,
      this.assignedServiceIdsByCategory,
      this.assignedDepthIdsByCategory,
    );
  }

  /**
   * Applies business rules to the current PackageBuilder state. This may result
   * in services and depths being added or removed to satisfy business requirements.
   *
   * Returns an array of alerts which may be used to inform the user of notable changes
   * to the package being built.
   */
  applyBusinessRules(): PackageBuilderAlert[] {
    const alerts: PackageBuilderAlert[] = [];

    applySSNRule(this, alerts);
    applyDefaultDepthsRule(this, alerts);
    applyOrphanDepthsRule(this);
    applyReconcileDepthsWithCriminalRule(this);

    return alerts;
  }

  setServices(serviceIds: number[]): this {
    const nextServices = serviceIds.map((id) => this.findService(id));

    this.assignedServices = new Set(nextServices);

    return this;
  }

  addService(serviceId: number): this {
    const serviceToAdd = this.findService(serviceId);

    this.assignedServices = new Set(this.assignedServices).add(serviceToAdd);

    this.assignedServiceIdsByCategory = {
      ...this.assignedServiceIdsByCategory,
      [serviceToAdd.categoryId]: [
        ...(this.assignedServiceIdsByCategory[serviceToAdd.categoryId] ?? []),
        serviceToAdd.id,
      ],
    };

    return this;
  }

  addServiceBySymbolicId(symbolicId: string): this {
    const serviceId = this.findServiceBySymbolicId(symbolicId).id;

    return this.addService(serviceId);
  }

  hasService(serviceId: number): boolean {
    return this.assignedServices.has(this.findService(serviceId));
  }

  hasServiceBySymbolicId(symbolicId: string): boolean {
    const serviceId = this.findServiceBySymbolicId(symbolicId).id;

    return this.hasService(serviceId);
  }

  removeService(serviceId: number): this {
    const serviceToDelete = this.findService(serviceId);

    this.assignedServices = new Set(
      [...this.assignedServices].filter((r) => r !== serviceToDelete),
    );

    this.assignedServiceIdsByCategory = {
      ...this.assignedServiceIdsByCategory,
      [serviceToDelete.categoryId]: (
        this.assignedServiceIdsByCategory[serviceToDelete.categoryId] ?? []
      ).filter((r) => r !== serviceToDelete.id),
    };

    return this;
  }

  removeServiceBySymbolicId(symbolicId: string): this {
    const serviceId = this.findServiceBySymbolicId(symbolicId).id;

    return this.removeService(serviceId);
  }

  setDepths(depthIds: number[]): this {
    const nextDepths = depthIds.map((id) => this.findDepth(id));

    this.assignedDepths = new Set(nextDepths);

    return this;
  }

  addDepth(depthId: number): this {
    console.log(this.assignedDepths);

    console.log(depthId);
    const depthToAdd = this.findDepth(depthId);
    console.log(depthToAdd);

    // remove other depths of same category
    this.assignedDepths = new Set(
      [...this.assignedDepths].filter(
        (r) => r.categoryId !== depthToAdd.categoryId,
      ),
    ).add(depthToAdd);

    this.assignedDepthIdsByCategory[depthToAdd.categoryId] = depthToAdd.id;

    console.log(this.assignedDepths);
    return this;
  }

  addDepthBySymbolicId(symbolicId: string): this {
    const depthId = this.findDepthBySymbolicId(symbolicId).id;

    return this.addDepth(depthId);
  }

  hasDepth(depthId: number): boolean {
    return this.assignedDepths.has(this.findDepth(depthId));
  }

  hasDepthBySymbolicId(symbolicId: string): boolean {
    const depthId = this.findDepthBySymbolicId(symbolicId).id;

    return this.hasDepth(depthId);
  }

  removeDepth(depthId: number): this {
    const depthToDelete = this.findDepth(depthId);

    this.assignedDepths = new Set(
      [...this.assignedDepths].filter((r) => r.id !== depthId),
    );

    delete this.assignedDepthIdsByCategory[depthToDelete.categoryId];

    return this;
  }

  removeDepthBySymbolicId(symbolicId: string): this {
    const depthId = this.findDepthBySymbolicId(symbolicId).id;

    return this.removeDepth(depthId);
  }

  getPrice(): number {
    const services = [...this.assignedServices];

    const total = services.reduce((total, { pricingRules }) => {
      let servicePrice = 0;

      // evaluate pricing rules
      //
      // how this works:
      //
      // service prices are determined by pricing rules. there could possibly be many pricing rules for each service.
      // the correct pricing rules are differentiated by the combination of depth ids. the applicable rule is the max price where
      // all required depths are currently selected on the package.
      pricingRules.forEach(({ price, depthIds }) => {
        const isRuleApplicable = depthIds.every((requiredDepthId: number) =>
          this.hasDepth(requiredDepthId),
        );

        if (isRuleApplicable && price > servicePrice) {
          servicePrice = price;
        }
      });

      return total + servicePrice;
    }, 0);

    // round to two decimal places
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  /**
   * Build a package based on the current PackageBuilder state.
   *
   * **NOTE**: applyBusinessRules should be called to ensure that the new package
   * complies with business requirements.
   */
  build(): Package {
    const depths = [...this.assignedDepths] as PackageDepthOfCoverage[];
    const services = [...this.assignedServices] as PackageService[];

    return {
      name: this.name,
      packageType: "custom",
      price: this.getPrice(),
      depths,
      services,
    } as unknown as Package;
  }

  findService(serviceId: number): ServiceOfCoverage {
    const service = this.availableServices.find((r) => r.id === serviceId);

    if (service === undefined) {
      throw new Error(`Unable to locate service ${serviceId}`);
    }

    return service;
  }

  findServiceBySymbolicId(symbolicId: string): ServiceOfCoverage {
    const service = this.availableServices.find(
      (r) => r.symbolicId === symbolicId,
    );

    if (service === undefined) {
      throw new Error(`Unable to locate service ${symbolicId}`);
    }

    return service;
  }

  findDepth(depthId: number): DepthOfCoverage {
    const depth = this.availableDepths.find((r) => r.id === depthId);

    if (depth === undefined) {
      throw new Error(`Unable to locate depth ${depthId}`);
    }

    return depth;
  }

  findDepthBySymbolicId(symbolicId: string): DepthOfCoverage {
    const depth = this.availableDepths.find((r) => r.symbolicId === symbolicId);

    if (depth === undefined) {
      throw new Error(`Unable to locate depth ${symbolicId}`);
    }

    return depth;
  }

  findDepthCategoryBySymbolicId(symbolicId: string): Category {
    const category = this.availableDepthCategories.find(
      (r) => r.symbolicId === symbolicId,
    );

    if (category === undefined) {
      throw new Error(`Unable to locate category ${symbolicId}`);
    }

    return category;
  }

  findServiceCategoryBySymbolicId(symbolicId: string): Category {
    const category = this.availableServiceCategories.find(
      (r) => r.symbolicId === symbolicId,
    );

    if (category === undefined) {
      throw new Error(`Unable to locate category ${symbolicId}`);
    }

    return category;
  }
}
