import { PackageBuilder } from "..";

/**
 * Removes all depth categories which are no longer required by any of the
 * configured services.
 */
export function applyOrphanDepthsRule(packageBuilder: PackageBuilder): void {
  // prune "orphan" depths - depths with no associated services
  const selectedDepthCategories = [...packageBuilder.assignedServices].reduce(
    (acc, r) => {
      r.requiredDepthCategoryIds.forEach((depthCategoryId) =>
        acc.add(depthCategoryId),
      );

      return acc;
    },
    new Set<number>(),
  );

  // we could use setServices here but it expects a set of IDs - a better
  // solution probably exists but for now just bypass the setter
  packageBuilder.assignedDepths = new Set(
    [...packageBuilder.assignedDepths].filter((r) =>
      selectedDepthCategories.has(r.categoryId),
    ),
  );
}
